export const toggleItemInArray = (items, payload) => {
  if (!items.length) {
    items.push(payload);
    return items;
  } else {
    let itemIndex = null;
    const updatedItems = items.filter((item, index) => {
      if (item._id == payload._id) {
        itemIndex = index;
      } else {
        return item;
      }
    });
    if (itemIndex == null) {
      updatedItems.push(payload);
    }
    return updatedItems;
  }
};

import React, { useEffect, useState } from 'react'

const useWindowDimensions = () => {
    const [dimensions, setDimensions] = useState({
        width: undefined,
        height: undefined
    })
    useEffect(() => {
        function calculate() {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        window.addEventListener('resize', calculate)
        calculate()

        return (() => window.removeEventListener('resize', calculate))
    }, [])
    return dimensions
}

export default useWindowDimensions



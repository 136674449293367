export function isSalesman(user: any): boolean {
    if (user?.roles?.includes('SALES')) {
        return true
    }
    return false
}

export function isManager(user: any): boolean {
    if (user?.roles?.includes('MANAGER')) {
        return true
    }
    return false
}

export function isTeamMember(user: any): boolean {
    if ((user?.roles?.includes('MANAGER')) || (user?.roles?.includes('SALES'))) {
        return true
    } 
    return false
}

export function salesTeamMember(salesmenNames, salesTeam) {
    let salesmenIds:any[] = []
    for (let member of salesTeam) { 
        for (let salesman of salesmenNames) {
            if (salesman === member.profile.profileId.username) {
                salesmenIds.push(member.profile.profileId._id)
            }
        }
    }
    return salesmenIds
} 

export const customerTypes = {
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_ACCOUNT_ID: 'SET_ACCOUNT_ID',
  IS_CUSTOMER: 'IS_CUSTOMER',
  SET_ACCOUNT_URL: 'SET_ACCOUNT_URL',
  SET_LOADING_CUSTOMER: 'SET_LOADING_CUSTOMER',
  SET_ERROR_CUSTOMER: 'SET_ERROR_CUSTOMER',
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',
  SET_COMPANY_LOGO: 'SET_COMPANY_LOGO',
  SET_IS_EXCEEDED_TRIAL_LIMIT: 'SET_IS_EXCEEDED_TRIAL_LIMIT',
  SET_CUSTOMER_CURRENCY: 'SET_CUSTOMER_CURRENCY',
};

export interface PaymentButton {
  name: string
  const: string
  enabled: boolean
  description: string
}
import { SERVICE_AREA_TYPES } from '../constants/services.constants';
import {roundToTwoDigits} from './roundToTwoDigits'

export const getServicePrice = (pricePerSquare, quantity, minPrice, fixedPrice, sqFeet, ranges, areaType) => {
  if (areaType !== SERVICE_AREA_TYPES.LAW_AREA && areaType !== SERVICE_AREA_TYPES.LOT_AREA && (!sqFeet || sqFeet === 0)) {
    return -1
  }
  if(!ranges?.length && fixedPrice !== null){
    return roundToTwoDigits(fixedPrice * quantity)
  } else if (!ranges?.length && pricePerSquare !== null) {
    const total = roundToTwoDigits(pricePerSquare * (sqFeet / 1000));
    let price =  quantity ? total * quantity : total;
    if(quantity){
      return  roundToTwoDigits(price > (minPrice * quantity)  ?  price : minPrice * quantity)
    } else {
      return roundToTwoDigits(price > minPrice ? price : minPrice)
    }
  } else if (ranges && ranges.length > 0) {
    ranges = ranges.sort((a, b) => a.price - b.price);
    let total = 0;
    for (let range of ranges) {
      if (range.to && !range.allAbove && range.from <= sqFeet && range.to < sqFeet) {
        if (range.fixedPrice !== null) {
          total =  range.fixedPrice;
        } else if (range.pricePerSquare !== null) {
          // handle bedroom/bath logic here
          let rangePrice
          if (areaType === SERVICE_AREA_TYPES.BATHROOM || areaType === SERVICE_AREA_TYPES.BEDROOM) {
            if(sqFeet >= range.from && sqFeet <= range.to ){
              rangePrice = range.pricePerSquare *sqFeet
            } else rangePrice = 0
          } else {
            rangePrice = roundToTwoDigits(range.pricePerSquare * ((sqFeet - range.from) / 1000));
          }
          total = total + rangePrice;
        }
      } else if (range.from <= sqFeet && (!range.to || range.allAbove) || (range.to && range.to >= sqFeet)) {
        if(range.customQuote) {
          return -1
        }
        if (range.fixedPrice !== null) {
          total =  range.fixedPrice;
          return roundToTwoDigits(quantity ? total * quantity: total);
        } else if (range.pricePerSquare !== null) {
          // handle bedroom/bath logic here
          let rangePrice
          if (areaType === SERVICE_AREA_TYPES.BATHROOM || areaType === SERVICE_AREA_TYPES.BEDROOM) {
            rangePrice = range.pricePerSquare *sqFeet
          } else {
            rangePrice = roundToTwoDigits(range.pricePerSquare * ((sqFeet - range.from) / 1000));
          }
          total = total + rangePrice;
          return roundToTwoDigits(quantity ? total * quantity: total);
        }
      }
    }
  }
}

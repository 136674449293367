import { SERVICE_AREA_TYPES } from '../../constants/services.constants';
import { calculateDiscount } from '../../utils/calculateDiscount';
import { getCustomQuoteServicePrice } from '../../utils/getCustomQuoteServicePrice';
import { getDiscountedPackagePrice } from '../../utils/getDiscountedPackagePrice';
import { getDiscountedServicePrice } from '../../utils/getDiscountedServicePrice';
import { getPackagePrice } from '../../utils/getPackagePrice';
import { getServicePrice } from '../../utils/getServicePrice';
import { getServiceQuantity } from '../../utils/getServiceQuantity';
import { roundToTwoDigits } from '../../utils/roundToTwoDigits';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import relativeTime from "dayjs/plugin/relativeTime"
import { getServiceSqFeet } from '../../utils/getServiceSqFeet';
import sendRequest from '../../utils/useAxios';
import { ApplyDiscountOnService, ApplyPackageCustomDiscounts, ApplyServiceCustomDiscounts } from '../../utils/applyCustomDiscounts';
import { checkPriceRange } from '../../utils/checkPriceRanges';
import { applyPriceRange, getEndingPrice } from '../../utils/applyPriceRange';

export const isActive = (_id, type) => (state) => {
  const filteredList = state.widget.cart[type].filter(
    (service) => service._id == _id
  );
  return !!filteredList.length;
};

export const getMaxTotalPrice = () => (state) => {
  let maxTotalPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, customQuotes, paymentType, customDiscounts } = state.widget;

  state.widget.cart.services.map((service) => {
    if (checkPriceRange([service], [], sqFeet)) {
      let servicePrice = getEndingPrice(service, sqFeet);

      const serviceQuantity = getServiceQuantity(quantity, service._id);

      servicePrice = servicePrice * serviceQuantity

      servicePrice = Number(ApplyDiscountOnService(service, servicePrice, paymentType, quantity)?.discountedPrice)
      maxTotalPrice += parseFloat(applyPriceRange(customDiscounts, service, servicePrice, paymentType, quantity, sqFeet, customQuotes))

      // let servicePrice = getServicePrice(
      //   service.pricePerSquare,
      //   getServiceQuantity(quantity, service._id),
      //   service.minPrice,
      //   service.fixedPrice,
      //   sqFeet,
      //   service.ranges,
      //   service.areaType
      // );
      // maxTotalPrice += parseFloat(applyPriceRange(customDiscounts, service, servicePrice, paymentType, quantity, sqFeet, customQuotes))
    } else {
      const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
      maxTotalPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
    }
    // else {
    //   const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    //   maxTotalPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
    // }
  });

  state.widget.cart.packages.map((packageItem) => {
    const packagePrice = parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    maxTotalPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
  });

  const estimatedTax = getEstimatedTax()(state);

  maxTotalPrice = maxTotalPrice + Number(estimatedTax);
  const orderDiscountAmount = parseFloat(getTotalOrderDiscountAmount()(state))
  if(orderDiscountAmount > 0){
    maxTotalPrice -= orderDiscountAmount
  }
  return roundToTwoDigits(maxTotalPrice).toFixed(2)
};

export const getTotalPrice = () => (state) => {
  let totalPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, customQuotes, paymentType, customDiscounts } = state.widget;

  state.widget.cart.services.map((service) => {
    if (checkPriceRange([service], [], sqFeet)) {
      let servicePrice = getServicePrice(
        service.pricePerSquare,
        getServiceQuantity(quantity, service._id),
        service.minPrice,
        service.fixedPrice,
        sqFeet,
        service.ranges,
        service.areaType
      );
      servicePrice = Number(ApplyDiscountOnService(service, servicePrice, paymentType, quantity)?.discountedPrice)
      totalPrice += parseFloat(applyPriceRange(customDiscounts, service, servicePrice, paymentType, quantity, sqFeet, customQuotes))
    } else {
      const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
      totalPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
    }
  });

  state.widget.cart.packages.map((packageItem) => {
    const packagePrice = parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    totalPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
  });

  const estimatedTax = getEstimatedTax()(state);

  totalPrice = totalPrice + Number(estimatedTax);
  const orderDiscountAmount = parseFloat(getTotalOrderDiscountAmount()(state))
  if(orderDiscountAmount > 0){
    totalPrice -= orderDiscountAmount
  }
  return roundToTwoDigits(totalPrice).toFixed(2)
};

export const getSubTotal = () => (state) => {
  let totalPrice = 0;
  const { sqFeet, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, customQuotes } = state.widget;
  // const {boundary} = state.widget
  const buildingSquareFeet = measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0

  state.widget.cart.services.map((service) => {
    let squareFeet = getServiceSqFeet(service.areaType, sqFeet, buildingSquareFeet, polygonsFeaturesSqFeet);

    let servicePrice = getServicePrice(
      service.pricePerSquare,
      getServiceQuantity(quantity, service._id),
      service.minPrice,
      service.fixedPrice,
      squareFeet,
      service.ranges,
      service.areaType
    );
    servicePrice = getCustomQuoteServicePrice(servicePrice, customQuotes, service._id, quantity)
    totalPrice += servicePrice
  });

  state.widget.cart.packages.map((packageItem) => {
    totalPrice += getPackagePrice(packageItem, sqFeet, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet);
  });
  return roundToTwoDigits(totalPrice).toFixed(2)
};

export const getMaxTotalPriceWithoutTax = () => (state) => {
  let maxTotalPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, paymentType, customQuotes, customDiscounts } = state.widget;

  state.widget.cart.services.map((service) => {
    if (checkPriceRange([service], [], sqFeet)) {
      let servicePrice
      let serviceQuantity 
      if (service.areaType === SERVICE_AREA_TYPES.BATHROOM) {
        servicePrice = getEndingPrice(service, sqFeet);
        serviceQuantity = getServiceQuantity(quantity, service._id);

        servicePrice = servicePrice * serviceQuantity
        // servicePrice = getServicePrice(
        //   service.pricePerSquare,
        //   getServiceQuantity(quantity, service._id),
        //   service.minPrice,
        //   service.fixedPrice,
        //   polygonsFeaturesSqFeet.baths,
        //   service.ranges,
        //   service.areaType
        // );
      } else if (service.areaType === SERVICE_AREA_TYPES.BEDROOM) {
        servicePrice = getEndingPrice(service, sqFeet);
        serviceQuantity = getServiceQuantity(quantity, service._id);

        servicePrice = servicePrice * serviceQuantity
        // servicePrice = getServicePrice(
        //   service.pricePerSquare,
        //   getServiceQuantity(quantity, service._id),
        //   service.minPrice,
        //   service.fixedPrice,
        //   polygonsFeaturesSqFeet.bedrooms,
        //   service.ranges,
        //   service.areaType
        // );
      } else {
        servicePrice = getEndingPrice(service, sqFeet);
        serviceQuantity = getServiceQuantity(quantity, service._id);

        servicePrice = servicePrice * serviceQuantity
        // servicePrice = getServicePrice(
        //   service.pricePerSquare,
        //   getServiceQuantity(quantity, service._id),
        //   service.minPrice,
        //   service.fixedPrice,
        //   sqFeet,
        //   service.ranges,
        //   service.areaType
        // );
      }
      const endPrice = Number(ApplyDiscountOnService(service, servicePrice /serviceQuantity, paymentType, quantity)?.discountedPrice)
      maxTotalPrice += parseFloat(applyPriceRange(customDiscounts, service, endPrice, paymentType, quantity, sqFeet, customQuotes, polygonsFeaturesSqFeet))
    } else {
      const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
      maxTotalPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
    }
  });

  state.widget.cart.packages.map((packageItem) => {
    const packagePrice = parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    maxTotalPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
  });
  
  return roundToTwoDigits(maxTotalPrice).toFixed(2)
};

export const getTotalPriceWithoutTax = () => (state) => {
  let totalPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, paymentType, customQuotes, customDiscounts } = state.widget;

  state.widget.cart.services.map((service) => {
    if (checkPriceRange([service], [], sqFeet)) {
      let servicePrice
      if (service.areaType === SERVICE_AREA_TYPES.BATHROOM) {
        servicePrice = getServicePrice(
          service.pricePerSquare,
          getServiceQuantity(quantity, service._id),
          service.minPrice,
          service.fixedPrice,
          polygonsFeaturesSqFeet.baths,
          service.ranges,
          service.areaType
        );
      } else if (service.areaType === SERVICE_AREA_TYPES.BEDROOM) {
        servicePrice = getServicePrice(
          service.pricePerSquare,
          getServiceQuantity(quantity, service._id),
          service.minPrice,
          service.fixedPrice,
          polygonsFeaturesSqFeet.bedrooms,
          service.ranges,
          service.areaType
        );
      } else {
        servicePrice = getServicePrice(
          service.pricePerSquare,
          getServiceQuantity(quantity, service._id),
          service.minPrice,
          service.fixedPrice,
          sqFeet,
          service.ranges,
          service.areaType
        );
      }
      servicePrice = ApplyDiscountOnService(service, servicePrice, paymentType, quantity)?.discountedPrice
      console.log({servicePrice})
      totalPrice += parseFloat(applyPriceRange(customDiscounts, service, Number(servicePrice), paymentType, quantity, sqFeet, customQuotes, polygonsFeaturesSqFeet))
    } else {
      const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
      totalPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
    }
  });

  state.widget.cart.packages.map((packageItem) => {
    const packagePrice = parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    totalPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
  });
  
  return roundToTwoDigits(totalPrice).toFixed(2)
};

export const getEstimatedTax = () => (state) => {
  let totalPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { quantity, customQuotes } = state.widget;
  const { paymentType, customDiscounts } = state.widget;
  // const {boundary} = state.widget

  state.widget.cart.services.map((service) => {
    if (checkPriceRange([service], [], sqFeet)) {
      let servicePrice = getServicePrice(
        service.pricePerSquare,
        getServiceQuantity(quantity, service._id),
        service.minPrice,
        service.fixedPrice,
        sqFeet,
        service.ranges,
        service.areaType
      );
      servicePrice = Number(ApplyDiscountOnService(service, servicePrice, paymentType, quantity)?.discountedPrice)
      totalPrice += parseFloat(applyPriceRange(customDiscounts, service, servicePrice, paymentType, quantity, sqFeet, customQuotes))
    } else {
      const servicePrice = parseFloat(getDiscountedServicePrice(service, quantity, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
      totalPrice += parseFloat(ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, paymentType, quantity))
    }
});

  state.widget.cart.packages.map((packageItem) => {
    const packagePrice = parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    totalPrice += parseFloat(ApplyPackageCustomDiscounts(customDiscounts, packageItem, packagePrice, paymentType))
  });
  totalPrice = (totalPrice * tax) / 100;
  
  return roundToTwoDigits(totalPrice).toFixed(2)
}


export const getCustomerRedirectURL = (customer,orderPaymentType) => {
  let redirectUrl = '/widget/thank-you'

  // check if customer has redirectUrl attached to payment Types
  if (customer?.branding?.paymentButtons?.length) {
    customer.branding.paymentButtons.forEach((btn) => {
      if (btn.redirectUrl && btn.const === orderPaymentType) {
        redirectUrl = btn.redirectUrl
      }
    })
  }
    
  return redirectUrl  
}

export const getTotalOrderDiscountAmount = () => (state) => {
  const {orderDiscounts, paymentType } = state.widget
  if(!orderDiscounts){
    return null
  }
  const subTotal = getTotalPriceWithoutTax()(state)
  const {discountedTotal} = calculateDiscount(orderDiscounts, subTotal, paymentType)
  return roundToTwoDigits(parseFloat(subTotal) - discountedTotal).toFixed(2)
}

export const isOnOrderDetailsPage = (router): boolean => {
  return router.pathname === '/contractor/order-details/[id]'
}

export const isOnLeadDetailsPage = (router): boolean => {
  return router.pathname === '/contractor/lead-details/[id]'
}

export const isOnEstimatePage = (router): boolean => {
  return router.pathname === '/contractor/search/[id]'
}

export const isOnQuotePage = (router): boolean => {
  return router.pathname === '/quote/[id]'
  // return (router.pathname === '/quote/[id]' || router.pathname === '/contractor/convert-to-order/[id]')
}

export const isOnWidgetPage = (router): boolean => {
  return router.pathname === '/widget/[id]'
}

export const isOnSearchPage = (router): boolean => {
  return router.pathname === '/contractor/search/[id]'
}

export const calculateMaxTotalWithoutTax = () => (state) => {
  let totalMaxPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { paymentType, quantity, customQuotes, cart, customDiscounts } = state.widget;
  // const { paymentType } = state.widget;
  // const {boundary} = state.widget;

  state.widget.cart.services.map((service) => {
    if(checkPriceRange([service], [], sqFeet)){
      let servicePrice
      if (service.areaType === SERVICE_AREA_TYPES.BATHROOM) {
        servicePrice = getEndingPrice(service, sqFeet)
        // servicePrice = getServicePrice(
        //   service.pricePerSquare,
        //   getServiceQuantity(quantity, service._id),
        //   service.minPrice,
        //   service.fixedPrice,
        //   polygonsFeaturesSqFeet.baths,
        //   service.ranges,
        //   service.areaType
        // );
      } else if (service.areaType === SERVICE_AREA_TYPES.BEDROOM) {
        servicePrice = getEndingPrice(service, sqFeet)
        // servicePrice = getServicePrice(
        //   service.pricePerSquare,
        //   getServiceQuantity(quantity, service._id),
        //   service.minPrice,
        //   service.fixedPrice,
        //   polygonsFeaturesSqFeet.bedrooms,
        //   service.ranges,
        //   service.areaType
        // );
      } else {
        servicePrice = getEndingPrice(service, sqFeet)
        // servicePrice = getServicePrice(
        //   service.pricePerSquare,
        //   getServiceQuantity(quantity, service._id),
        //   service.minPrice,
        //   service.fixedPrice,
        //   sqFeet,
        //   service.ranges,
        //   service.areaType
        // );
      }
      servicePrice = ApplyDiscountOnService(service, servicePrice, paymentType, quantity)?.discountedPrice
      totalMaxPrice += parseFloat(applyPriceRange(customDiscounts, service, servicePrice, paymentType, quantity, sqFeet, customQuotes))
    } else {
    totalMaxPrice += parseFloat(getDiscountedServicePrice(service, quantity,sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    }
  });

  state.widget.cart.packages.map((packageItem) => {
    totalMaxPrice += parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
  });


  const orderDiscountAmount = parseFloat(getTotalOrderDiscountAmount()(state))
  if(orderDiscountAmount > 0){
    totalMaxPrice -= orderDiscountAmount
  }
  return roundToTwoDigits(totalMaxPrice).toFixed(2)
};

export const calculateTotalWithoutTax = () => (state) => {
  let totalPrice = 0;
  const { sqFeet, tax, measurement, polygonsFeaturesSqFeet } = state.measurement;
  const { paymentType, quantity, customQuotes, cart, customDiscounts } = state.widget;
  // const { paymentType } = state.widget;
  // const {boundary} = state.widget;

  state.widget.cart.services.map((service) => {
    if(checkPriceRange([service], [], sqFeet)){
      let servicePrice
      if (service.areaType === SERVICE_AREA_TYPES.BATHROOM) {
        servicePrice = getServicePrice(
          service.pricePerSquare,
          getServiceQuantity(quantity, service._id),
          service.minPrice,
          service.fixedPrice,
          polygonsFeaturesSqFeet.baths,
          service.ranges,
          service.areaType
        );
      } else if (service.areaType === SERVICE_AREA_TYPES.BEDROOM) {
        servicePrice = getServicePrice(
          service.pricePerSquare,
          getServiceQuantity(quantity, service._id),
          service.minPrice,
          service.fixedPrice,
          polygonsFeaturesSqFeet.bedrooms,
          service.ranges,
          service.areaType
        );
      } else {
        servicePrice = getServicePrice(
          service.pricePerSquare,
          getServiceQuantity(quantity, service._id),
          service.minPrice,
          service.fixedPrice,
          sqFeet,
          service.ranges,
          service.areaType
        );
      }
      servicePrice = ApplyDiscountOnService(service, servicePrice, paymentType, quantity)?.discountedPrice
      totalPrice += parseFloat(applyPriceRange(customDiscounts, service, servicePrice, paymentType, quantity, sqFeet, customQuotes))
    } else {
    totalPrice += parseFloat(getDiscountedServicePrice(service, quantity,sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
    }
  });

  state.widget.cart.packages.map((packageItem) => {
    totalPrice += parseFloat(getDiscountedPackagePrice(packageItem, sqFeet, paymentType, customQuotes, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet))
  });


  const orderDiscountAmount = parseFloat(getTotalOrderDiscountAmount()(state))
  if(orderDiscountAmount > 0){
    totalPrice -= orderDiscountAmount
  }
  return roundToTwoDigits(totalPrice).toFixed(2)
};

const formatDate = (date) => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(relativeTime)
  const user_timeZone = dayjs.tz.guess()
  return `${dayjs(date).tz(user_timeZone).format('MMMM DD, YYYY [at] hh:mm A')}`
}

export const getSessionString = (session,key,index) =>{
  let str = ''
  if (key==='searched_address') {
    str = `Address searched ${index!==0 ? 'again' : ''} on ${formatDate(session[key])}`
  } else if (key==='viewed_services') {
    str = `viewed_services`
  } else if (key==='abandoned_cart') {
    str = `Cart abandoned on ${formatDate(session[key])}`
  }else if (key==='Order_placed') {
    str = `Order placed on ${formatDate(session[key])}`
  }
  return str
}


export const getRequiredFeatures = (customerFeatures, searchedFeatures) => {
  // return the search features that are enabled for contractor
  const requiredFeatures = customerFeatures.filter(feature=> searchedFeatures.includes(feature))
  return requiredFeatures
}

export const getMeasurementImage = async (Center, polygons, zoom, type) => {
  const { data } = await sendRequest('POST', '/measurement/image', { Center, polygons, zoom, type })
  return data
};

export const getCustomerServiceAutoPilotData = async (customerId) => {
  const { data } = await sendRequest('GET', `/service-autopilot/customer/${customerId}`)
  if (!data?.forms || data.statusCode) return
  return data
};

export const calculateCardSize = (Size) => {
  if (Size === 'small') {
    return 3;
  }
  if (Size === 'medium') {
    return 2;
  }
  if (Size === 'large') {
    return 1;
  }
}

export const getRecommendedCardSize = (size) => {
  if (size === 'small') {
    return 'Recommended size: (940W x 940L)';
  }
  if (size === 'medium') {
    return 'Recommended size: (2300W x 940L)';
  }
  if (size === 'large') {
    return 'Recommended size: (2300W x 940L)';
  }
};

// import { ObjectLiteralElement } from "typescript";
// import { Button } from "../Buttons/Button";
import React from 'react'
import { useState } from "react";
// import {FaSort} from 'react-icons/fa'
import {RxCaretSort} from 'react-icons/rx'

interface TableProps {
  handleSorting?: (sortField: string, sortOrder: string) => void;
  tabledata?: any[]
  headers: any[]
  isEdit?: string
  enableSorting?: boolean,
  rowHeight?:string
}
export const Table = ({ tabledata, headers,enableSorting, handleSorting, isEdit,rowHeight, ...props }: TableProps) => {
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortingChange = (accessor: string) => {
    const newSortOrder =
      sortField === accessor && sortOrder === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setSortOrder(newSortOrder);
    handleSorting && handleSorting(accessor, newSortOrder);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-0">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="px-">
                <tr>
                  {headers && headers.map((each,idx) =>
                    <th
                    key={idx}
                      scope="col"
                      className={`pl-4 pr-5 min-w-min w-auto py-2 text-left text-normal font-semibold text-gray-500 sm:pl-0`}
                    >
                      <div className="flex justify-start items-center gap-2 ml-5 ">
                        {each.name === 'view' ? 'Details' : each.name === 'delete' ? 'Delete' : each.name}
                        {enableSorting && each.name !== 'view' && each.ref !== null ?

                        <RxCaretSort size={20} className="text-gray-500 hover:text-gray-700 text-sm cursor-pointer" onClick={() =>  handleSortingChange(each.ref)}/>
                        :null}
      
                      </div>
                    </th>
                  )}
                </tr>

              </thead>
              <tbody className="divide-y w-96 divide-gray-200">
                {tabledata && tabledata?.map((each, index: any) =>
                  <tr key={index} className="hover:bg-gray-50">
                    {Object.keys(tabledata[0]).filter(item => item !== 'key').map((item) =>
                      <td className={`whitespace-nowrap min-w-min w-auto pr-5 py-${!rowHeight?'4':rowHeight==='lg'?'4':rowHeight==='md'?'3':rowHeight==='sm'?'2':'4'} text-sm text-black pl-5`}>
                        {(each[item] && each[item] !== "N/A") ? each[item] : '-'}
                      </td>
                    )}
                  </tr>
                )}
                { isEdit && <tr>
                  <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                  <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                  <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                  <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                  <td className="whitespace-nowrap px-3 py-9 text-sm text-gray-500"></td>
                </tr>}
              </tbody>
            </table>
            <hr className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

import { packagesTypes } from './packages.types';

const INITIAL_STATE = {
  loading: false,
  packageItem: null,
  packages: null,
  errorMessages: [],
  region:null
};

const packagesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case packagesTypes.SET_PACKAGES:
      return {
        ...state,
        packages: payload,
        loading: false,
      };
    case packagesTypes.SET_PACKAGE:
      return {
        ...state,
        packageItem: payload,
        loading: false,
      };
    case packagesTypes.SET_ERROR_PACKAGES:
      return {
        ...state,
        errorMessages: payload,
        loading: false,
      };
    case packagesTypes.SET_PACKAGES_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case packagesTypes.SET_PACKAGES_REGION:
      return {
        ...state,
        region: payload
      }
    default:
      return state;
  }
};

export default packagesReducer;

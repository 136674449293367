import { applyServicesDiscountsOnPackage } from "./applyServicesDiscountsOnPackage";
import { calculateDiscount } from "./calculateDiscount";
import { getPackagePrice } from "./getPackagePrice";
import { roundToTwoDigits } from "./roundToTwoDigits";

const getPackagePriceWithDiscount = (packageItem, sqFeet, cartPaymentType, customQuotes, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  let packagePrice = 0
  let appliedDiscounts = []
  if(packageItem?.servicesDiscounts?.length){
    const {discountedPrice, discounts} = applyServicesDiscountsOnPackage(packageItem, sqFeet, customQuotes, cartPaymentType, buildingSquareFeet, polygonsFeaturesSqFeet)
    packagePrice = discountedPrice
    appliedDiscounts.push(...discounts)
  } else {
    packagePrice = getPackagePrice(packageItem, sqFeet, customQuotes, buildingSquareFeet, polygonsFeaturesSqFeet);
  }
  if (packageItem.discount?.length) {
    const {discountedTotal, discounts} = calculateDiscount(packageItem.discount, packagePrice, cartPaymentType)
    if(discountedTotal != null && discountedTotal != undefined) {
      packagePrice = discountedTotal
      appliedDiscounts.push(...discounts)
    }
    return {discountedPrice: roundToTwoDigits(packagePrice).toFixed(2), discounts: appliedDiscounts};
  }
  return {discountedPrice: roundToTwoDigits(packagePrice).toFixed(2), discounts: appliedDiscounts};
};

export default getPackagePriceWithDiscount
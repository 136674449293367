export const discountPaymentTypeConst = {
  PAY_AS_YOU_GO: "PAY_AS_YOU_GO",
  PRE_PAY: "PRE_PAY",
  PAY_BY_CHECK: "PAY_BY_CHECK",
  BOTH: "BOTH",
}

export const paymentTypeValuesConst = {
  PAY_AS_YOU_GO: "Pay As You Go",
  PRE_PAY: "Pre Pay",
  BOTH: "Both",
}
import { RiSearchLine } from "react-icons/ri";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface SearchProps {
  label?: string;
  onChange?: (e: any) => void;
  onSubmit?: () => void;
  onKeyPress?: (e: any) => void;
}

export const Search=({
  label,
  onChange,
  onSubmit,
  onKeyPress,
  ...props
}: SearchProps)=> {
  return (
    <div className="w-full md:w-[288px]">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <RiSearchLine className="text-2xl text-gray-400" />
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full px-3 py-2 pl-10 text-l text-gray-900 border border-gray-300 rounded-xl bg-white focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500 placeholder:text-gray-300"
            placeholder={`Search ${label}`}
            onChange={onChange}
            onKeyPress={onKeyPress}
            required
          />
        </div>
    </div>
  );
}

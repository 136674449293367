import { XCircleIcon } from '@heroicons/react/solid';
import React from 'react';

const ErrorAlert = ({ errorMessages }: any) => (
  <div className="rounded-md bg-red-50 p-4">
    <div className="flex">
      <div>
        <XCircleIcon className="h-5 w-5 text-red-400 " aria-hidden="true" />
        <div className="mt-2 text-sm text-red-700">
          {errorMessages && errorMessages.length > 0 && (
            <ul className="list-disc pl-5 space-y-1">
              <p />
              {Array.isArray(errorMessages)
                ? errorMessages?.map((message) => <li key={message}>{message}</li>)
                : ''}
            </ul>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default ErrorAlert;

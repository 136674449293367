export const CREATE_LEAD = {
  PATH: '/leads',
  METHOD: 'POST'
}

export const GET_LEADS = {
  PATH: '/leads/contractor',
  METHOD: 'GET'
}

export const GET_LEADS_BY_PLACEID = {
  PATH: '/leads/findByPlaceId',
  METHOD: 'GET'
}

export const GET_LEAD = {
  PATH: '/leads',
  METHOD: 'GET'
}

export const UPDATE_LEAD = {
  PATH: '/leads',
  METHOD: 'PATCH'
}

export const UPDATE_LEAD_IS_CHECKED = {
  PATH: '/leads/update-is-checked',
  METHOD: 'PATCH'
}

export const DELETE_LEAD = {
  PATH: '/leads',
  METHOD: 'DELETE'
}

export const GET_SEARCHED_LEADS = {
  PATH: '/leads/search',
  METHOD: 'POST'
}
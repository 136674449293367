import { SearchesLogTypes } from "./searches-log.types";

const INITIAL_STATE = {
  searchesLog:null,
  measurements: null,
  totalCount: 0,
  searchesOverview: null,
  loading: false,
  error: null
};

const SearchesLogReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SearchesLogTypes.ADD_SEARCHES_LOGS:
        return {
        ...state,
        searchesLog: payload
      };
    case SearchesLogTypes.SET_MEASUREMENTS:
        return {
        ...state,
            measurements: payload.measurements,
            totalCount: payload.totalCount
      };
    case SearchesLogTypes.SET_SEARCHES_OVERVIEW:
        return {
        ...state,
          searchesOverview: payload,
        };
    case SearchesLogTypes.SET_ERROR_SEARCHES_LOGS:
      return {
        ...state,
        searchesOverview: null,
        error: payload,
      };
    case SearchesLogTypes.SET_LOADING_SEARCHES:
      return {
        ...state,
        loading: payload
      }
    
    default:
      return state;
  }
};

export default SearchesLogReducer;

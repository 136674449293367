import { IServiceArea } from './service-area.interface';
import { serviceAreaTypes } from './service-area.types';

const INITIAL_STATE: IServiceArea = {
  loading: false,
  serviceArea: null,
  regionCountLoading: '',
  error: '',
};

const ServiceAreaReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case serviceAreaTypes.SET_SERVICE_AREA:
      return {
        ...state,
        serviceArea: payload,
      };
    case serviceAreaTypes.SET_SERVICE_AREA_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case serviceAreaTypes.SET_REGION_COUNT_LOADING:
      return {
        ...state,
        regionCountLoading: payload,
      };
      case serviceAreaTypes.SET_ERRORS:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default ServiceAreaReducer;

import { useMemo } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './rootReducer';
import jwtDecode from 'jwt-decode';
import { logoutUser } from './user/user.actions';

export let store;
const middlewares = [thunkMiddleware];

const checkTokenExpirationMiddleware = (store) => (next) => (action) => {

  if (store.getState().user.token) {
    if (jwtDecode(store.getState().user.token).exp < Date.now() / 1000) {
      localStorage.clear();
   
      next(action);
    }
  }
  next(action);
};

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: ['user'], 
};

const persistedReducer = persistReducer(persistConfig, reducers);

function initStore(initialState) {
  if(process.env.NEXT_PUBLIC_REDUX_DEV_TOOLS === 'true') {
    return createStore(
      persistedReducer,
      initialState,
      composeWithDevTools(applyMiddleware(...middlewares))
    );
  } else {
    return createStore(
      persistedReducer,
      initialState,
      compose(applyMiddleware(...middlewares))
    );
  }
  
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

 
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
   
    store = undefined;
  }

  if (typeof window === 'undefined') return _store;
  
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}

import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TeamMemberDetails from '../../components/TeamMemberDetails';
import ContractorOrders from '../../components/contractor-orders/contractor-orders.component';
import LoadingAnimation from '../../components/loading/loadingAnimation.component';
import HomeStepper from '../../components/stepper/stepper';
import ErrorAlert from '../../components/tailwind/alerts/error-alert.component';
import VideoModal from '../../components/video-modal/video-modal';
import { DashboardLayout } from '../../layouts/dashboard/dashboard.layout';
import { getCustomer } from '../../redux/customer/customer.actions';
import { getAllIntegrations } from '../../redux/integrations/integrations.actions';
import { getTeamMember } from '../../redux/teamMember/teamMember.action';
import { isManager, isTeamMember } from '../../utils/isTeamMember';
// import TeamMemberDetails from '../team-member-details';
import styles from './dashboard.module.scss';

const Dashboard = () => {
  const [displayStepper, setDisplayStepper] = useState(true);
  const [teamMemberStepper, setTeamMemberStepper] = useState(false)
  const dispatch = useDispatch();
  const router = useRouter()
  const { customer, loading: customerLoading } = useSelector((state: any) => state.customer);
  const customerError = useSelector((state: any) => state.customer.error);
  const { user } = useSelector((state: any) => state.user)
  const { member } = useSelector((state: any) => state.teamMember)

  useEffect(() => {
    dispatch(getCustomer());
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(getTeamMember(user._id))
    }
  }, [user])

  useEffect(() => {
    if (customer) {
      if (isTeamMember(user)) {
        const { teamMemberSteps } = member
        if (teamMemberSteps?.namePic === false) {
          setTeamMemberStepper(true)
        }
      } else {
        const { stepsCompleted } = customer;
        if ((stepsCompleted?.addedWidget || stepsCompleted?.skipAddWidget)
          && stepsCompleted.addedService
          && stepsCompleted.addedServiceArea
          && stepsCompleted.completedProfile) { setDisplayStepper(false); }
      }
      dispatch(getAllIntegrations(customer?._id))
      }
  }, [customer]);

  if (!customer || customerLoading) return <LoadingAnimation fullScreen={true}/>

  if (customerError && customerError.length) {
    return (
      <div className={styles.error}>
        <ErrorAlert errorMessages={[customerError]} />
      </div>
    );
  }

  function renderHome() {
    if (customer?.settings) {
      if (customer.settings['managerSelectOptions'] && isManager(user)) {
        if (customer.settings['managerSelectOptions']['salesmenSelected'] && customer.settings['managerSelectOptions']['dateRangeSelected']) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  return (
    <>
      {
        isTeamMember(user) ? (teamMemberStepper ? <TeamMemberDetails setTeamMemberStepper={setTeamMemberStepper} /> 
        : 
          renderHome() ? <DashboardLayout>
            <div className='p-5'>
              <ContractorOrders />
            </div>
          </DashboardLayout> : <LoadingAnimation fullScreen={true} />
          )
          :
          <DashboardLayout>
            <VideoModal />
            {
              customerLoading ? <LoadingAnimation /> : (
                <div className="px-8 pb-8 pt-[34px]">
                  {displayStepper ? <HomeStepper /> : <ContractorOrders />}
                </div>
              )}
          </DashboardLayout>}
    </>
  );
};

export default Dashboard;
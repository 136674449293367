import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { getRecommendedCardSize } from '../../redux/widget/widget.selectors';


const FormFileUploader = ({ imageUrl, imageLoading, imageUpload, recommendedSize }) => {

    const onDrop = useCallback((acceptedFiles) => {
        imageUpload(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        disabled: false,
        accept: "image/jpeg, image/png",
    });

    return (
        <div className=''>
            {/* <label className='text-sm font-medium'>Media</label> */}
            <div {...getRootProps()} className='w-full border-1 border-sky-400 border-dashed px-10 py-7 xl:px-20 xl:py-14 rounded-xl bg-gray-50 opacity-75 hover:border-green-500 cursor-pointer' >
                <input {...getInputProps()} />
                {imageUrl && !imageLoading && (
                    <div className="mb-4 flex justify-center w-full">
                        <img
                            className="preview"
                            src={imageUrl}
                            alt=""
                           
                        />
                    </div>
                )}
                {/* {!imageUrl && <img src="https://www.svgrepo.com/show/391014/money.svg" alt="image icon" className='block w-1/2 mx-auto' />} */}
                {!imageUrl && <img src="/ArrowUpTray.svg" alt="image icon" className='block max-w-[50px] mx-auto' />}
                {/* <img src="/assets/upload-svgrepo-com.svg" alt="upload icon" className='block mx-auto w-5' /> */}
                <p className='text-center text-sm mt-1 whitespace-wrap text-gray-900 font-semibold'>Drag images here or click to upload</p>
                <p className='text-gray-700 text-xs text-center mt-1'>Formats .jpg and .png</p>
            </div>
            <span className='text-gray-900 text-sm'>{getRecommendedCardSize(recommendedSize)}</span>
        </div>
    )
};

export default FormFileUploader;

import { SERVICE_AREA_TYPES } from '../constants/services.constants';
import { calculateDiscount } from './calculateDiscount';
import { getServicePrice } from './getServicePrice';
import getServicePriceWithDiscount from './getServicePriceWithDiscount';
import { getServiceSqFeet } from './getServiceSqFeet';
import { roundToTwoDigits } from './roundToTwoDigits';

export const applyServicesDiscountsOnPackage = (packageItem, sqFeet, customQuotes, cartPaymentType, buildingSquareFeet, polygonsFeaturesSqFeet): {discountedPrice: number, discounts: Array<Object>} => {
  
  let appliedDiscounts = []
  let packagePrice = 0;
  for(const service of packageItem.services) {
    delete service.discount
    const currentServiceInServicesDiscounts = packageItem.servicesDiscounts.find(discount => discount.service === service._id && discount.discounts?.length !== 0 )
    if(currentServiceInServicesDiscounts){
      service.discount = currentServiceInServicesDiscounts.discounts
    }
    
    let squareFeet = getServiceSqFeet(service.areaType, sqFeet, buildingSquareFeet, polygonsFeaturesSqFeet);
    
    let servicePrice
    if ((squareFeet && Number(squareFeet) !== 0)) {
      servicePrice = getServicePrice(
        service.pricePerSquare,
        packageItem.quantity[service._id],
        service.minPrice,
        service.fixedPrice,
        squareFeet,
        service.ranges,
        service.areaType
      );
    }
    
    if (servicePrice === -1 || ((service.areaType === SERVICE_AREA_TYPES.BATHROOM || service.areaType === SERVICE_AREA_TYPES.BEDROOM) && ((squareFeet && Number(squareFeet) === 0) || !squareFeet))) {
      if(customQuotes?.packages[packageItem._id]){
        return {discountedPrice: customQuotes.packages[packageItem._id], discounts: appliedDiscounts }
      } else {
        return {discountedPrice: 0, discounts: appliedDiscounts }
      }
    }
    
    if (service.discount?.length) {
      const {discountedTotal, discounts} = calculateDiscount(service.discount, servicePrice, cartPaymentType, packageItem.quantity[service._id])
      if(discounts.length) {
        // appliedDiscounts.push(...discounts)
      }
      servicePrice = discountedTotal
    }
    packagePrice += servicePrice
  };
  return {discountedPrice: roundToTwoDigits(packagePrice), discounts: appliedDiscounts };
};

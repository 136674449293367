import sendRequest from "../../utils/useAxios"
import { getCustomer } from "../customer/customer.actions"
import { CREATE_INTEGRATION, GET_INTEGRATIONS_CUSTOMER, UPDATE_INTEGRATION } from "./integrations.api"
import { integrationTypes } from "./integrations.types"

export const createIntegration = integration => async dispatch => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(CREATE_INTEGRATION.METHOD, CREATE_INTEGRATION.PATH, integration)
  if(!data.statusCode){
    dispatch(getCustomer())
    return
  }
  return data
}

export const updateIntegration = (integration, id) => async dispatch => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(UPDATE_INTEGRATION.METHOD, `${UPDATE_INTEGRATION.PATH}/${id}`, integration)
  if(!data.statusCode){
    dispatch(getCustomer())
  }
}

export const getIntegrationsCustomer = (customerId) => async dispatch => {
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_INTEGRATIONS_CUSTOMER.METHOD, `${GET_INTEGRATIONS_CUSTOMER.PATH}/${customerId}`)
  console.log(data)
  if (data.length) {
    dispatch({
      type: integrationTypes.GET_INTEGRATIONS_CUSTOMER,
      payload: true
    })
  } else {
    dispatch({
      type: integrationTypes.GET_INTEGRATIONS_CUSTOMER,
      payload: false
    })
  }
  dispatch(setLoading(false))
}

const setLoading = payload => async dispatch => {
  dispatch({
    type: integrationTypes.SET_INTEGRATIONS_LOADING,
    payload
  })
}

export const getAllIntegrations = customerId => async dispatch => {
  const { data } = await sendRequest('GET', `/integrations/customer/${customerId}`)
  if (data && !data.statusCode) {
    dispatch({
      type:integrationTypes.SET_INTEGRATIONS_ALL,
      payload:data
    })
      return data
  }
  setIntegrationError(data)
}

export const setIntegrationError = payload => async dispatch => {
  dispatch({
    type: integrationTypes.SET_INTEGRATIONS_ERROR,
    payload
  })
}
export const setIntegrationTempData = payload => async dispatch => {
  dispatch({
    type: integrationTypes.SET_INTEGRATIONS_TEMP_DATA,
    payload
  })
}

export const deleteIntegration = async(customerId,type) =>  {
  const { data } = await sendRequest('DELETE', `/integrations/removeIntegration/${customerId}/${type}`)
  return data
}
export const GET_TEAM_MEMBER = {
    METHOD: 'GET',
    PATH: '/teams/member'
  }

export const GET_WHOLE_TEAM = {
  METHOD: 'GET',
  PATH: '/teams/manager'
}

export const UPDATE_TEAM_MEMBER = {
  METHOD: 'PATCH',
  PATH: '/teams/member'
}
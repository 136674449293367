import { createTheme } from '@material-ui/core/styles';

export const Theme = createTheme({
    palette: {
        primary: {
            light: '#555',
            main: '#222',
            dark: '#000',
            contrastText: '#FFF',
        },
        
    },
});
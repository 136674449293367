import React from 'react';
import { useRouter } from 'next/router';

interface Props {
  fullScreen?: boolean;
  currentTab?: string;
}

const LoadingAnimation = (props: Props) =>  {

    const isBillingHistory = props.currentTab === 'billing-history';

    return (
    <div className={`flex justify-center items-center ${!isBillingHistory ? 'w-screen h-screen' : 'max-h-lg'}`} >
        {/* <video className='w-full h-full max-w-md max-h-md object-contain' autoPlay={true} loop muted> */}
            <img src="/servicecart-loading.gif" />
            {/* Your browser does not support the video tag. */}
        {/* </video> */}
    </div>
)};

export default LoadingAnimation;

import { customerTypes } from './customer.types';
import sendRequest from '../../utils/useAxios';
import { CANCEL_CUSTOMER_SUBSCRIPTION, CHECK_TRIALING_LIMIT, CREATE_BRANDING, CREATE_CUSTOMER, CREATE_SETTINGS, GET_BRANDING, GET_CUSTOMER, GET_IS_CUSTOMER, GET_PUBLIC_CUSTOMER, UPDATE_BRANDING, UPDATE_CUSTOMER, UPDATE_CUSTOMER_COUNTRY, UPDATE_CUSTOMER_INFO_DETAILS, UPDATE_CUSTOMER_STEP, UPDATE_SETTINGS, UPLOAD_CUSTOMER_COMPANY_LOGO, CUSTOMER_ADVANCED_SERVICE_AREA_SUBSCRIPTION, CUSTOMER_PLAN_SUBSCRIPTION } from './customer.api';
import router from 'next/router';
import { PADDLE_ADDONS, PADDLE_MODIFIERS } from '../../constants/app.constants';

export const getCustomer = () => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_CUSTOMER.METHOD, GET_CUSTOMER.PATH);
  if (!data.statusCode && data) {
    dispatch(setCustomerCurrency(data))
    dispatch(setCuststomerSuccess(customerTypes.SET_CUSTOMER, data));
  } else {
    dispatch(setCustomerError(data?.message));
  }
  dispatch(setLoading(false));
};

export const setIsExceededTrialLimit = (userId) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(CHECK_TRIALING_LIMIT.METHOD, `${CHECK_TRIALING_LIMIT.PATH}/${userId}`);
  if (!data.statusCode) {
    dispatch(setCuststomerSuccess(customerTypes.SET_IS_EXCEEDED_TRIAL_LIMIT, data.exceededTrailLimit));
  } else {
    dispatch(setCustomerError(data?.message));
  }
  dispatch(setLoading(false));
};

export const getPublicCustomer = (contractor) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_PUBLIC_CUSTOMER.METHOD, `${GET_PUBLIC_CUSTOMER.PATH}/${contractor}`);
  if (!data.statusCode && data) {
    dispatch(setCustomerCurrency(data))
    dispatch(setCuststomerSuccess(customerTypes.SET_CUSTOMER, data));
  } else {
    dispatch(setCustomerError(data?.message));
  }
  dispatch(setLoading(false));
};

export const isCustomer = () => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_IS_CUSTOMER.METHOD, GET_IS_CUSTOMER.PATH);
  if (!data.statusCode && data) {
    dispatch(setCuststomerSuccess(customerTypes.IS_CUSTOMER, data));
  } else {
    dispatch(setCustomerError(data?.message));
  }
};

export const updateBranding = (branding) => async (dispatch, getState) => {
  const state  = getState()
  const {customer} = state.customer
  // dispatch(setLoading(true));
  const { data } = await sendRequest(UPDATE_BRANDING.METHOD, `${UPDATE_BRANDING.PATH}/${branding._id}`, branding);
  if (data.statusCode && data) {
    setCustomerError(data?.message)
    return
  } else {
    dispatch(setCuststomerSuccess(customerTypes.SET_CUSTOMER, {...customer, branding: data}))
  }
  // if (!data.statusCode && data) {
  //   dispatch(setLoading(false))
  // } else {
  //   dispatch(setLoading(false))
  // }
}

export const createBranding = (branding) => async (dispatch) => {

  dispatch(setLoading(true));
  const { data } = await sendRequest(CREATE_BRANDING.METHOD, CREATE_BRANDING.PATH, branding);
  if (!data.statusCode && data) {
    dispatch(getCustomer())
    dispatch(setLoading(false))
  } else {
    dispatch(setLoading(false))
  }
}

export const updateSettings = (settings) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(UPDATE_SETTINGS.METHOD, `${UPDATE_SETTINGS.PATH}/${settings._id}`, settings);
  if (!data.statusCode && data) {
    dispatch(getCustomer())
    dispatch(setLoading(false))
  } else {
    dispatch(setLoading(false))
  }
}

export const createSettings = (settings) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(CREATE_SETTINGS.METHOD, CREATE_SETTINGS.PATH, settings);
  if (!data.statusCode && data) {
    dispatch(getCustomer())
    dispatch(setLoading(false))
  } else {
    dispatch(setLoading(false))
  }
}

export const createCustomer = (newCustomer?: any) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(CREATE_CUSTOMER.METHOD, CREATE_CUSTOMER.PATH, newCustomer);
  if (data && !data.statusCode) {
    dispatch(setCuststomerSuccess(customerTypes.SET_ACCOUNT_URL, data.url));
  } else {
    dispatch(setCustomerError(data?.message));
  }
};

export const updateCustomerDetails = (id, body) => async (dispatch) => {
  const {data} = await sendRequest(UPDATE_CUSTOMER_INFO_DETAILS.METHOD, `${UPDATE_CUSTOMER_INFO_DETAILS.PATH}/${id}`, body)

  if (data && !data?.statusCode)
    dispatch(setCuststomerSuccess(customerTypes.SET_CUSTOMER, data));
  else
    dispatch(setCustomerError(data?.message));
}

export const updateCustomerStep = (key, router?, pushTo?) => async (dispatch) => {
  const { data } = await sendRequest(
    UPDATE_CUSTOMER_STEP.METHOD,
    `${UPDATE_CUSTOMER_STEP.PATH}/${key}`
  );
  if (data && !data.statusCode) {
    dispatch(setCuststomerSuccess(customerTypes.SET_CUSTOMER, data));
    if(router) {
      router.push(pushTo);
    }
  } else {
    dispatch(setCustomerError(data?.message));
  }
};

export const cancelCustomerSubscription = (customer) => async (dispatch) => {
  const { data } = await sendRequest(CANCEL_CUSTOMER_SUBSCRIPTION.METHOD, `${CANCEL_CUSTOMER_SUBSCRIPTION.PATH}/${customer?.subscription}`);
  if (data && !data.statusCode) {
    dispatch(setCuststomerSuccess(customerTypes.SET_CUSTOMER, data));
  } else {
    dispatch(setCustomerError(setCustomerError(data?.message)));
  }
};

export const uploadCompanyLogo = (file, updatedCustomer, router?: any) => async (dispatch) => {
  const { data } = await sendRequest(UPLOAD_CUSTOMER_COMPANY_LOGO.METHOD, UPLOAD_CUSTOMER_COMPANY_LOGO.PATH, file);
  if (data && !data.statusCode) {
    dispatch(setCuststomerSuccess(customerTypes.SET_COMPANY_LOGO, data));
    dispatch(updateCustomer({...updatedCustomer, customerLogo: data}, router));
  } else {
    dispatch(setCustomerError(setCustomerError(data?.message)));
  }
};


export const patchCustomer = (customer: any,) => async (dispatch) => {
  const { data } = await sendRequest(UPDATE_CUSTOMER.METHOD, `${UPDATE_CUSTOMER.PATH}/${customer._id}`, customer);
  if (data && !data.statusCode) {
    dispatch(setCuststomerSuccess(customerTypes.SET_CUSTOMER, {...customer, ...data}));
  } else {
    dispatch(setCustomerError(data?.message));
  }
};

export const updateCustomer = (customer: any, router?: any) => async (dispatch) => {
  dispatch(setLoading(true));
  
  const { data } = await sendRequest(UPDATE_CUSTOMER.METHOD, `${UPDATE_CUSTOMER.PATH}/${customer._id}`, customer);
  if (data && !data.statusCode) {
    // dispatch(setCuststomerSuccess(customerTypes.SET_CUSTOMER, data));
    dispatch(updateCustomerStep('completedProfile', router, '/contractor/home'))
    dispatch(setLoading(false));
  } else {
    dispatch(setCustomerError(data?.message));
  }
};
export const updateCustomerData = (customer: any, setSuccess?: (success:boolean)=>void) => async (dispatch) => {
  dispatch(setLoading(true));
  
  const { data } = await sendRequest(UPDATE_CUSTOMER.METHOD, `${UPDATE_CUSTOMER.PATH}/${customer._id}`, customer);
  if (data && !data.statusCode) {
    if(setSuccess){
      setSuccess(true)
    }
    dispatch(setLoading(false));
  } else {
    if(setSuccess){
      setSuccess(false)
    }
    dispatch(setCustomerError(data?.message));
  }
};

export const updateCustomerCountry = (customerId, state) => async (dispatch) => {
  await sendRequest(UPDATE_CUSTOMER_COUNTRY.METHOD, `${UPDATE_CUSTOMER_COUNTRY.PATH}/${customerId}`, state);
}

export const setCustomerCurrency = (customer) => (dispatch) => {
  if(customer && customer.countrySettings) {
    if(customer.countrySettings?.settings?.currency){
      dispatch({
        type: customerTypes.SET_CUSTOMER_CURRENCY,
        payload: customer.countrySettings.settings.currency,
      });
      return
    }
    dispatch({
      type: customerTypes.SET_CUSTOMER_CURRENCY,
      payload: '$',
    });
  }
}

export const setLoading = (loading) => async (dispatch) => {
  dispatch({
    type: customerTypes.SET_LOADING_CUSTOMER,
    payload: loading,
  });
};

export const setCuststomerSuccess = (type, payload) => {
  return {
    type: customerTypes[type],
    payload
  }
}

export const setCustomerError = (payload) => {
  return {
    type: customerTypes.SET_ERROR_CUSTOMER,
    payload
  }
}

export const updateAdvancedServiceAreaSubscription = (customer, enableAdvancedServiceArea) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(CUSTOMER_ADVANCED_SERVICE_AREA_SUBSCRIPTION.METHOD, CUSTOMER_ADVANCED_SERVICE_AREA_SUBSCRIPTION.PATH,
    {
      customer,
      enabled:enableAdvancedServiceArea,
      subscription_desc: PADDLE_MODIFIERS.GEO_PRICING,
      recurring:PADDLE_ADDONS[PADDLE_MODIFIERS.GEO_PRICING].MODIFIER_RECURRING,
      subscription_amount:PADDLE_ADDONS[PADDLE_MODIFIERS.GEO_PRICING].MODIFIER_AMOUNT,
      skipOneOffCharge:false
    });
  if (!data.statusCode && !data.status) {
    if(customer.settings){
      dispatch(updateSettings({enableAdvancedServiceArea, _id: customer.settings._id}))
    } else {
      dispatch(createSettings({enableAdvancedServiceArea, customerId: customer._id}))
    }
  }
  dispatch(setLoading(false))
}

export const updateCustomerPlanSubscription = (customer, hdMeasurementPlan, enableMapOverlayImage, currentPlan) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(CUSTOMER_PLAN_SUBSCRIPTION.METHOD, CUSTOMER_PLAN_SUBSCRIPTION.PATH, {customer, hdMeasurementPlan, enableMapOverlayImage, currentPlan});
  if (!data.statusCode && data) {
    if(customer.settings){
      dispatch(updateSettings({enableMapOverlayImage, _id: customer.settings._id}))
    } else {
      dispatch(createSettings({enableMapOverlayImage, customerId: customer._id}))
    }
  }
  dispatch(setLoading(false))
}

import { betaTypes } from './beta.types';

const INITIAL_STATE = {
  betaCode: {code: "", visible: true},
};

const betaCodeReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case betaTypes.SET_BETA_CODE:
      return {
        ...state,
        betaCode: payload,
      };
    default:
      return state;
  }
};

export default betaCodeReducer;

import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import { CheckIcon } from '@heroicons/react/solid';
import Typography from '@material-ui/core/Typography';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../components/card/card.component';
import { updateCustomerStep } from '../../redux/customer/customer.actions';
import { LIGHT_BLUE_BUTTON_COLOR } from '../../utils/CONSTANTS';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    color: "white",
    width: "125px",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: LIGHT_BLUE_BUTTON_COLOR
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    'Complete your Company Profile',
    "Define your Company's Service Area",
    'Add your first Service or Package',
    // 'Enable Payment Processing',
    'Place the Service Cart widget on your website',
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return { btnText: 'Complete Profile', btnUrl: '/contractor/my-account?navigateTo=account' };
    case 1:
      return { btnText: 'Service Area', btnUrl: '/contractor/service-area' };
    case 2:
      return { btnText: 'Setup Services', btnUrl: '/contractor/create-service' };
    case 3:
      return { btnText: 'Add Widget to Your Site', btnUrl: '/contractor/integrations' };
    default:
      return 'Unknown step';
  }
}

const HomeStepper = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const { customer, loading } = useSelector((state: any) => state.customer);
  const steps = getSteps();
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const skipButtonStyle = {
    color: '#238cbc',
    textDecoration: 'underline',
    backgroundColor: isHover ? "#D2E9E9" : "#DFE6E2",
    borderRadius: "10px"
  }

  useEffect(() => {
    if (customer) {
      const { stepsCompleted } = customer;
      if (!stepsCompleted?.completedProfile) setActiveStep(0);
      else if (!stepsCompleted?.addedServiceArea) setActiveStep(1);
      else if (!stepsCompleted?.addedService) setActiveStep(2);
      else setActiveStep(3);
    }
  }, [customer]);

  const handleNext = (url: any) => {
    router.push(url);
  };

  const handleReset = () => {
    router.push('/contractor/home');
  };
  const dispatch = useDispatch();
  const router = useRouter();

  const handleSkip = (index) => {
    if (index === 3) {
      dispatch(updateCustomerStep('skipAddWidget'));
    } 
  };

  return (
    <div className="container">
      <Card title="Finish your Service Cart Setup ..." classes="mb-5 text-2xl text-green-500 mt-5">
        <p className="text-gray-600 ml-3 mt-5 text-left text-lg">
          Your ecommerce store is almost ready. Complete the remaining steps below 👇
        </p>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              {/* <StepLabel>{label}</StepLabel> */}
              <div className='flex flex-row space-x-2'>
                <div className='rounded-full bg-[#238cbc] justify-center h-6 w-6 text-sm p-0.5'>{activeStep > index ? <CheckIcon className='text-white h-[20px] w-[20px]' /> : <div className='text-white ml-1.5'>{index + 1}</div>}</div>
                <span className='text-sm font-medium mt-0.5'>{label}</span>
              </div>
              <StepContent>
                <div className={classes.actionsContainer}>
                  <div className='flex gap-x-2 items-center'>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleNext(getStepContent(index).btnUrl)}
                      className={`${classes.button} hover:bg-green-200`}
                    >
                      {getStepContent(index).btnText}
                    </Button> */}
                    <button className="w-30 px-2 py-1 bg-[#238cbc] hover:bg-[#116D6E] border border-transparent text-base font-medium text-white md:text-base rounded-lg"
                      onClick={() => handleNext(getStepContent(index).btnUrl)}
                    >
                      {getStepContent(index).btnText}
                    </button>
                    {(index === 3) && (
                      <Button
                        style={skipButtonStyle}
                        onClick={() => handleSkip(index)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        Skip
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button
              onClick={handleReset}
              className={classes.button}
              style={{ backgroundColor: '#000', color: '#fff' }}
            >
              Go to Home Page
            </Button>
          </Paper>
        )}
      </Card>
    </div >
  );
};

export default HomeStepper;

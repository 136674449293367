import moment from 'moment';

export const getDatesByFilter = (filter) => {
  let startDate;
    let endDate = moment().toISOString();
    if(filter === "Today") 
      startDate = moment().startOf('day').toISOString();  
    else if(filter === "Yesterday"){
      startDate = moment().subtract(1, 'days').startOf('day').toISOString(); 
      endDate = moment().subtract(1, 'days').endOf('day').toISOString(); 
    } 
    else if(filter === "Last 7 Days") 
      startDate = moment().subtract(7, 'days').startOf('day').toISOString(); 
    else if(filter === "Last 30 Days")
      startDate = moment().subtract(1, 'months').startOf('day').toISOString();
    else if(filter === "This Month")
      startDate = moment().startOf('month').startOf('day').toISOString();
    else if(filter === "Last Month"){
      startDate = moment().subtract(1,'months').startOf('month').toISOString();
      endDate = moment().subtract(1,'months').endOf('month').toISOString();
    }
    else startDate = moment("2020-01-01T10:00:00").toISOString();
    return {startDate, endDate}
}
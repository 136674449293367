export const isLoggedIn = () => (state) => {
  return state.user.token !== '';
};
export const getUserRole = () => (state) => {
  return state.user.user ? state.user.user.roles : [];
};
export const getUserMainRole = () => (state) => {
  return state.user.user ? state.user.user.roles[0] : [];
};
export const getEmail = () => (state) => {
  return state.user.email;
}


import { calculateDiscount } from "./calculateDiscount";
import { getServiceQuantity } from "./getServiceQuantity";
import { roundToTwoDigits } from "./roundToTwoDigits";

export const ApplyServiceCustomDiscounts = (customDiscounts, service, price, cartPaymentType, quantity) => {
  if (customDiscounts?.services[service._id]?.length) {
    const serviceQuantity = typeof quantity === 'object' ? getServiceQuantity(quantity, service._id) : quantity;
    const { discountedTotal } = calculateDiscount(customDiscounts?.services[service._id], price, cartPaymentType, serviceQuantity)
    if (discountedTotal != null && discountedTotal != undefined) {
      return roundToTwoDigits(discountedTotal).toFixed(2)
    }
  }
  return price
}

export const ApplyPackageCustomDiscounts = (customDiscounts, packageItem, price, cartPaymentType) => {
  if (customDiscounts?.packages[packageItem._id]?.length) {
    const { discountedTotal } = calculateDiscount(customDiscounts?.packages[packageItem._id], price, cartPaymentType)
    if (discountedTotal != null && discountedTotal != undefined) {
      return roundToTwoDigits(discountedTotal).toFixed(2)
    }
  }
  return roundToTwoDigits(price)
}


export const ApplyDiscountOnService = (service, price, cartPaymentType, serviceQuantity) => {
  let servicePrice = price
  if (service.discount?.length) {
    const { discountedTotal, discounts } = calculateDiscount(service.discount, servicePrice, cartPaymentType, serviceQuantity)
    if (discountedTotal != null && discountedTotal != undefined) {
      servicePrice = discountedTotal
    }
    return { discountedPrice: roundToTwoDigits(servicePrice).toFixed(2), discounts };
  }
  return { discountedPrice: roundToTwoDigits(servicePrice).toFixed(2), discounts :[] };
}
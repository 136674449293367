
export const checkPriceRange = (services, packages, sqFeet) => {
    let isEndPrice = false

    isEndPrice = checkServicesEndPrice(services, sqFeet)
    if (!isEndPrice) {
        isEndPrice = checkPackagesEndPrice(packages, sqFeet)
    }

    return isEndPrice
}

function checkServicesEndPrice(services, sqFeet) {
    const retunValues = services?.map(service => checkEndRange(service, sqFeet))
    return retunValues.includes(true)
}

function checkPackagesEndPrice(packages, sqFeet) {
    const retunValues = packages?.map(pack => {
        return checkServicesEndPrice(pack?.services, sqFeet)
    })
    return retunValues.includes(true)
}

export function checkEndRange(item, sqFeet) {
    let isEndPrice = false
    for (let range of item.ranges) {
        if ((sqFeet > range.from) && ((sqFeet < range.to) || (range.to === null)) && range.fixedPrice && range?.fixedPriceEnd) {
            isEndPrice = true
            break;
        }
    }
    return isEndPrice
}
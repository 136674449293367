import { datasetTypes } from './dataset.types';

const INITIAL_STATE = {
  loading: false,
  datasetURL: '',
  error: null
};

const datasetReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case datasetTypes.SET_LOADING_DATASET:
      return {
        ...state,
        loading: payload,
      };
    case datasetTypes.SET_DATASET:
      return {
        ...state,
        datasetURL: payload,
        loading: false
      };
    case datasetTypes.SET_ERROR_DATASET:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
};

export default datasetReducer;

import sendRequest from '../../utils/useAxios';
import {leadTypes} from './leads.types';
import { CREATE_LEAD, DELETE_LEAD, GET_LEAD, GET_LEADS, UPDATE_LEAD, UPDATE_LEAD_IS_CHECKED, GET_SEARCHED_LEADS, GET_LEADS_BY_PLACEID } from './leads.api';
import { contractorTypes } from '../contractor/contractor.types';
import { addQuantityToService } from '../../utils/addQuantityToService';
import { setCart, setContactInfo, setCurrentRegion, setErrors, setWidgetStepper, toggleCategories, toggleSubCategories } from '../widget/widget.actions';
import { setLoading as setWidgetLoading } from '../widget/widget.actions';
import { validateLead } from '../../utils/validateLead';
import { SERVICE_AUTOPILOT_STATES_MAPPING } from '../../constants/states.constant';
import { saveSearchLog } from '../searches-log/searches-log.actions';
import { setMeasurementForMap } from '../measurement/measurement.actions';
import { toggleItemInArray } from '../../utils/toggleItemInArray';
import { getServiceArea } from '../service-area/service-area.actions';
import { GET_SERVICE_AREA } from '../service-area/service-area.api';
import { checkInsideServiceArea } from '../../utils/map/checkInsideServiceArea';
import { widgetTypes } from '../widget/widget.types';
import { getCustomerRedirectURL, getCustomerServiceAutoPilotData, getRequiredFeatures } from '../widget/widget.selectors';
export const createLead = (lead, router?, query?, redirectData?) => async (dispatch, getState) => {
  console.log('lead called')
  const state = getState();
  const { searchedServicesType} = state.widget;
  dispatch(setLoading(true))
  let path = CREATE_LEAD.PATH
  if(query){
    path += '?' + query
  }
  console.log({lead})
  const {data} = await sendRequest(CREATE_LEAD.METHOD, path, {...lead, leadSearchType: searchedServicesType})
  if(!data.statusCode){
    dispatch({
      type: leadTypes.SET_LEAD,
      payload: data
    })
    dispatch(setWidgetLoading(false));
    if(router) {
      const { customer, paymentType } = redirectData
      const redirectUrl = getCustomerRedirectURL(customer, paymentType)
      router.push(redirectUrl)
    }
  }
}

export const resetLead = () => dispatch => {
  dispatch({
    type: leadTypes.SET_LEAD,
    payload: null
  })
}

export const createQuoteRequestLead = (router?) => (dispatch, getState) => {
  const state = getState();
  const { measurement } = state.measurement;
  const { customer } = state.customer;
  const { cart, quantity, orderQuestions, signature, orderUser, paymentType, searchedServicesType} = state.widget;
  const { lead } = state.leads;

  let services = {}
  let onePackage = []
  let packages = []
  cart.packages.forEach( function (packageItem) {
      services = packageItem.services.map(serviceItem => (
          {
              ...serviceItem,
              quantity: packageItem.quantity[serviceItem._id]
          }
      ))
      onePackage = {...packageItem, services}
      packages = [...packages, onePackage]
    })
  const order = {
    services: addQuantityToService(cart.services, quantity),
    packages: packages?.map(orderPackage => orderPackage._id),
    signature,
    user: orderUser
  }
  const {isValid, errors} = validateLead(order, customer, orderQuestions)
  if(!isValid){
    dispatch(setErrors(errors))
    dispatch(setWidgetLoading(false));
    return
  }
  const leadPayload = {
    contractor: customer?.user,
    measurement,
    ...orderUser,
    services: addQuantityToService(cart.services, quantity),
    packages: packages?.map(orderPackage => orderPackage._id),
    signature,
    leadSessions: lead?.leadSessions,
    leadSearchType: searchedServicesType,
    
  }
  if(lead) {
    dispatch(updateLead(lead._id, leadPayload, router))
  } else {
    dispatch(createLead(leadPayload, router, ...[,], {customer, paymentType}))
  }
}

export const getLead = (leadId) => async (dispatch) => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(GET_LEAD.METHOD, `${GET_LEAD.PATH}/${leadId}`)
  if(!data.statusCode){
    dispatch({
      type: leadTypes.SET_LEAD,
      payload: data
    })
  }
}

export const deleteLead = (leadId) => async (dispatch) => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(DELETE_LEAD.METHOD, `${DELETE_LEAD.PATH}/${leadId}`)
  if(!data.statusCode){
    dispatch({
      type: leadTypes.DELETE_LEAD_BY_ID,
      payload: leadId
    })
  }
}

export const updateLead = (leadId, leadPayload, router?) => async (dispatch, getState) => {
  const state = getState();
  const {quantity, searchedServicesType} = state?.widget
  // dispatch(setLoading(true))
  let items = {}
  if(leadPayload?.services?.length){
    items['services'] = addQuantityToService(leadPayload?.services, quantity)
  }
  if(leadPayload?.packages?.length){
    items['packages'] = leadPayload?.packages?.map((packageItem) => packageItem._id)
  }
  const {data} = await sendRequest(UPDATE_LEAD.METHOD, `${UPDATE_LEAD.PATH}/${leadId}`, {...leadPayload, ...items, leadSearchType: searchedServicesType})
  if(!data.statusCode){
    dispatch({
      type: leadTypes.SET_LEAD,
      payload: data
    })
    dispatch(setWidgetLoading(false));
    if(router) {
      router.push('/widget/thank-you')
    }
  }
}

export const sendAbandonedCartEmail = (emailPayload) => async (dispatch) => {
  sendRequest('POST', '/leads/send-abandoned-cart-email', emailPayload);
  return
}

export const updateItemsInLead = () => (dispatch, getState) => {
  const state = getState();
  const {lead} = state?.leads
  const {services, packages} = state?.widget.cart
  const {quantity} = state?.widget
  if(!lead) return

  dispatch(updateLead(lead._id, {...lead, services, packages, quantity}))
} 

export const addLeadSession = (leadId, sessionPayload) => async (dispatch) => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(GET_LEAD.METHOD, `${GET_LEAD.PATH}/${leadId}`)
  if(!data.statusCode){
    let leadSessions = []
    if (data.leadSessions) {
      leadSessions = data.leadSessions
    }
    leadSessions.push(sessionPayload)
    dispatch(updateLead(leadId, { ...data, leadSessions }))
  }
}

export const updateLeadIsChecked = (leadId, leadPayload) => async (dispatch) => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(UPDATE_LEAD_IS_CHECKED.METHOD, `${UPDATE_LEAD_IS_CHECKED.PATH}/${leadId}`, leadPayload)
  dispatch(getLeads(data.contractor, "page=1"))
}

export const getLeads = (contractorId, query) => async (dispatch) => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(GET_LEADS.METHOD, `${GET_LEADS.PATH}/${contractorId}?${query}`)
  if(!data.statusCode){
    dispatch({
      type: leadTypes.SET_LEADS,
      payload: data
    })
    dispatch({
      type: contractorTypes.SET_CONTRACTOR_ORDERS_TOTAL_COUNT,
      payload: data.leadsCount 
    })
  }
  dispatch(setLoading(false))
}

export const inserNewSession = (leadData: any, latestSession?:any) => (dispatch) => {
  let leadSessions: any = []
  if (leadData?.leadSessions) {
    leadSessions = leadData.leadSessions
  }
  const newSession = latestSession?.stepper?.currentStep === 2 ? { searched_address: Date.now(), viewed_services: true } : { searched_address: Date.now() }
  leadSessions.push(newSession)
  dispatch(updateLead(leadData._id, { leadSessions }))
}

export const getLeadsByPlaceID = (placeId, contractorId) => async (dispatch, getState) => {

  dispatch(setLoading(false))
  dispatch(setLeadByPlaceIdLoading(false))
  // return
  
  const state = getState();
  const { customer } = state.customer;
  const { searchedServicesFeatures } = state.widget
  const enableMapOverlayImage = customer?.settings?.enableMapOverlayImage === 'All' ? 'All' : 'None';
  const polygonFeatures = customer?.settings?.polygonFeatures || ['LAWN']
  const requiredFeatures = getRequiredFeatures(polygonFeatures, searchedServicesFeatures)

  dispatch(setLoading(true))
  dispatch(setLeadByPlaceIdLoading(true))
  const { data } = await sendRequest(GET_LEADS_BY_PLACEID.METHOD, `${GET_LEADS_BY_PLACEID.PATH}/${placeId}/${contractorId}`)
  if (data?.response?.statusCode || !data) {
    dispatch(setLoading(false))
    dispatch(setLeadByPlaceIdLoading(false))
    return
  }
  const { measurement, latestSession, services, packages, contractor, selectedCategories, selectedSubCategories, quantity } = data
  // const { data: serviceArea } = await sendRequest(GET_SERVICE_AREA.METHOD, `${GET_SERVICE_AREA.PATH}/${contractor._id}`);
  // const region = checkInsideServiceArea(serviceArea.polygon, measurement.mapCenter)
  // if (!region) {
  //   dispatch(setLoading(false))
  //   dispatch(setLeadByPlaceIdLoading(false))
  //   return
  // }
  // dispatch(setCurrentRegion(region))
  dispatch({
    type: leadTypes.SET_LEAD,
    payload: data
  })
  dispatch(setContactInfo({
    name: data.name,
    email: data.email,
    phone: data.phone,
    comments: ''
  }))
// return
  // check if measurement includes all required polygon features
  // const dbFeatures: any = []
  // const dbFeaturesPolygons: any = {}
  // measurement.mapPolygons.map(polygonObj => {
  //   if (!dbFeatures.includes(polygonObj?.type) && requiredFeatures?.includes(polygonObj?.type)) {
  //     dbFeatures.push(polygonObj?.type)
  //   }
  //   if (!dbFeaturesPolygons[polygonObj?.type]) {
  //     dbFeaturesPolygons[polygonObj?.type] = []
  //   }
  //   dbFeaturesPolygons[polygonObj?.type]?.push(polygonObj)
  // })
  // if (requiredFeatures.length !== dbFeatures.length) {
  //   dispatch(inserNewSession(data))
  //   dispatch(setLoading(false))
  //   dispatch(setLeadByPlaceIdLoading(false))
  //   return
  // }

  // if (enableMapOverlayImage === 'All' && (!measurement?.overlayImage?.imageObject || !measurement?.overlayImage?.bounds)) {
  //   dispatch(inserNewSession(data))
  //   dispatch(setLoading(false))
  //   dispatch(setLeadByPlaceIdLoading(false))
  //   return
  // }
  // dispatch({
  //   type: widgetTypes.SET_CALL_ON_PLACE_SELECTED,
  //   payload: false,
  // });
  if (selectedCategories) {
    dispatch(toggleCategories(selectedCategories))
    dispatch(toggleSubCategories(selectedSubCategories))
  }
  // dispatch(setMeasurementForMap(measurement))
  if (latestSession?.stepper) {
    dispatch(setWidgetStepper(latestSession.stepper))
  }
  if (quantity) {
    dispatch({
      type: widgetTypes.SET_QUANTITY_FOR_SERVICE,
      payload: quantity,
    });
  }
  dispatch(setCart(services, packages))
  // new session
  dispatch(inserNewSession(data, latestSession))
  // dispatch(
  //   saveSearchLog({
  //     contractor: contractorId,
  //     measurement: measurement._id,
  //     searchedFrom: 'WIDGET',
  //     timeTaken: 0,
  //     sentBy: 'database',
  //     isBuildingSquareFeet: !!measurement?.propertyInfo?.propertyAttributes?.buildgSqFt,
  //     features: requiredFeatures,
  //   })
  // );
  dispatch(setLoading(false))
  dispatch(setLeadByPlaceIdLoading(false))
}

export const getSearchedLeads = (searchQuery, page) => async (dispatch) => {
  const query = {
    searchQuery
  }
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_SEARCHED_LEADS.METHOD, `${GET_SEARCHED_LEADS.PATH}?page=${page}`, query)
  if(!data.statusCode){
    dispatch({
      type: leadTypes.SET_LEADS,
      payload: data
    })
    dispatch({
      type: contractorTypes.SET_CONTRACTOR_ORDERS_TOTAL_COUNT,
      payload: data.leadsCount 
    })
  }
  dispatch(setLoading(false))

}

export const setLoading = (type) => (dispatch) => {
  dispatch({
    type: leadTypes.SET_LEADS_LOADING,
    payload: type,
  });
}

export const setLeadByPlaceIdLoading = (status) => (dispatch) => {
  dispatch({
    type: leadTypes.SET_LEADS_BY_PLACE_ID_LOADING,
    payload: status,
  });
}

export const setLeadWidgetAddress = (address) => (dispatch) => {
  dispatch({
    type: leadTypes.SET_WIDGET_LEAD_ADDRESS,
    payload: address,
  });
}

export const xmlHttpPostRquest = async (url: any, data: any, company_id: string) => {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    xhr.setRequestHeader('x-CompanyID', company_id);
    xhr.responseType = 'json';
    xhr.withCredentials = false;
    (xhr as any).crossDomain = true;
    xhr.send(JSON.stringify(data));
  } catch (error) {
    console.log("ServiceAutoPilot lead error ::", error)
  }
};

export const create_serviceAutoPilot_lead = (leadData, customer) => async (dispatch, getState) => {
  const store = getState()
  const { currentRegion } = store.widget
  const { place } = store.measurement
  // const { serviceAutoPilotForms } = customer
  const { _id } = customer
  const serviceAutoPilotDoc = await getCustomerServiceAutoPilotData(_id)
  const firstName = leadData.name.split(" ").map(item => item.trim()).slice(0, 1).join(' ')
  const lastName = leadData.name.split(" ").map(item => item.trim()).slice(1).join(' ')
  const address = place.formatted_address.split(',').map(item => item.trim());
  const state = address[2].split(' ');
  const leadForms = serviceAutoPilotDoc?.forms?.find(form => form.formType === 'lead' && ((form?.serviceArea_regions?.length && form.serviceArea_regions.includes(currentRegion?._id)) || !form?.serviceArea_regions?.length))

  try {
    await xmlHttpPostRquest('https://my.serviceautopilot.com/MarketingBFF/Form/ApplyTagsOnClick', {
      request: {
        companyId: leadForms?.companyId || serviceAutoPilotDoc?.companyId,
        formTemplateId: leadForms?.formId,
      },
    },
      leadForms?.companyId || serviceAutoPilotDoc?.companyId
    );

    await xmlHttpPostRquest('https://my.serviceautopilot.com/MarketingBFF/Form/SubmitForm', {
      request: {
        formTemplateId: leadForms?.formId,
        data: {
          pages: [
            {
              page: 1,
              fields: [
                {
                  fieldId: leadForms?.formFields?.firstName || 3,
                  value: firstName,
                },
                {
                  fieldId: leadForms?.formFields?.lastName || 4,
                  value: lastName,
                },
                {
                  fieldId: leadForms?.formFields?.email || 5,
                  value: leadData.email,
                },
                {
                  fieldId: leadForms?.formFields?.phone || 6,
                  value: leadData.phone,
                },
                {
                  fieldId: leadForms?.formFields?.addressLine1 || 7,
                  value: address[0],
                },
                {
                  fieldId: leadForms?.formFields?.addressLine2 || 8,
                  value: "",
                },
                {
                  fieldId: leadForms?.formFields?.city || 9,
                  value: address[1],
                },
                {
                  fieldId: leadForms?.formFields?.state || 10,
                  value: state[0] ? (serviceAutoPilotDoc.stateIds ? serviceAutoPilotDoc?.stateIds[state[0]] : SERVICE_AUTOPILOT_STATES_MAPPING[state[0]]) : '',
                },
                {
                  fieldId: leadForms?.formFields?.postalCode || 11,
                  value: state[1],
                },
              ],
            },
          ],
        },
      },
    },
      leadForms?.companyId || serviceAutoPilotDoc?.companyId
    );
  } catch (error) {
    console.log("serviceAutoPilot lead error: ", error)
  }
}
export const GET_CONTRACTOR_MEASUREMENTS = {
	METHOD: 'GET',
	PATH: '/searches-log/get-measurements'
}

export const GET_CONTRACTOR_SEARCHES_OVERVIEW = {
	METHOD: 'GET',
	PATH: '/searches-log/contractor-overview'
}


export const SAVE_SEARCH_LOG = {
	METHOD: 'POST',
	PATH: '/searches-log'
}

export const GET_UNIQUE_MEASUREMENTS = {
	METHOD: "GET",
	PATH: '/searches-log/get-unique-measurements'
}

export const SEARCH_MEASUREMENTS = {
	METHOD: "POST",
	PATH: '/searches-log/search'
}

import { integrationTypes } from "./integrations.types";

const INITIAL_STATE = {
  payapl: null,
  loading: false,
  integrationsPresent: false,
  allIntegrations:[],
  allEnabled:[],
  tempData:null
}

export const integrationsReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action

  switch (type) {
    case integrationTypes.SET_INTEGRATIONS:
      return {
        ...state,
        paypal: payload,
        loading: false
      }
    case integrationTypes.SET_INTEGRATIONS_LOADING:
      return {
        ...state,
        loading: payload
      }
    case integrationTypes.GET_INTEGRATIONS_CUSTOMER:
      return {
        ...state,
        integrationsPresent: payload
      }
    case integrationTypes.SET_INTEGRATIONS_ALL:
      return {
        ...state,
        // allIntegrations: payload,
        allEnabled:payload?.length ?payload?.map((each)=>each?.type):[],
        integrationsPresent:payload?.length?true:false
      }
      case integrationTypes.SET_INTEGRATIONS_TEMP_DATA:
        return {
          ...state,
          tempData:payload
        }
    default:
      return state;
  }

}
import { appointmentTypes } from "./appointment.types"

const INITIAL_STATE = {
    date: null,
    timeSlot: null,
    contractorId: null,
    slotType: null,
    orderId: null
}

export const appointmentReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch(type){
        case appointmentTypes.SET_APPOINTMENT: 
            return {
                ...state,
                date: payload.date,
                contractorId: payload.contractorId,
                timeSlot: payload.timeSlot,
                slotType: payload.slotType
            }
        default:
            return state
    }
}
import { measurementTypes } from './measurement.types';

const INITIAL_STATE = {
  loading: false,
  measurementsList: null,
  place: null,
  measurement: null,
  annotations: null,
  sqFeet: 0,
  polygonsFeaturesSqFeet: {},
  tax: 0,
  isHDMeasurement: false
};

const measurementReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case measurementTypes.GET_MEASUREMENT:
      return {
        ...state,
        measurement: payload,
        loading: false
      };
    case measurementTypes.GET_ORIGINAL_MEASUREMENT:
      return {
        ...state,
        measurement: payload,
        annotations:payload.mapPolygons,
        loading: false
      };
    case measurementTypes.SET_MEASUREMENT_LIST:
      return {
        ...state,
        measurementsList: payload,
        loading: false
      };
    case measurementTypes.SET_INITIAL_MEASUREMENT:
      return {
        ...INITIAL_STATE,
      };
    case measurementTypes.UPDATE_MEASUREMENT_LIST:
      return {
        ...state,
        measurementsList: state.measurementsList.filter(
          (measurement) => measurement.placeId != payload
        ),
        loading: false
      };
    case measurementTypes.SET_LOADING_MEASUREMENT:
      return {
        ...state,
        loading: payload,
      };
    case measurementTypes.IS_HD_MEASUREMENT:
      return {
        ...state,
        isHDMeasurement: payload,
      };
    case measurementTypes.SET_PLACE:
      return {
        ...state,
        place: payload,
      };
    case measurementTypes.SET_ANNOTATIONS:
      return {
        ...state,
        annotations: payload,
      };
    case measurementTypes.SET_MEASUREMENT:
      return {
        ...state,
        measurement: payload,
      };
    case measurementTypes.SET_TAX:
      return {
        ...state,
        tax: payload,
        loading: false
      };
    case measurementTypes.SET_SQ_FEET:
      return {
        ...state,
        sqFeet: payload,
      };
      case measurementTypes.SET_POLYGONS_FEATURES_SQ_FEET:
        return {
          ...state,
          polygonsFeaturesSqFeet: payload,
        };
        case measurementTypes.SET_BATHS:
      return {
        ...state,
        polygonsFeaturesSqFeet: {...state.polygonsFeaturesSqFeet, baths: payload},
      };
      case measurementTypes.SET_BED_ROOMS:
      return {
        ...state,
        polygonsFeaturesSqFeet: {...state.polygonsFeaturesSqFeet, bedrooms: payload},
      };
    default:
      return state;
  }
};

export default measurementReducer;

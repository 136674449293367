export const GET_MEASUREMENT = {
	METHOD: 'GET',
	PATH: '/measurement/'
}

export const GET_ORIGINAL_MEASUREMENT = {
	METHOD: 'GET',
	PATH: '/measurement/originalMeasurement'
}

export const UPDATE_MEASUREMENT = {
	METHOD: 'PATCH',
	PATH: '/measurement/'
}

export const GET_MEASUREMENT_STATUS = {
	METHOD: 'GET',
	PATH: '/measurement/status'
}

export const GET_MEASUREMENT_TAX = {
	METHOD: 'GET',
	PATH: '/tax-manager'
}
import { IAreaAddress } from './area-address.interface';
import { AreaAddressTypes } from './AreaAddress.types';

const INITIAL_STATE: IAreaAddress = {
  loading: false,
  count: 0,
};

const AreaAddressReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case AreaAddressTypes.SET_COUNT:
      return {
        ...state,
        count: payload,
      };
    default:
      return state;
  }
};

export default AreaAddressReducer;

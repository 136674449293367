import { useSelector } from 'react-redux';
import { getPackagePrice } from '../../utils/getPackagePrice';
import { getServicePrice } from '../../utils/getServicePrice';
import { orderTypeConst } from '../../constants/orderType.constants';

export const isLoggedIn = () => (state) => {
  return state.user.token !== '';
};

export const getCustomerAccountId = () => (state) => {
  return state.customer.accountId;
};

export const getCustomerData = () => (state) => {
  return state.customer.customer;
};

export const getEnabledPaymentButtons = () => (state) => {
  const paymentButtons = state.customer?.customer?.branding?.paymentButtons
  if(paymentButtons){
    return paymentButtons.filter(button => button.enabled)
  }
};

export const hasCustomerDisabledTax = (customer): boolean => {
  return customer && customer.settings && customer?.settings?.enabledTax === false
}

export const displaySignatureCanvas = (customer, isEditable): boolean => {
  return customer.isSignatureEnabled && isEditable
}

export const hasCustomerEnabledServiceAutopilot = (customer): boolean => {
  return customer?.serviceAutoPilotEnabled
}

export const isOnDesktop = (): boolean => {
  return typeof(window) !== 'undefined' && window.innerWidth >= 1024
}

export const displayCheckoutQuestions = (customer) => {
  if(customer?.branding?.checkoutQuestions?.questions && customer?.branding?.checkoutQuestions?.questions?.length !== 0){
    return true
  }
  return false
}

export const getPostAcceptTerms = (customer): string => {
  return (customer.forceAcceptTerms && customer.branding?.postTermText) ? customer?.branding?.postTermText : ''
}

export const getCheckoutQuestions = (customer): any => {
  return customer?.branding?.checkoutQuestions?.questions
}

export const disabledLawnSquareFootage = (customer): any => {
  return customer?.settings?.disabledLawnSquareFootage
}

export const enabledBuildingSquareFootage = (customer): any => {
  return customer?.settings?.enableBuildingSquareFeet
}

export const disabledLotSquareFootage = (customer): any => {
  if(customer && customer.countrySettings) {
    if(customer.countrySettings?.settings?.enableLotSquareFootage === false){
      return true
    }
  }
  return false
}

export const getCurrency = (customer): any => {
  if(customer && customer.countrySettings) {
    if(customer.countrySettings?.settings?.currency){
      return customer.countrySettings.settings.currency
    }
  }
  return '$'
}
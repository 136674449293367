import { waitingAreaTypes } from './waiting-area.types';

const INITIAL_STATE = {
  loading: false,
  currentMeasurement: null,
};

const waitingAreaReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case waitingAreaTypes.SET_CURRENT_MEASUREMENT:
      return {
        ...state,
        currentMeasurement: payload,
      };
    default:
      return state;
  }
};

export default waitingAreaReducer;

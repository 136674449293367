import { ThemeProvider } from '@material-ui/core';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import 'react-modal-video/scss/modal-video.scss';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Loading from '../components/loading/loading.component';
import useAuth from '../hooks/useAuth';
import SEO from '../next-seo.config';
import { useStore } from '../redux/store';
import { routes } from '../routes';
import '../styles/globals.css';
import '../styles/prism.css';
import '../styles/flip.css';
import { Theme } from '../theme/theme';
import Dashboard from './contractor/home';
import LoadingAnimation from '../components/loading/loadingAnimation.component';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const store = useStore(pageProps.initialReduxState);
  const persistor = persistStore(store, {}, () => {
    persistor.persist();
  });


  const checkRole = () => {
    if (typeof window !== 'undefined') {
      const u = localStorage.getItem("persist:primary");
      const us = JSON.parse(u);
      if(!us) return

      const { user } = JSON.parse(us.user);
      if (user === null) return;

      let isAllowed = false;
      const routeObj = routes[router.pathname];

      user?.roles.map((role) => {
        if (routeObj === undefined) {
          isAllowed = true
        }
        else if (routeObj.roles.includes(role)) {
          isAllowed = true
        }
      });

      if (!isAllowed) {
        isAllowed = false;
      };

      return isAllowed;
    }
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        {
          router.pathname.includes('contractor') || router.pathname.includes('worker') || router.pathname.includes('admin')
            ? (
              /* eslint-disable react/jsx-props-no-spreading */
              <PersistGate loading={<LoadingAnimation />} persistor={persistor}>
                {checkRole() ? <Component {...pageProps} /> : <Dashboard {...pageProps} />}
              </PersistGate>
            )
            : (
              /* eslint-disable react/jsx-props-no-spreading */
              <>
                <DefaultSeo {...SEO} />
                <Component {...pageProps} />
              </>
            )
        }
      </ThemeProvider>
    </Provider>
  );
}

export default MyApp;

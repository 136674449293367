import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ChevronUpIcon } from '@heroicons/react/solid'

interface ProfileMenuProps {
  contractorDropDownOptions: any;
  username: any;
  company: any;
  avatar?: any;
}

export default function ProfileMenu({ contractorDropDownOptions, username, company, avatar, ...props }: ProfileMenuProps) {

  return (
    <div className="w-full">
      <Menu as="div" className="relative w-full inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-between items-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-sky-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <div className='flex justify-start items-center gap-2 w-full'>
              {avatar ?
                <img
                  className="h-8 w-8 rounded-full"
                  src={avatar}
                  alt="User Avatar"
                /> :
                <div className='rounded-full bg-sky-50 border border-sky-200 p-2 min-w-fit'>
                  <img src="https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/person_vr2994.svg" alt="profile" className='w-8 h-8' />
                </div>
              }
              <div className='font-medium text-[14px] flex-grow-1'><span>{username}</span> <span>{company}</span></div>
            </div>
            <ChevronUpIcon
              className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-[-3rem] md:right-[-12.5rem] top-[-7rem] md:top-[-6rem] z-50 mt-2 w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              {contractorDropDownOptions.map((val: any, index: any) => (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`
                    ${active && val.name !== 'Logout' ? 'bg-sky-50 text-gray-900' : !active && val.name !== 'Logout' && 'text-gray-900'}
                    ${active && val.name === 'Logout' ? 'bg-sky-50 text-red-600' : !active && val.name === 'Logout' && 'text-red-600'}
                     group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={val.onClick}
                    >
                      {active ? (
                        <img src={val.icon}

                          className="mr-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <img src={val.icon}
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                      {val.name}
                    </button>
                  )}
                </Menu.Item>
              ))}

            </div>

          </Menu.Items>

        </Transition>
      </Menu>
    </div>
  )
}

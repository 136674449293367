import { widgetTypes } from './widget.types';
import sendRequest from '../../utils/useAxios';
import { calculateMaxTotalWithoutTax, calculateTotalWithoutTax, getCustomerRedirectURL, getMaxTotalPrice, getTotalPrice, getTotalPriceWithoutTax } from './widget.selectors';
import { validateOrder } from '../../utils/validateOrder';
import { addQuantityToService } from '../../utils/addQuantityToService';
import { GET_PACKAGES, GET_PACKAGE_BY_ID } from '../packages/packages.api';
import { GET_PUBLIC_SERVICES, GET_SERVICES, GET_SERVICES_BY_ID } from '../services/services.api';
import { GET_PUBLIC_CUSTOMER } from '../customer/customer.api';
import { CREATE_ORDER, UPDATE_ORDER } from './widget.api';
import { getServicePrice } from '../../utils/getServicePrice';
import { getServiceQuantity } from '../../utils/getServiceQuantity';
import { getPackagePrice } from '../../utils/getPackagePrice';
import { calculateDiscount } from '../../utils/calculateDiscount';
import { SERVICE_AREA_TYPES } from '../../constants/services.constants';
import { applyServicesDiscountsOnPackage } from '../../utils/applyServicesDiscountsOnPackage';
import {saveAs} from 'file-saver'
import { getServiceSqFeet } from '../../utils/getServiceSqFeet';
import { orderTypeConst } from '../../constants/orderType.constants';
import { hasCustomerEnabledServiceAutopilot } from '../customer/customer.selectors';

export const setCurrentStep = (currentStep) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_CURRENT_STEP,
    payload: currentStep,
  });
};
export const setWidgetStepper = (stepper) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_WIDGET_STEPPER,
    payload: stepper,
  });
};
export const setTotalSteps = (totalSteps) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_TOTAL_STEPS,
    payload: totalSteps,
  });
};
export const setSubStep = (subStep) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_SUB_STEP,
    payload: subStep,
  });
};
export const setTotalSubSteps = (totalSubSteps) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_TOTAL_SUB_STEPS,
    payload: totalSubSteps,
  });
};
export const setShowSteps = (status) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_SHOW_STEP,
    payload: status,
  });
};
export const setLoading = (type) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_WIDGET_LOADING,
    payload: type,
  });
};
export const getContractorPackages = () => async (dispatch) => {
  const { data } = await sendRequest(GET_PACKAGES.METHOD, GET_PACKAGES.PATH);

  dispatch({
    type: widgetTypes.SET_PACKAGES,
    payload: data,
  });
};

export const getContractorServices = () => async (dispatch) => {
  const { data } = await sendRequest(GET_SERVICES.METHOD, GET_SERVICES.PATH);
  dispatch(setQuantityForService(data));
  dispatch({
    type: widgetTypes.SET_SERVICES,
    payload: data,
  });
};

export const getPublicServices = (contractor) => async (dispatch) => {
  const { data } = await sendRequest(
    GET_PUBLIC_SERVICES.METHOD,
    `${GET_PUBLIC_SERVICES.PATH}/${contractor}?active=true`
  );
  dispatch({
    type: widgetTypes.SET_SERVICES,
    payload: data,
  });
  // dispatch(setQuantityForService(data))
};

export const setQuantityForService = (services) => async (dispatch) => {
  const quantity = {};
  services.map((service) => {
    quantity[service._id] = service.quantity;
  });
  dispatch({
    type: widgetTypes.SET_QUANTITY_FOR_SERVICE,
    payload: quantity,
  });
};

export const setIsPaidWithPaypal = () => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_PAYPAL_PAID,
  });
};

const service_visibilty= (service,sqFeet)=>{
  let new_service;
    if(service.ranges && service.ranges.length){
      const filtered_ranges= service.ranges.filter(range=>{        
        if(range.from<=sqFeet && (range.to>=sqFeet || !range.to) && range.hideService){
          return range
        }
      })
      if(filtered_ranges.length>0){
        new_service = {...service, hide:true}      
      }else{
        new_service = {...service, hide:false}
      }     
    }else{
      new_service = {...service, hide:false}
    }
  return new_service;
}

export const filter_services= (services,sqFeet)=>{
  let filtered_services = []    
  services?.forEach(service=>{
    const newService =  service_visibilty(service,sqFeet)
      if (!newService.hide) {
        filtered_services.push(newService) 
      }       
  })
  return filtered_services;
}

export const filter_packages= (packages,sqFeet)=>{
  let filtered_packages = []   
  packages?.forEach(pckg=>{      
    let newPackage;
    if(pckg.services && pckg.services.length){
      let filtered_services = []    
      pckg.services.forEach(service=>{
        const newService =  service_visibilty(service,sqFeet)
          if (newService.hide) {
            filtered_services.push(newService) 
          }       
      })
      if(filtered_services.length){
        newPackage= {...pckg, hide:true}      
      }else{
        newPackage= {...pckg, hide:false}
      }
    }else{
      newPackage= {...pckg, hide:false}
    }
    if (!newPackage.hide) {
      filtered_packages.push(newPackage) 
    }       
  })
  return filtered_packages;
}

export const getPackagesById = (id, active, sqFeet, selectedCategories, selectedSubCategories, currentRegion) => async (dispatch) => {
  const categories = selectedCategories.length > 0 ? `&categories=${JSON.stringify(selectedCategories)}` : ''
  const subCategories = selectedSubCategories.length > 0 ? `&subCategories=${JSON.stringify(selectedSubCategories)}` : ''
  dispatch(setLoading(true));
  const { data } = await sendRequest(
    GET_PACKAGE_BY_ID.METHOD,
    `${GET_PACKAGE_BY_ID.PATH}/${id}?active=${active}&regions=${[currentRegion?._id]}&withoutRegions=true${categories}${subCategories}`
  );
  // filter packages to hide
  const filtered_packages = filter_packages(data,sqFeet)
  dispatch(setSuccess(widgetTypes.SET_PACKAGES, filtered_packages));
  dispatch(setLoading(false));
};

export const getServicesById = (id, active, sqFeet, selectedCategories, selectedSubCategories, currentRegion) => async (dispatch) => {
  const categories = selectedCategories.length > 0 ? `&categories=${JSON.stringify(selectedCategories)}` : ''
  const subCategories = selectedSubCategories.length > 0 ? `&subCategories=${JSON.stringify(selectedSubCategories)}` : ''
  dispatch(setLoading(true));
  const { data } = await sendRequest(
    GET_SERVICES_BY_ID.METHOD,
    `${GET_SERVICES_BY_ID.PATH}/${id}?active=${active}&regions=${[currentRegion?._id]}&withoutRegions=true${categories}${subCategories}`
  );
  // filter services to hide
  let filtered_services = filter_services(data,sqFeet)
  dispatch(setSuccess(widgetTypes.SET_SERVICES, filtered_services));
  dispatch(setLoading(false));
};

export const setMeasurement = (updatedProperty) => async (dispatch) => {
  dispatch({
    type: widgetTypes.UPDATE_MEASUREMENT,
    payload: updatedProperty,
  });
};
export const updateServiceQuantity = (updatedService) => async (dispatch) => {
  dispatch({
    type: widgetTypes.UPDATE_SERVICE_QUANTITY,
    payload: updatedService,
  });
};
export const updateWidgetServiceQuantityInCart = (updatedService) => async (dispatch) => {
  dispatch({
    type: widgetTypes.UPDATE_WIDGET_SERVICE_QUANTITY_IN_CART,
    payload: updatedService,
  });
};
export const toggleServiceInCart = (service) => async (dispatch) => {
  dispatch({
    type: widgetTypes.TOGGLE_SERVICE_IN_CART,
    payload: service,
  });
};
export const togglePackageInCart = (packageItem) => async (dispatch) => {
  dispatch({
    type: widgetTypes.TOGGLE_PACKAGE_IN_CART,
    payload: packageItem,
  });
};
export const UpdateCartPackage = (packageItem) => async (dispatch) => {
  dispatch({
    type: widgetTypes.UPDATE_PACKAGE_IN_CART,
    payload: packageItem,
  });
};
export const toggleCategories = (categories) => async (dispatch) => {
  dispatch({
    type: widgetTypes.TOGGLE_CATEGORY,
    payload: categories,
  });
};
export const toggleSubCategories = (subCategories) => async (dispatch) => {
  dispatch({
    type: widgetTypes.TOGGLE_SUB_CATEGORY,
    payload: subCategories,
  });
};
export const setCart = (services, packages) => async (dispatch) => {
  dispatch({
    type: widgetTypes.SET_CART,
    payload: { services, packages },
  });
};
export const changePaymentType = (paymentType) => async (dispatch) => {
  dispatch({
    type: widgetTypes.CHANGE_PAYMENT_TYPE,
    payload: paymentType,
  });
};
export const updateOrderUser = (orderUser) => async (dispatch) => {
  dispatch({
    type: widgetTypes.UPDATE_ORDER_USER,
    payload: orderUser,
  });
};

export const setContactInfo = (contactInfo) => async (dispatch) => {
  contactInfo = { ...contactInfo };
  dispatch({
    type: widgetTypes.SET_CONTACT_INFO,
    payload: contactInfo,
  });
};

export const updateShowIFrame = (payload) => async (dispatch) => {
  dispatch({
    type: widgetTypes.SHOULD_SHOW_IFRAME,
    payload,
  });
};

export const setViewOtherServices = (payload) => async (dispatch) => {
  dispatch({
    type: widgetTypes.SET_VIEW_OTHER_SERVICES,
    payload,
  });
};

export const resetWidget = () => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_WIDGET_INITIAL_STATE,
  });
};
export const setErrors = (errors) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_ERRORS,
    payload: errors,
  });
};

const getOrderDiscountIds = (orderDiscounts) => {
  return orderDiscounts.map(orderDiscount => orderDiscount._id);
}

export const createOrder = (orderType, router, appointment?) => async (dispatch, getState) => {
  const state = getState();
  const {
    orderUser,
    cart,
    contractor,
    orderFrom,
    quantity,
    signature,
    orderbase64,
    // boundary,
    orderQuestions,
    customQuotes,
    customDiscounts,
    orderDiscounts,
    searchedServicesType,
    pciVault_card,
  } = state.widget;
  const { lead } = state.leads;
  const { searchesLog } = state.searchesLog;
  const { allEnabled, tempData } = state.integrations;
  const { data: customer } = await sendRequest(
    GET_PUBLIC_CUSTOMER.METHOD,
    `${GET_PUBLIC_CUSTOMER.PATH}/${contractor._id}`
  );

  const { tax, place, sqFeet, polygonsFeaturesSqFeet, measurement } = state.measurement;
  const totalPrice =
    customer?.settings?.enabledTax === false
      ? calculateTotalWithoutTax()(state)
      : getTotalPrice()(state);

      const maxTotalPrice =
    customer?.settings?.enabledTax === false
      ? calculateMaxTotalWithoutTax()(state)
      : getMaxTotalPrice()(state);

  let services = {};
  let onePackage = [];
  let packages = [];
  cart.packages.forEach(function (packageItem) {
    services = packageItem.services.map((serviceItem) => ({
      ...serviceItem,
      quantity: packageItem.quantity[serviceItem._id],
    }));
    onePackage = { ...packageItem, services };
    packages = [...packages, onePackage];
  });

  let leadSessions = []
    if (lead?.leadSessions && lead?.leadSessions?.length) {
      leadSessions = lead.leadSessions
      leadSessions[leadSessions.length - 1]['Order_placed'] = Date.now()
    }

  let order = {
    user: orderUser,
    amount: totalPrice,
    maxAmount: maxTotalPrice,
    services: addQuantityToService(cart.services, quantity),
    packages: packages?.map((orderPackage) => orderPackage._id),
    place,
    // boundarySqFeet: boundary?.acreage_calc * 43560,
    payment: orderType,
    contractor: contractor._id,
    sqFeet,
    polygonsFeaturesSqFeet,
    tax,
    measurement,
    customQuotes,
    customDiscounts,
    widgetContractor: {
      name: contractor.name,
      email: customer?.businessEmail || contractor.email,
      companyName: contractor.company,
      companyLogo: customer.customerLogo,
    },
    signature: signature,
    orderQuestions,
    discounts: orderDiscounts ? getOrderDiscountIds(orderDiscounts) : null,
    // searcheslogs:searchesLog._id,
    orderServiceType: searchedServicesType,
    pciVault_card,
    appointment,
    ...(leadSessions.length && {leadSessions})
  };
  const { isValid, errors } = validateOrder(order, customer, orderQuestions);
  if (!isValid) {
    dispatch(setErrors(errors));
    dispatch(setLoading(false));
    return;
  }
  if(allEnabled?.includes('clover') ){
    const address = place?.formatted_address?.split(',').map(item => item.trim());
    const state = address[2].split(' ');
    const requestBody={
      "account": tempData?.clover?.account,
      "expiry": tempData?.clover?.expiry, // 12/26
      "cvv2": tempData?.clover?.card_cvv,
      "amount": totalPrice,
      "currency": customer?.countrySettings?.country === 'Canada' ? 'CAD' : 'USD',
      "name":orderUser?.name || '',
      "address": address[0],
      "city": address[1],
      "region": state[0],
      "country": address?.at(-1) || 'USA',
      "postal": state[1],
      "email": orderUser?.email || '',
      "phone": orderUser?.phone || '',
    }

    if (orderType == orderTypeConst.PAY_NOW) {
      const { data } = await sendRequest('POST', '/orders/clover-payment', { customerId: customer?._id, requestBody })
      if (!data || data?.statusCode) {
        dispatch(setErrors([data?.message]));
        dispatch(setLoading(false));
        return;
      }
    }

    if (orderType == orderTypeConst.PAY_AS_YOU_GO && !hasCustomerEnabledServiceAutopilot(customer)) {
      const { data } = await sendRequest('POST', '/orders/clover-payment-profile', { customerId: customer?._id, requestBody })
      if (!data || data?.statusCode) {
        dispatch(setErrors([data?.message]));
        dispatch(setLoading(false));
        return;
      }
    }

  }
  // clover pay as you go => !hasCustomerEnabledServiceAutopilot(customer)
  if (orderFrom == 'BUY_FROM_QUOTE') {
    order['_id'] = router.query.id;
    order['orderPdf'] = orderbase64;
    const { data } = await sendRequest(UPDATE_ORDER.METHOD, UPDATE_ORDER.PATH, order);
    if (data.statusCode) {
      dispatch(setErrors(data.message));
      dispatch(setLoading(false));
      return;
    }
    dispatch(setLoading(false))
    router.push(getCustomerRedirectURL(customer, orderType));
    // router.push((!customer?.serviceAutoPilotEnabled && customer?.checkoutUrl) ? customer?.checkoutUrl : '/widget/thank-you');
  } else {
    const leadId = lead?._id;
    let leadSessions = []
    if (lead?.leadSessions) {
      leadSessions = lead.leadSessions
      leadSessions[leadSessions.length - 1]['Order_placed'] = Date.now()
    }
    const { data } = await sendRequest(CREATE_ORDER.METHOD, CREATE_ORDER.PATH, {
      ...order,
      leadId,
      leadSessions,
    });
    if (data.statusCode) {
      dispatch(setErrors(data.message));
      dispatch(setLoading(false))
      return;
    }

    dispatch(setLoading(false));

    router.push(getCustomerRedirectURL(customer, orderType));
  }
};

export const createOrderLocalStorage = (orderData, orderType, router) => async (dispatch) => {
  const { widget, measurement: measurementData, totalPrice, _id } = orderData;
  const {
    orderUser,
    cart,
    contractor,
    orderFrom,
    quantity,
    signature,
    // boundary,
    orderQuestions,
    customQuotes,
    customDiscounts,
    paymentType,
    orderDiscounts,
    searchedServicesType,
    pciVault_card,
  } = widget;
  const { data: customer } = await sendRequest(
    GET_PUBLIC_CUSTOMER.METHOD,
    `${GET_PUBLIC_CUSTOMER.PATH}/${contractor._id}`
  );

  const { tax, measurement, sqFeet, place } = measurementData;
  let services = {};
  let onePackage = [];
  let packages = [];
  cart.packages.forEach(function (packageItem) {
    services = packageItem.services.map((serviceItem) => ({
      ...serviceItem,
      quantity: packageItem.quantity[serviceItem._id],
    }));
    onePackage = { ...packageItem, services };
    packages = [...packages, onePackage];
  });

  let order = {
    user: orderUser,
    amount: totalPrice,
    services: addQuantityToService(cart.services, quantity),
    packages: packages?.map((orderPackage) => orderPackage._id),
    measurement,
    // boundarySqFeet: boundary?.acreage_calc * 43560,
    payment: orderType,
    contractor: contractor._id,
    sqFeet,
    place,
    tax,
    signature,
    customQuotes,
    customDiscounts,
    widgetContractor: {
      name: contractor.name,
      email: customer?.businessEmail || contractor.email,
      companyName: contractor.company,
      companyLogo: customer.customerLogo,
    },
    orderQuestions,
    discounts: orderDiscounts ? getOrderDiscountIds(orderDiscounts) : null,
    orderServiceType: searchedServicesType,
    pciVault_card,
  };
  const { isValid, errors } = validateOrder(order, customer, orderQuestions);

  // if(!isValid){
  //   dispatch(setErrors(errors))
  //   dispatch(setLoading(false));
  //   return
  // }
  if (orderFrom == 'BUY_FROM_QUOTE') {
    order['_id'] = _id;
    const { data } = await sendRequest(UPDATE_ORDER.METHOD, UPDATE_ORDER.PATH, order);
    if (data.statusCode) {
      dispatch(setErrors(data.message));
      dispatch(setLoading(false));
      return;
    }
  } else {
    const { data } = await sendRequest(CREATE_ORDER.METHOD, CREATE_ORDER.PATH, order);
    if (data.statusCode) {
      dispatch(setErrors(data.message));
      dispatch(setLoading(false));
      return;
    }
  }

  localStorage.removeItem('ORDER_DATA');

  dispatch(setLoading(false));
  router.replace(getCustomerRedirectURL(customer, paymentType));
};

// export const getBoundary = (placeId, requestedAddress) => async (dispatch) => {
//   const { data } = await sendRequest('GET', `/boundary/${placeId}/${requestedAddress}`);
//   if (data && !data.statusCode && data.boundary) {
//     dispatch({
//       type: widgetTypes.SET_WIDGET_BOUNDARY,
//       payload: data.boundary,
//     });
//   }
// };

export const getPaymentDiscounts = (customPaymentType) => async (dispatch, getState) => {
  const paymentTypeHasDiscount = getDiscounts(getState, customPaymentType);
  dispatch({
    type: widgetTypes.SET_PAYMENT_DISCOUNTS,
    payload: { type: customPaymentType, value: paymentTypeHasDiscount },
  });
};

const getDiscounts = (getState, customPaymentType?) => {
  const state = getState();
  const { cart, quantity, paymentType: cartPaymentType, customQuotes } = state.widget;
  const { sqFeet, measurement, polygonsFeaturesSqFeet } = state.measurement;
  let paymentTypeHasDiscount = false;
  cart.services?.forEach((servcie) => {
    const serviceDiscounts = getServiceDiscounts(
      servcie,
      quantity,
      customPaymentType || cartPaymentType,
      // boundary,
      sqFeet,
      measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
      polygonsFeaturesSqFeet,
    );
    if (serviceDiscounts) {
      paymentTypeHasDiscount = true;
    }
  });
  cart.packages?.forEach((packageItem) => {
    const packageDiscounts = getPackagesDiscounts(
      packageItem,
      customPaymentType || cartPaymentType,
      // boundary,
      sqFeet,
      customQuotes,
      measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
      polygonsFeaturesSqFeet,
    );
    if (packageDiscounts) {
      paymentTypeHasDiscount = true;
    }
  });
  return paymentTypeHasDiscount;
};

export const setCustomQutoes = (customQuotes) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_CUSTOM_QUOTES,
    payload: customQuotes,
  });
};

export const setCustomDiscounts = (customDiscounts, itemId, itemType, selectedDiscount) => (dispatch) => {

  let newDiscounts = {}
  const itemDiscounts= customDiscounts[itemType]
  
  if (!itemDiscounts[itemId]) {
    newDiscounts = { ...customDiscounts, [itemType]: {...itemDiscounts, [itemId]: [selectedDiscount] } }

  } else if (itemDiscounts[itemId] && itemDiscounts[itemId].find(discount => discount._id === selectedDiscount._id)) {
    const singleItemDiscounts = itemDiscounts[itemId].filter(discount => discount._id !== selectedDiscount._id)
    newDiscounts = { ...customDiscounts, [itemType]: {...itemDiscounts, [itemId]: singleItemDiscounts } }

  } else if (itemDiscounts[itemId] && !itemDiscounts[itemId].find(discount => discount._id === selectedDiscount._id)) {
    const singleItemDiscounts = itemDiscounts[itemId]
    singleItemDiscounts.push(selectedDiscount)
    newDiscounts = { ...customDiscounts, [itemType]: {...itemDiscounts, [itemId]: singleItemDiscounts } }
  }

  dispatch({
    type: widgetTypes.SET_CUSTOM_DISCOUNTS,
    payload: newDiscounts
  })
}

const getServiceDiscounts = (service, quantity, cartPaymentType, sqFeet, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  let squareFeet = getServiceSqFeet(service.areaType, sqFeet, buildingSquareFeet, polygonsFeaturesSqFeet);

  let servicePrice = getServicePrice(
    service.pricePerSquare,
    getServiceQuantity(quantity, service._id),
    service.minPrice,
    service.fixedPrice,
    squareFeet,
    service.ranges,
    service.areaType
  );
  if (service.discount?.length) {
    const serviceQuantity = getServiceQuantity(quantity, service._id);
    const { discounts: serviceDiscounts } = calculateDiscount(
      service.discount,
      servicePrice,
      cartPaymentType,
      serviceQuantity
    );
    if (serviceDiscounts.length) {
      return serviceDiscounts;
    }
  }
  return null;
};

const getPackagesDiscounts = (packageItem, cartPaymentType, sqFeet, customQuotes, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  let packagePrice = getPackagePrice(
    packageItem,
    sqFeet,
    // boundary?.acreage_calc * 43560,
    customQuotes,
    buildingSquareFeet,
    polygonsFeaturesSqFeet,
  );
  if (packageItem.discount?.length) {
    const { discounts: packageDiscounts } = calculateDiscount(
      packageItem.discount,
      packagePrice,
      cartPaymentType
    );
    if (packageDiscounts.length) {
      return packageDiscounts;
    }
  }
  if (packageItem.servicesDiscounts) {
    const { discounts: packageServicesDiscounts } = applyServicesDiscountsOnPackage(
      packageItem,
      sqFeet,
      // boundary,
      customQuotes,
      cartPaymentType,
      buildingSquareFeet,
      polygonsFeaturesSqFeet,
    );
    if (packageServicesDiscounts.length) {
      return packageServicesDiscounts;
    }
  }
  return null;
};

export const getOrderDiscounts = (discount, orderDiscounts) => {
  if(orderDiscounts && orderDiscounts.includes(discount)){
    return orderDiscounts
  } else {
    if(orderDiscounts){
      return [...orderDiscounts, discount]
    } else {
      return [discount]
    }
  }
}

export const setOrderDiscount = (discount) => async (dispatch, getState) => {
    const state = getState();
    const {orderDiscounts} = state.widget
    const discounts = getOrderDiscounts(discount, orderDiscounts)
    dispatch({
      type: widgetTypes.SET_ORDER_DISCOUNTS,
      payload: discounts,
    });
};

export const setOrderDiscounts = (discounts) => async (dispatch) => {
    dispatch({
      type: widgetTypes.SET_ORDER_DISCOUNTS,
      payload: discounts,
    });
};

export const setCurrentRegion = (region) => {
  return {
    type: widgetTypes.SET_CURRENT_REGION,
    payload: region,
  };
};

export const setSearchedServicesType = (type) => {
  return {
    type: widgetTypes.SET_SEARCHED_SERVICES_TYPE,
    payload: type,
  };
};

export const updateSearchOptionsDisplay = (value) => {
  return {
    type: widgetTypes.UPDATE_SEARCH_OPTIONS_DISPLAY_STATUS,
    payload: value,
  };
};

export const setSuccess = (action, payload) => {
  return {
    type: widgetTypes[action],
    payload,
  };
};

export const setError = (payload) => {
  return {
    type: widgetTypes.SET_WIDGET_ERROR,
    payload,
  };
};

export const saveMapImage = async (Center, polygons, zoom, type, address) => {
  const { data } = await sendRequest('POST', '/measurement/image', { Center, polygons, zoom, type })
  saveAs(data, `${address}.png`)
};

export const setEstimateDetailImages = (images) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_ESTIMATE_DETAIL_IMAGES,
    payload: images
  });
}

export const contractorTypes = {
  SET_CONTRACTOR_ORDERS: 'SET_CONTRACTOR_ORDERS',
  SET_ORDERS_FILTER: 'SET_ORDERS_FILTER',
  SET_OVERVIEW_FILTER: 'SET_OVERVIEW_FILTER',
  TOGGLE_ORDER_EXPAND: 'TOGGLE_ORDER_EXPAND',
  CHANGE_PRODUCT_SELECTION: 'CHANGE_PRODUCT_SELECTION',
  SET_LOADING_CONTRACTOR: 'SET_LOADING_CONTRACTOR',
  SET_ERROR_CONTRACTOR: 'SET_ERROR_CONTRACTOR',
  SET_OVERVIEW_DATA: 'SET_OVERVIEW_DATA',
  DELETE_ORDER_BY_ID: 'DELETE_ORDER_BY_ID',
  SET_CONTRACTOR_ORDERS_TOTAL_COUNT: 'SET_CONTRACTOR_ORDERS_TOTAL_COUNT',
};

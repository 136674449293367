import React, { useState } from 'react';
import { BsPlayFill, BsX } from 'react-icons/bs';
import ModalVideo from 'react-modal-video';

const VideoModal = () => {
  const [isOpen, setOpen] = useState(false);
  const [showVideoPopupButton, setshowVideoPopupButton] = useState(true);

  return ''

  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="L61p2uyiMSo"
        onClose={() => setOpen(false)}
      />

      {showVideoPopupButton && (
        <div className="z-10 bg-gradient-to-b cursor-pointer from-blue-600 via-blue-500 to-blue-400 rounded bottom-10 right-10 fixed px-8 py-4 shadow-xl">
          <BsX
            size="23"
            color="white"
            className="-mt-2 -mr-4 float-right rounded-full bg-blue-300"
            onClick={() => setshowVideoPopupButton(false)}
          />

          <BsPlayFill
            onClick={() => setOpen(true)}
            size="35"
            className="rounded-full bg-white mr-4 md:mr-0 text-blue-400"
          />

          <h1
            className="text-white text-2xl uppercase w-60 font-bold mt-5 hidden md:block"
            onClick={() => setOpen(true)}
          >
            5 Tips to increase your Orders
          </h1>
        </div>
      )}
    </>
  );
};

export default VideoModal;

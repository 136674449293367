import { pricingPlanTypes } from './pricing-plans.types';

const INITIAL_STATE = {
  pricingPlans: null,
  pricingPlan: null,
  HdMeasurementsPricingPlans: [],
  error: null,
  loading: null
};

const pricingPlansReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case pricingPlanTypes.SET_PRICING_PLANS:
      return {
        ...state,
        pricingPlans: payload,
        loading: false,
      };
    case pricingPlanTypes.SET_PRICING_PLAN:
      return {
        ...state,
        pricingPlan: payload,
        loading: false,
      };
    case pricingPlanTypes.SET_ERROR_PRICING_PLAN:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case pricingPlanTypes.SET_HD_MEASUREMENT_PRICING_PLANS:
      return {
        ...state,
        HdMeasurementsPricingPlans: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default pricingPlansReducer;

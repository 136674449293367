import { rolesConstants } from '../../constants/roles.constants';
import sendRequest from '../../utils/useAxios';
import { setBetaCode } from '../beta-code/beta.actions';
import { GET_CUSTOMER, GET_IS_CUSTOMER, GET_PUBLIC_CUSTOMER } from '../customer/customer.api';
import { CREATE_SETTINGS_SIGNUP, GET_USER_PROFILE, UPDATE_USER_PROFILE, UPLOAD_PROFILE_IMAGE, USER_CHANGE_PASSWORD, USER_LOGIN, USER_PASSWORD_RESET_EMAIL, USER_REGISTER, VERIFY_USER_PROFILE } from './user.api';
import { userTypes } from './user.types';

export const signInUser = (user, router) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(USER_LOGIN.METHOD, USER_LOGIN.PATH, user);
  if (data.token) {
    dispatch(setToken(data.token));
    dispatch(getUser(router));
    dispatch(setErrorMessages([]));
  } else if(!data.token) {
    if(data.statusCode && data.statusCode == 401){
      dispatch(setErrorMessages([data.message]));
    } else if (data.statusCode && data.statusCode == 400){
      dispatch(setErrorMessages([...data.message]));
    } else if (!data.statusCode && data.status == 401){
      dispatch(setErrorMessages([data.error]));
    }
    dispatch(setLoading(false));
  }
};
export const signUpUser = (user, router) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrorMessages([]));
  const { data } = await sendRequest(USER_REGISTER.METHOD, USER_REGISTER.PATH, user);
  if (!data.statusCode && data?.data[0].statusCode == 202 ) {
    localStorage.removeItem("PRICING_PLAN")
    await sendRequest(CREATE_SETTINGS_SIGNUP.METHOD, CREATE_SETTINGS_SIGNUP.PATH, {userId: data.customer.user})
    router.push('/email/check-email-verification');
  } else if (data.status && data.status == 400) {
    dispatch(setBetaCode({code: "", visible: true}))
    dispatch(setErrorMessages([data.message]));
  } else if (data.status && data.status == 406) {
    dispatch(setErrorMessages([data.message]));
  }
  dispatch(setLoading(false));
};

export const sendEmailToSeniorWorkers = (payload: any) => async (_dispatch) => {
  const { data: workers } = await sendRequest('GET', '/profile/senior-workers')
  if (workers && workers.length) {
    sendRequest('POST', '/profile/help-email', { workers })
  }
}

export const setErrorMessages = (errorMessages)=>(dispatch)=>{
  dispatch({
    type: userTypes.SET_USER_ERROR,
    payload: errorMessages,
  })
} 

export const changePassword = (password, confirmPassword, token, router) => async (dispatch) => {
  dispatch(setLoading(true));
  if(password === confirmPassword) {
    const {data} = await sendRequest(USER_CHANGE_PASSWORD.METHOD, `${USER_CHANGE_PASSWORD.PATH}/${token}`, {password});
    if(data && !data.statusCode) {
      dispatch(setErrorMessages([]));
      router.push('/password-reset/success');
    } else {
      dispatch(setErrorMessages(data.message));
    }
  } else {
    dispatch(setErrorMessages(['Passwords must match']));
  }
  dispatch(setLoading(false));
}

export const setUserEmail = (email) => (dispatch) => {
  dispatch({
    type: userTypes.SET_EMAIL,
    payload: email,
  })
}

export const sendPasswordResetEmail = (email, router) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(USER_PASSWORD_RESET_EMAIL.METHOD, `${USER_PASSWORD_RESET_EMAIL.PATH}/${email}`);
  if (data[0] && (data[0].statusCode === 200 || data[0].statusCode === 202)) {
    router.push('/password-reset/send-email');
  } else {
    dispatch(setErrorMessages([data.message]));
  }
  dispatch(setLoading(false));
}

export const setToken = (token) => (dispatch) => {
  dispatch({
    type: userTypes.SET_USER_TOKEN,
    payload: token,
  });
};

export const checkIsCustomer = () => async (dispatch) => {
  const { data } = await sendRequest(GET_IS_CUSTOMER.METHOD, `${GET_IS_CUSTOMER.PATH}`);
  if (data) {
    dispatch(setSuccess(userTypes.IS_CUSTOMER, data));
  }
};

export const getUser = (router) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_USER_PROFILE.METHOD, GET_USER_PROFILE.PATH);
  // const { data: customerData } = await sendRequest(GET_PUBLIC_CUSTOMER.METHOD, `${GET_PUBLIC_CUSTOMER.PATH}/${data._id}`);
  const { data: customerData } = await sendRequest(GET_CUSTOMER.METHOD, GET_CUSTOMER.PATH);

  if (data && !data.statusCode) {
    dispatch(checkIsCustomer());
    dispatch(setSuccess(userTypes.SET_USER, data));
    if (data.roles.includes(rolesConstants.ADMIN)) {
      router.push('/admin/searches-feed');
    } else if(data.roles.includes(rolesConstants.SENIORWORKER)) {
      router.push('/worker/hallway');
    }
    else if(data.roles.includes(rolesConstants.WORKER)) {
      router.push('/worker/dashboard');
    } else if (data.roles.includes(rolesConstants.CONTRACTOR)) {
      router.push('/contractor/home');
    } else if(data.roles.includes(rolesConstants.UNPAIDCUSTOMER) && !customerData.paddle) {
      router.push('/contractor/payment-details');
    } 
    else if(data.roles.includes(rolesConstants.UNPAIDCUSTOMER) && customerData.paddleSubscriptionStatus !== 'active') {
      router.push('/contractor/payment-details');
    } 
    else {
      router.push('/contractor/home');
    }
  }
  dispatch(setLoading(false));
};

export const getOnlyUser = () => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_USER_PROFILE.METHOD, GET_USER_PROFILE.PATH);
  if (data) {
    dispatch(checkIsCustomer());
    dispatch(setSuccess(userTypes.SET_USER, data));
  }
  dispatch(setLoading(false));
};

export const updateProfile = (id:string, name: string, file?: any) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrorMessages([]));

  let formBody:any = {name}
  if (file) {
    const {data} = await sendRequest(UPLOAD_PROFILE_IMAGE.METHOD, UPLOAD_PROFILE_IMAGE.PATH, file);
    if (data && !data.statusCode) {
      formBody = {...formBody, avatar:data}
    }
  }

  const { data } = await sendRequest(`${UPDATE_USER_PROFILE.METHOD}`, `${UPDATE_USER_PROFILE.PATH}/${id}`, formBody);
  if (data && !data.statusCode) {
    dispatch(setSuccess(userTypes.SET_USER, data));
  }else {
    dispatch(setError([data?.message]));
    setTimeout(() => {
      dispatch(setError([]));
    }, 2000);      
  }
  dispatch(setLoading(false));
};

export const logoutUser = (router) => async (dispatch) => {
  dispatch({
    type: userTypes.SET_INITIAL_STATE,
  });
  localStorage.removeItem("batch-worker")
  localStorage.removeItem("batch-admin")
  localStorage.removeItem("persist:primary")
  router.push('/');
};

export const setLoading = (loading) => async (dispatch) => {
  dispatch({
    type: userTypes.SET_USER_LOADING,
    payload: loading,
  });
};
export const verifyEmailToken = (email, token) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(
    VERIFY_USER_PROFILE.METHOD,
    `${VERIFY_USER_PROFILE.PATH}/${email}/${token}`
  );
  dispatch(setSuccess(userTypes.SET_VERIFICATION_STATUS, data.verified));
  dispatch(setLoading(false));
};

export const setSuccess = (action, payload) => {
  return {
    type: userTypes[action],
    payload,
  }
}

export const setError = (payload) => {
  return {
    type: userTypes.SET_USER_ERROR,
    payload,
  }
}
import sendRequest from "../../utils/useAxios"
import { GET_TEAM_MEMBER, GET_WHOLE_TEAM, UPDATE_TEAM_MEMBER } from "./teamMember.api"
import { teamMemberTypes } from "./teamMember.types"

export const getTeamMember = (memberId) => async (dispatch) => {
    try {
        const { data } = await sendRequest(GET_TEAM_MEMBER.METHOD, `${GET_TEAM_MEMBER.PATH}/${memberId}`)
        dispatch({
            type: teamMemberTypes.SET_TEAM_MEMBER,
            payload: data
        })
    } catch(err) {
        console.error(err)
    }
}

export const getWholeTeam = (contractorId) => async (dispatch) => {
    try {   
        const { data } = await sendRequest(GET_WHOLE_TEAM.METHOD, `${GET_WHOLE_TEAM.PATH}/${contractorId}`)
        dispatch({
            type: teamMemberTypes.SET_WHOLE_TEAM,
            payload: data
        })
    } catch(err) {
        console.error(err)
    }
}

export const updateTeamMember = (memberId, body) => async (dispatch) => {
    try {
        const req = {
            filter: { 'profile.profileId': memberId },
            body: { ...body }
        }
        await sendRequest(UPDATE_TEAM_MEMBER.METHOD, UPDATE_TEAM_MEMBER.PATH, req)
    } catch(err) {
        console.error(err)
    }
}
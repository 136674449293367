export const DEFAULT_QUESTION_OPTIONS = ['Yes', 'No']
export const SERVICE_CART_PNG = '/Service Cart Transparent Logo.png'
export const APP_URL=process.env.NEXT_PUBLIC_CLIENT_URL

export const PADDLE_STATUSES = {
  SUBSCRIPTION_CREATED: 'subscription_created',
  SUBSCRIPTION_UPDATED: 'subscription_updated',
  SUBSCRIPTION_CANCELLED: 'subscription_cancelled',
  SUBSCRIPTION_PAYMENT_RECIEVED: 'subscription_payment_succeeded',
  SUBSCRIPTION_PAYMENT_FAILED: 'subscription_payment_failed',
  SUBSCRIPTION_PAYMENT_REFUNDED: 'subscription_payment_refunded'
}

export const PADDLE_MODIFIERS = {
  GEO_PRICING: 'Geo Pricing',
  TEAM_MEMBER: 'Team Member',
  PCI_VAULT: 'PCI Vault',
}

export const PADDLE_ADDONS = {
  'Geo Pricing': {
    MODIFIER_RECURRING: true,
    MODIFIER_AMOUNT: '75.00',
  },
  'Team Member': {
    MODIFIER_RECURRING: true,
    MODIFIER_AMOUNT: '15.00',
  },
  'PCI Vault': {
    MODIFIER_RECURRING: true,
    MODIFIER_AMOUNT: '75.00',
  }
}
import moment from "moment";
import { getDatesByFilter } from "../../utils/getDatesByFilter";
import sendRequest from "../../utils/useAxios";
import { GET_CONTRACTOR_MEASUREMENTS, GET_CONTRACTOR_SEARCHES_OVERVIEW, SAVE_SEARCH_LOG, GET_UNIQUE_MEASUREMENTS, SEARCH_MEASUREMENTS } from "./searches-log.api";
import { SearchesLogTypes } from "./searches-log.types";


export const getContractorMeasurements = (QUERY: any) => async (dispatch) => {
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_CONTRACTOR_MEASUREMENTS.METHOD, `${GET_CONTRACTOR_MEASUREMENTS.PATH}?${QUERY}`);
    if (data && !data.statusCode) {
        dispatch(setSuccess(SearchesLogTypes.SET_MEASUREMENTS, data))
    }
    else {
        dispatch(setError(data?.message));
    }
  dispatch(setLoading(false))
};

export const getUniqueMeasurements = (query: any) => async (dispatch) => {
  dispatch(setLoading(true))
  const {data} = await sendRequest(GET_UNIQUE_MEASUREMENTS.METHOD, `${GET_UNIQUE_MEASUREMENTS.PATH}?page=${query}`)
  if (data && !data.statusCode) {
    dispatch(setSuccess(SearchesLogTypes.SET_MEASUREMENTS, data))
}
else {
    dispatch(setError(data?.message));
}
dispatch(setLoading(false))
}

export const searchMeasurements = (pageNo: any, searchQuery: any) => async (dispatch) => {
  const query = {
    searchQuery
  }
  dispatch(setLoading(true))

  const {data} = await sendRequest(SEARCH_MEASUREMENTS.METHOD, `${SEARCH_MEASUREMENTS.PATH}?page=${pageNo}`, query)
  if (data && !data.statusCode) {
    dispatch(setSuccess(SearchesLogTypes.SET_MEASUREMENTS, data))
}
else {
    dispatch(setError(data?.message));
}
dispatch(setLoading(false))
}


export const getContractorSearchesOverview = (filter, contractorId) => async (dispatch) => {
  dispatch(setLoading(true));
  const {startDate, endDate} = getDatesByFilter(filter);
  const utcOffset = moment().format("Z")
  const query = `filter=${filter}&startDate=${startDate}&endDate=${endDate}&utcOffset=${encodeURIComponent(utcOffset)}`
  const { data } = await sendRequest(GET_CONTRACTOR_SEARCHES_OVERVIEW.METHOD,`${GET_CONTRACTOR_SEARCHES_OVERVIEW.PATH}/${contractorId}?${query}`);
  if (data && !data.statusCode) {
    dispatch(setSuccess(SearchesLogTypes.SET_SEARCHES_OVERVIEW, data))
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false));
};


export const saveSearchLog = (searchLog: object) => async (dispatch) => {
  const { data } = await sendRequest(SAVE_SEARCH_LOG.METHOD, SAVE_SEARCH_LOG.PATH, searchLog)
  if (data && !data.statusCode) {
    dispatch(setSuccess(SearchesLogTypes.ADD_SEARCHES_LOGS, data))
  }
}

export const setSearchesLog = (searchLog) => async (dispatch) => {
  dispatch(setSuccess(SearchesLogTypes.ADD_SEARCHES_LOGS, searchLog))
}

export const setSuccess = (action, payload) => {
  return {
    type: SearchesLogTypes[action],
    payload,
  }
}

export const setError = (payload) => {
  return {
    type: SearchesLogTypes.SET_ERROR_SEARCHES_LOGS,
    payload,
  }
}

export const setLoading = (type) => async (dispatch) => {
  dispatch({
    type: SearchesLogTypes.SET_LOADING_SEARCHES,
    payload: type,
  });
};
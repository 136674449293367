export const updateQuantity = (items, id, type) => {
  const updatedItems = items.map((item) => {
    if (item._id == id) {
      if (item.quantity == 1 && type == 'remove') return item;
      item.quantity += type == 'add' ? 1 : -1;
      return item;
    } else {
      return item;
    }
  });
 
  return updatedItems;
};
export const updateQuantityObject = (quantity, id, type) => {
  
  let currentValue = quantity[id]
  if(!currentValue){
    currentValue = 1
  }
  
    return {...quantity, [id]: type == 'add' ? currentValue + 1 : currentValue - 1}
 
};

import { combineReducers } from 'redux';
import userReducer from './user/user.reducer';
import contractorReducer from './contractor/contractor.reducer';
import widgetReducer from './widget/widget.reducer';
import measurementReducer from './measurement/measurement.reducer';
import customerReducer from './customer/customer.reducer';
import servicesReducer from './services/services.reducer';
import packagesReducer from './packages/packages.reducer';
import discountsReducer from './discounts/discounts.reducer';
import waitingAreaReducer from './waiting-area/waiting-area.reducer';
import datasetReducer from './dataset/dataset.reducer';
import betaCodeReducer from './beta-code/beta.reducer';
import workerReducer from './worker/worker.reducer';
import pricingPlansReducer from './pricing-plans/pricing-plans.reducer';
import {userTypes} from './user/user.types'
import AreaAddressReducer from './area-addresses/area-address.reducer';
import SearchesLogReducer from './searches-log/searches-log.reducer';
import adminReducer from './admin/admin.reducer';
// import BoundaryReducer from './boundary/boundary.reducer';
import ServiceAreaReducer from './service-area/service-area.reducer';
import { leadsReducer } from './leads/leads.reducer';
import { customFieldsReducer } from './custom-fields/custom-fields.reducer';
import { integrationsReducer } from './integrations/integrations.reducer';
import { appointmentReducer } from './appointment/appointment.reducer';
import { teamMemberReducer } from './teamMember/teamMember.reducer';

const appReducer = combineReducers({
  user: userReducer,
  betaCode: betaCodeReducer,
  customer: customerReducer,
  contractor: contractorReducer,
  services: servicesReducer,
  packages: packagesReducer,
  discounts: discountsReducer,
  measurement: measurementReducer,
  widget: widgetReducer,
  waitingArea: waitingAreaReducer,
  dataset: datasetReducer,
  worker: workerReducer,
  pricingPlans: pricingPlansReducer,
  areaAddress: AreaAddressReducer,
  searchesLog: SearchesLogReducer,
  admin:adminReducer,
  // boundary: BoundaryReducer,
  serviceArea: ServiceAreaReducer,
  leads: leadsReducer,
  customFields:customFieldsReducer,
  integrations: integrationsReducer,
  appointment: appointmentReducer,
  teamMember: teamMemberReducer
})

const rootReducer =  (state, action) => {
  if (action.type === userTypes.SET_INITIAL_STATE) {
      // reset state
      state = undefined;
      // reset local storage
      localStorage.clear();
  }
  return appReducer(state, action)
}

export default rootReducer

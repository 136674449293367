export const SERVICE_AUTOPILOT_STATES_MAPPING = {
  TX: '269553b2-7286-431c-8e74-879acea550ea',
  IA: '2e0dfda2-af85-4cf2-9a16-8e34ab979540',
  WI: '323d2bc8-d6fe-4ca1-9e22-b019b817a15f',
  IN: '3195251f-b753-4055-8593-91b77aa002f2',
  AL: 'a6a9fa87-b37f-4426-bb5e-a1ab56e92970',
  AK: '46bf912b-5654-4578-92f9-418220742a39',
  AZ: '85b29b40-b08d-48c3-b45b-91b0d300e213',
  AR: '1e826a12-20d7-4dbf-9259-7cc99520b336',
  CA: '9b6c4bb7-d7ae-49eb-835f-01da47e3af45',
  CO: '1d761220-17b1-4d84-a938-07c5c8a3ec21',
  CT: '62e5444d-669e-4238-89fb-c5c6ee2f526b',
  DE: '52520256-956d-4825-ad7f-faab5ad858f7',
  DC: '474c4019-44ab-4265-a036-0a39d74d37d4',
  FL: '4b8fce4e-0eac-45ad-92f6-ee70bd2a2beb',
  GA: '50b7e272-c0e8-470b-b42b-1ec32f69dda5',
  HI: 'ebdd0f48-1223-416f-b95b-ab6b4ee92cbe',
  ID: '3f2e1be6-2d9a-4fe8-999e-d6195f644aef',
  IL: '76f0930a-a5d9-43e4-abbd-c0b3c4b2a328',
  KS: '6a4f3e46-1e07-43e6-9862-385969f7a77a',
  KY: '8c9eab13-c1ad-4f6c-bd50-2d1a3e24209b',
  NH: 'd640bfbf-29f3-4ca7-8fc0-613ae5e4c96f',
  NJ: '42034d0c-26ad-4e83-9b8a-d84baaec68a3',
  NM: '75900681-0e9b-4efa-9684-49e228253ca4',
  NY: '33dc3b2f-4226-4144-a61d-26e9f7e25a5d',
  OH: '9331c6f0-64e3-490e-a339-31ddf604b228',
  PA: '7f57a7e3-3622-4fe7-b5b8-c3ed3b77db16',
  SC: 'c50aaeef-a41c-4116-ba79-10ee6f071d19',
  VA: 'd156a4e6-ad9b-4d8e-85a3-d4844662eaa1',
  WA: 'e4efc9a6-da60-4da6-bce8-7ae6004adf07',
  WV: '8aec96b2-9ac4-4d91-8b1e-af903921c47e',
  WY: 'f5692115-6036-4f92-adc2-8533257dd4ef',
  MD: '8f6d5410-9bc3-4d65-9d8f-48af9766120c'
};

export type serviceAutopilotStatesType = ['TX', 'IA', 'WI', 'IN', 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL',
'KS', 'KY', 'NH', 'OH', 'PA', 'SC', 'VA', 'WA', 'WV', 'WY', 'MD' ]

export default {
    // titleTemplate: '%s - Deep Lawn | E-Commerce tools for home cleaning companies',The ultimate sales tool for home cleaning companies
    titleTemplate: '%s - The ultimate sales tool for home cleaning companies',
    description: "E-Commerce tools for home cleaning companies. At Service Cart you can sell your services online in less than a minute",
    openGraph: {
        // title: '%s - Deep Lawn | E-Commerce tools for home cleaning companies',
        title: '%s - The ultimate sales tool for home cleaning companies',
        description: "E-Commerce tools for home cleaning companies. At Service Cart you can sell your services online in less than a minute",
        type: 'website',
        locale: 'en_US',
        site_name: 'Service Cart',
        images: [
        {
            // url: 'https://deeplawn.com/deeplawnlogo.png',
            url: '/fav_3.png',
            width: 800,
            height: 600,
            alt: 'The ultimate sales tool for home cleaning companies',
            type: 'image/png',
        }
    
        ]

    },
  };
export const toggleInArrayOfObjects = (items, id, key) => {
  const updatedItems = items.map((item) => {
    if (item._id == id) {
      const updatedItem = item;
      updatedItem[key] = !updatedItem[key];
      return updatedItem;
    } else {
      return item;
    }
  });
  return updatedItems;
};

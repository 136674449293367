// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment =  process.env.NEXT_PUBLIC_ENVIRONMENT

Sentry.init({
  // dsn: environment === 'production' ? (SENTRY_DSN || 'https://d0633cb5599a46d685ad3b342ef26ddd@o1027773.ingest.sentry.io/5994648') : null,
  dsn: null,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  maxValueLength: 20000,
  silent: true
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

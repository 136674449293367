import { toggleItemInArray } from '../../utils/toggleItemInArray';
import { updateQuantity, updateQuantityObject } from '../../utils/updateQuantity';
import { servicesTypes } from './services.types';

const INITIAL_STATE = {
  loading: false,
  services: null,
  quantity: {},
  service: null,
  servicesCart: [],
  errorMessages: [],
  region:null
};

const servicesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case servicesTypes.SET_SERVICES:
      return {
        ...state,
        services: payload.map((service) => {
          return { ...service, quantity: 1 };
        }),
      };
    case servicesTypes.SET_SERVICES_WITH_QUANTITIES:
      return {
        ...state,
        services: payload
      };
    case servicesTypes.SET_SERVICE:
      return {
        ...state,
        service: payload,
      };
    case servicesTypes.SET_SERVICE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case servicesTypes.SET_SERVICE_ERROR:
      return {
          ...state,
          errorMessages: payload,
      };  
    case servicesTypes.RESET_SERVICE_CART:
      return {
        ...INITIAL_STATE,
      };
    case servicesTypes.TOGGLE_SERVICE_IN_CART:
      return {
        ...state,
        servicesCart: toggleItemInArray(state.servicesCart, payload),
      };
    case servicesTypes.SET_SERVICES_IN_CART:
      return {
        ...state,
        servicesCart: payload
      };
    case servicesTypes.UPDATE_SERVICE_QUANTITY:
      return {
        ...state,
        services: updateQuantity(state.services, payload._id, payload.type),
      };
    case servicesTypes.UPDATE_SERVICE_QUANTITY_IN_CART:
      return {
        ...state,
        quantity: updateQuantityObject(state.quantity, payload._id, payload.type)
      };
    case servicesTypes.SET_QUANTITY_FOR_SERVICE:
      return {
        ...state,
        quantity: payload
      };
    case servicesTypes.SET_SERVICE_REGION:
      return {
        ...state,
        region: payload
      }
    default:
      return state;
  }
};

export default servicesReducer;

import { adminTypes } from "./admin.types";


const INITIAL_STATE = {
  contractorsList: null,
  error: null
};

const adminReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case adminTypes.SET_CONTRACTORS_LIST:
      return {
        ...state,
        contractorsList: payload,
      };
      case adminTypes.SET_ERROR_CONTRACTORS:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default adminReducer;

export const servicesTypes = {
  SET_SERVICES: 'SET_SERVICES',
  SET_SERVICES_WITH_QUANTITIES: 'SET_SERVICES_WITH_QUANTITIES',
  SET_QUANTITY_FOR_SERVICE: 'SET_QUANTITY_FOR_SERVICE',
  SET_SERVICE: 'SET_SERVICE',
  SET_SERVICES_IN_CART: 'SET_SERVICES_IN_CART',
  CREATE_SERVICE: 'CREATE_SERVICE',
  TOGGLE_SERVICE_IN_CART: 'TOGGLE_SERVICE_IN_CART',
  UPDATE_SERVICE_QUANTITY: 'UPDATE_SERVICE_QUANTITY',
  UPDATE_SERVICE_QUANTITY_IN_CART : 'UPDATE_SERVICE_QUANTITY_IN_CART',
  RESET_SERVICE_CART: 'RESET_SERVICE_CART',
  SET_SERVICE_LOADING: 'SET_SERVICE_LOADING',
  SET_SERVICE_ERROR: 'SET_SERVICE_ERROR', 
  SET_SERVICE_REGION: 'SET_SERVICE_REGION',
};

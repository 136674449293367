import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateTeamMember } from '../redux/teamMember/teamMember.action';
import { updateProfile } from '../redux/user/user.actions';
import sendRequest from '../utils/useAxios';
import FormFileUploader from './common/form-file-uploader'
import ErrorPopup from './tailwind/popups/error.popup';

export default function TeamMemberDetails({ setTeamMemberStepper }) {
    const [imageUrl, setImageUrl] = useState('')
    const [errorMessages, setErrorMessages] = useState<any>([]);
    const [name, setName] = useState('')
    const [fieldRoutesEmployeeId, setFieldRoutesEmployeeId] = useState('')
    const [tempFile, setTempFile] = useState(null)
    const [imageLoading, setImageLoading] = useState(false)
    const [isFieldRouteEnabled, setIsFieldRouteEnabled] = useState(false)
    
    const {user} = useSelector((state: any) => state.user)
    const { customer } = useSelector((state:any) => state.customer)

    const dispatch = useDispatch()


    useEffect(() => {
      if(user && (user.roles[0] === 'SALES' || user.roles[0] === 'MANAGER' )){
        checkFieldRoutesIntegration()
      }
    }, [user])
    
    const imageUpload = async (file: any) => {
        if (file) {
            setImageUrl(URL.createObjectURL(file));
            setTempFile(file)
        } else {
            setErrorMessages(['No File Selected.']);
        }
    };

    const handleSubmit = () => {
        if (!imageUrl) {
            setErrorMessages(['Profile Picture not selected'])
            return
        }
        if (!name) {
            setErrorMessages(['No name entered'])
            return
        }
        if (isFieldRouteEnabled && !fieldRoutesEmployeeId) {
            setErrorMessages(['No Routes EmployeeId Entered'])
            return
        }
        if (tempFile) {
            setImageLoading(true)
            const data = new FormData()
            data.append('file', tempFile)
            setImageLoading(false)
            dispatch(updateProfile(user._id, name,fieldRoutesEmployeeId, data))
            // dispatch(updateTeamMember(user._id, { teamMemberSteps: { namePic: true }}))
            setTeamMemberStepper(false)
            // window.location.reload()
        }
    }

    const checkFieldRoutesIntegration = async () => {
        const { data } = await sendRequest('GET', `/integrations/checkIntegration/${customer?._id}/fieldRoutes`)
        if (data && !data.statusCode) {
            setIsFieldRouteEnabled(data)
            return
        }
    }

    return (
        <Fragment>
            {errorMessages.length ? <ErrorPopup errors={errorMessages} /> : null}
            <section className='w-3/4 mx-auto'>
                <span className='flex justify-center'>
                    <h3 className='font-bold text-5xl'>Welcome to the Team!</h3>
                </span>
                <span className='flex items-center w-full mt-10 flex-col'>
                    <p className='text-xl text-red-600'>Upload your Profile Picture</p>
                    <FormFileUploader imageUpload={imageUpload} imageUrl={imageUrl} imageLoading={imageLoading} recommendedSize='medium' />
                </span>
                <span className='flex items-center w-full flex-col mt-10'>
                    <label htmlFor="name" className='text-sm font-medium mb-1'>Enter your Name</label>
                    <input type="text" id='name' className='rounded-md border-1 border-gray-300 hover:border-blue-400 w-2/5'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </span>
                {isFieldRouteEnabled &&
                    <span className='flex items-center w-full flex-col mt-10'>
                        <label htmlFor="name" className='text-sm font-medium mb-1'>Enter your Field Routes Employee Id</label>
                        <input type="text" id='name' className='rounded-md border-1 border-gray-300 hover:border-blue-400 w-2/5'
                            value={fieldRoutesEmployeeId}
                            onChange={(e) => setFieldRoutesEmployeeId(e.target.value)}
                        />
                    </span>
                }
                <span className='flex justify-center w-full'>
                    <button className='bg-blue-500 w-2/5 py-2.5 mt-6 text-white text-sm font-bold rounded-md hover:bg-blue-600'
                        onClick={handleSubmit}>
                        Submit
                    </button>
                </span>
            </section>
        </Fragment>
    )
}

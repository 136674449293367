import { SERVICE_AREA_TYPES } from "../constants/services.constants";
import { ApplyDiscountOnService, ApplyServiceCustomDiscounts } from "./applyCustomDiscounts";
import { getServiceQuantity } from "./getServiceQuantity";
import { roundToTwoDigits } from "./roundToTwoDigits";

export function applyPriceRange(customDiscounts, service, servicePrice, cartPaymentType, quantity, sqFeet, customQuotes?, polygonsFeaturesSqFeet?) {
        let startingPrice = ApplyServiceCustomDiscounts(customDiscounts, service, servicePrice, cartPaymentType, quantity)
        let endingPrice
        if (polygonsFeaturesSqFeet) {
            if(polygonsFeaturesSqFeet.baths) {
                if (service.areaType === SERVICE_AREA_TYPES.BATHROOM) {
                    endingPrice = getEndingPrice(service, polygonsFeaturesSqFeet.baths)
                }
            } else if(polygonsFeaturesSqFeet.bedrooms) {
                if (service.areaType === SERVICE_AREA_TYPES.BEDROOM) {
                    endingPrice = getEndingPrice(service, polygonsFeaturesSqFeet.bedrooms)
                } 
            }
        }  else {
            endingPrice = getEndingPrice(service,sqFeet)
        }
        
        if (endingPrice) {
            const customRange = (customQuotes && (Object?.keys(customQuotes?.services).length) && (customQuotes?.services[service._id]?.start !== null) && (customQuotes?.services[service._id]))
            const serviceQuantity = getServiceQuantity(quantity, service._id)
            if (customRange) {
                const customObj = customQuotes?.services[service._id]
                startingPrice = customObj?.start * serviceQuantity
                endingPrice = customObj?.end
                // return `${customObj.start} — $${customObj.end}`
            }
            const endPrice = endingPrice * serviceQuantity

            return `${roundToTwoDigits(startingPrice)} — $${roundToTwoDigits(parseFloat(endPrice.toString()))}`
        } else {
            return startingPrice
        }
}

export function getEndingPrice(service,sqFeet){
    let endingPrice = null
    for (let range of service.ranges) {
        if (range.fixedPrice) {
            if ((sqFeet > range.from) && ((sqFeet < range.to) || (range.to === null))) {
                endingPrice = range.fixedPriceEnd
            }
        }
    }
    return endingPrice
}

export function getStartingPrice(service,sqFeet){
    let startingPrice = null
    for (let range of service.ranges) {
        if (range.fixedPrice) {
            if ((sqFeet > range.from) && ((sqFeet < range.to) || (range.to === null))) {
                startingPrice = range.fixedPrice
            }
        }
    }
    return startingPrice
}

export function getEndingPriceToDisplay(service,sqFeet,quantity){
    let endingPrice = null
    for (let range of service.ranges) {
        if (range.fixedPrice) {
            if ((sqFeet > range.from) && ((sqFeet < range.to) || (range.to === null))) {
                let qty = quantity[service._id]
                if (qty) {
                    endingPrice = range.fixedPriceEnd * qty
                } else {
                    endingPrice = range.fixedPriceEnd
                }
            }
        }
    }
    return endingPrice
}
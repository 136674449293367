export const userTypes = {
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',
  SET_USER_TOKEN: 'SET_USER_TOKEN',
  SET_BETA_CODE: 'SET_BETA_CODE',
  SET_USER: 'SET_USER',
  IS_CUSTOMER: 'IS_CUSTOMER',
  SET_VERIFICATION_STATUS: 'SET_VERIFICATION_STATUS',
  SET_USER_LOADING: 'SET_USER_LOADING',
  SET_USER_ERROR: 'SET_USER_ERROR', 
  SET_EMAIL: 'SET_EMAIL',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
};

import { workerTypes } from './worker.types';

const INITIAL_STATE = {
  loading: false,
  waiting: null,
  queue: null,
  denied: null,
  skipped: null,
};

const workerReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case workerTypes.SET_BATCH_ADDRESSES:
      return {
        ...state,
        [payload.type]: payload.addresses,
      };
    default:
      return state;
  }
};

export default workerReducer;

import { discountsTypes } from './discounts.types';

const INITIAL_STATE = {
  loading: false,
  discountItem: null,
  discounts: null,
  isCheckoutDiscount: false,
  errorMessages: [],
  region: null,
};

const discountsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case discountsTypes.SET_DISCOUNTS:
      return {
        ...state,
        discounts: payload,
        loading: false,
      };
    case discountsTypes.SET_DISCOUNT:
      return {
        ...state,
        discountItem: payload,
        loading: false,
      };
    case discountsTypes.SET_ERROR_DISCOUNTS:
      return {
        ...state,
        errorMessages: payload,
        loading: false,
      };
    case discountsTypes.IS_CHECKOUT_DISCOUNT:
      return {
        ...state,
        isCheckoutDiscount: payload,
        loading: false,
      };
    case discountsTypes.SET_DISCOUNTS_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case discountsTypes.SET_DISCOUNT_REGION:
      return {
        ...state,
        region: payload
      }
    default:
      return state;
  }
};

export default discountsReducer;

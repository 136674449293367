import { customFieldsTypes } from "./custom-fields.types";

const INITIAL_STATE = {
  goHighLevel_contractor:null,
  contractor_HighLevel_CustomFields:null,
  contact:null,
}

export const customFieldsReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action

  switch (type) {
    case customFieldsTypes.SET_CONTACT:  
      return {
          ...state,
          contact: payload
        }
      case customFieldsTypes.UPDATE_CONTACT:  
      return {
          ...state,
          contact: payload
        }
    case customFieldsTypes.SET_CUSTOM_FIELDS:  
    return {
        ...state,
        contractor_HighLevel_CustomFields: payload
      }
    case customFieldsTypes.SET_GO_HIGH_LEVEL_CONTRACTOR:  
    return {
        ...state,
        goHighLevel_contractor: payload
      }
  
    default:
      return state;
  }
}
export const USER_LOGIN = {
	METHOD: 'POST',
	PATH: '/auth/login'
}

export const USER_REGISTER = {
	METHOD: 'POST',
	PATH: '/auth/register'
}

export const USER_CHANGE_PASSWORD = {
	METHOD: 'PATCH',
	PATH: '/profile/password'
}

export const USER_PASSWORD_RESET_EMAIL = {
	METHOD: 'GET',
	PATH: '/profile/reset-password-email'
}

export const GET_USER_PROFILE = {
	METHOD: 'GET',
	PATH: '/profile'
}

export const UPDATE_USER_PROFILE = {
	METHOD: 'PATCH',
	PATH: '/profile'
}

export const UPLOAD_PROFILE_IMAGE = {
	METHOD: 'POST',
	PATH: '/services/uploadImage'
}

export const VERIFY_USER_PROFILE = {
	METHOD: 'GET',
	PATH: '/profile/verify-email'
}

export const CREATE_SETTINGS_SIGNUP = {
	METHOD: 'POST',
	PATH: '/settings/create-on-signup'
}

interface NotFoundProps {
    message: string;
}
export default function NotFound({message , ...props}: NotFoundProps){
    return(
        <div className="py-10 text-center">
            <h1 className="text-2xl font-bold">We could not find any {message}</h1>
            <h1 className="text-base font-normal">Please enter your search again</h1>
        </div>
    )
}
import { rolesConstants } from './constants/roles.constants';
export const routes = {
  '/widget/[id]': {
    roles: [rolesConstants.CONTRACTOR],
  },
  '/contractor/home': {
    roles: [rolesConstants.CONTRACTOR, rolesConstants.MANAGER],
  },
  '/contractor/services-packages': {
    roles: [rolesConstants.CONTRACTOR, rolesConstants.MANAGER],
  },
  '/contractor/branding': {
    roles: [rolesConstants.CONTRACTOR, rolesConstants.MANAGER],
  },
  '/contractor/profile': {
    roles: [rolesConstants.SALES, rolesConstants.MANAGER],
  },
  '/contractor/my-account': {
    roles: [rolesConstants.CONTRACTOR],
  },
  '/contractor/integrations': {
    roles: [rolesConstants.CONTRACTOR, rolesConstants.MANAGER],
  },
  '/admin/waiting-area': {
    roles: [rolesConstants.ADMIN],
  },
  '/admin/list-batches': {
    roles: [rolesConstants.ADMIN],
  },
  '/admin/workers-list': {
    roles: [rolesConstants.ADMIN],
  },
  '/admin/bulk-addresses': {
    roles: [rolesConstants.ADMIN],
  },
  '/worker/hallway': {
    roles: [rolesConstants.SENIORWORKER],
  },
  '/admin/worker-analytics/[workerId]': {
    roles: [rolesConstants.ADMIN],
  },
  '/admin/dataset': {
    roles: [rolesConstants.ADMIN],
  },
  '/worker/measure': {
    roles: [rolesConstants.WORKER],
  },
  '/worker/dashboard': {
    roles: [rolesConstants.WORKER],
  },
  '/worker/list-batches': {
    roles: [rolesConstants.WORKER],
  },
  '/start-measuring': {
    roles: [],
  },
};

export const routeNames = {
  WIDGET_URL: '/widget/[id]',
  SIGN_IN: '/sign-in',
};

import { SERVICE_AREA_TYPES } from '../constants/services.constants';
import { getServicePrice } from './getServicePrice';
import { getServiceSqFeet } from './getServiceSqFeet';
import { roundToTwoDigits } from './roundToTwoDigits';

export const getPackagePrice = (packageItem, sqFeet, customQuotes, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  let packagePrice = 0;
  for(const service of packageItem.services) {
    let squareFeet = getServiceSqFeet(service.areaType, sqFeet, buildingSquareFeet, polygonsFeaturesSqFeet);

    let servicePrice
    if ((squareFeet && Number(squareFeet) !== 0)) {
      servicePrice = getServicePrice(
        service.pricePerSquare,
        packageItem.quantity[service._id],
        service.minPrice,
        service.fixedPrice,
        squareFeet,
        service.ranges,
        service.areaType
      );
    }

    if (servicePrice === -1 || ((service.areaType === SERVICE_AREA_TYPES.BATHROOM || service.areaType === SERVICE_AREA_TYPES.BEDROOM) && ((squareFeet && Number(squareFeet) === 0) || !squareFeet))) {
      if(customQuotes?.packages[packageItem._id]){
        return customQuotes.packages[packageItem._id]
      } else {
        return 0
      }
    }
    packagePrice += servicePrice
  };
  if(customQuotes?.packages[packageItem._id]){
    return customQuotes.packages[packageItem._id]
  }
  return roundToTwoDigits(packagePrice);
};

export const CREATE_SERVICE = {
  METHOD: 'POST',
	PATH: '/services'
}

export const CREATE_SERVICE_FROM_SHEET = {
  METHOD: 'POST',
	PATH: '/services/from-sheet'
}

export const EDIT_SERVICE_FROM_SHEET = {
  METHOD: 'PATCH',
	PATH: '/services/from-sheet'
}

export const GET_SERVICE = {
  METHOD: 'GET',
	PATH: '/services/service'
}

export const EDIT_SERVICE = {
  METHOD: 'PATCH',
	PATH: '/services'
}

export const GET_CONTRACTOR_SERVICES = {
	METHOD: 'GET',
	PATH: '/services'
}

export const GET_SERVICES = {
	METHOD: 'GET',
	PATH: '/services'
}

export const GET_SERVICES_BY_ID = {
	METHOD: 'GET',
	PATH: '/services'
}

export const GET_PUBLIC_SERVICES = {
	METHOD: 'GET',
	PATH: '/services/public'
}

export const UPLOAD_SERVICE_IMAGE = {
	METHOD: 'POST',
	PATH: '/services/uploadImage'
}
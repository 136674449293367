export const validateOrder = (order, customer?, orderQuestions?) => {
  const {user, services} = order
  let isValid = true
  let errors = []
  if(user && (user.name == "" || user.email == "" || user.phone == "")){
    isValid = false
    errors.push("Fill Order Information form correctly")
  }
  if(!services.length && !order.packages.length){
    isValid = false
    errors.push("Please select at least one package or service")
  }
  if(customer && customer.isSignatureEnabled && !order.signature) {
    isValid = false
    errors.push("Please add your Signatures")
  }
  if(orderQuestions && orderQuestions.find(question => question['answer'].length === 0)) {
    isValid = false
    errors.push("Please answer all the questions")
  }
  if(orderQuestions && orderQuestions.find(question => question['question'] === 'Are you tax exempt?' && question['answer'].includes('Yes') && !question.fileLink) ) {
    isValid = false
    errors.push("Please upload a file")
  }
  
  return {isValid, errors}
}
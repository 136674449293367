export const GET_CONTRACTOR_ORDERS = {
	METHOD: 'GET',
	PATH: '/orders'
}

export const GET_CONTRACTOR_ORDERS_FILTERED = {
	METHOD: 'GET',
	PATH: '/orders/filter'
}

export const GET_ORDERS_OVERVIEW = {
	METHOD: 'GET',
	PATH: '/orders/overview'
}

export const DELETE_ORDER_BY_ID = {
	METHOD: 'DELETE',
	PATH: '/orders/order'
}

export const GET_SEARCHED_ORDERS = {
	METHOD: 'POST',
	PATH: '/orders/search'
}

export const GET_MULTIPLE_SALESMEN_ORDERS_FILTERED = {
	METHOD: 'POST',
	PATH: '/orders/salesmen/multiple'
}

export const GET_SALESMAN_ORDERS_FILTERED = {
	METHOD: 'GET',
	PATH: '/orders/salesman'
}

export const GET_MULTIPLE_SALESMAN_ORDERS_OVERVIEW = {
	METHOD: 'POST',
	PATH: '/orders/multiple/salesmen/overview'
}

export const GET_SALESMAN_ORDERS_OVERVIEW = {
	METHOD: 'GET',
	PATH: '/orders/salesman/overview'
}
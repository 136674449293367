import React, { useState, useEffect, useRef } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';

const SelectSalesmen = ({ isSettingsLoaded, salesmen, setSalesmen, totalSalesmen, onChangeSalesmanFilter }) => {
    const ref = useRef()
    const [showOptions, setShowOptions] = useState(false)

    useEffect(() => {
        const clickOutside = e => {
            if (showOptions && ref.current && !ref.current.contains(e.target)) {
                setShowOptions(false)
            }
        }

        document.addEventListener('mousedown', clickOutside)

        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }

    }, [showOptions])

    const handleSelect = (value) => {
        if (salesmen.includes(value)) {
            const temp_salesmen = salesmen.filter(salesman => salesman !== value)
            setSalesmen(temp_salesmen)
        } else {
            setSalesmen([...salesmen, value])
        }
    }
    
    useEffect(() => {
        if (isSettingsLoaded) {
            onChangeSalesmanFilter(salesmen)
        }
    }, [salesmen])

    return (

        <div className="w-full flex flex-col justify-start min-w-full text-sm mt-[1rem]">
            <div className="" ref={ref}>
                <div className="flex flex-col max-h-64 relative">
                    <div className="w-full sticky t-0 z-2">
                        <div className="flex border border-gray-300 bg-white rounded-lg w-full">
                            <div className={`flex flex-auto h-full ${salesmen.length ? "py-[0.5rem]" :"py-[0.7rem]"} truncate overflow-y-hidden w-52`}>
                                {salesmen && salesmen.length ? salesmen?.map(salesman =>
                                    <div key={salesman} className={`flex justify-center whitespace-nowrap items-center mx-1 font-medium py-1 px-2 bg-white ${salesmen.length==1?'':'border-r-1'}  border-gray-300 text-gray-700`}>
                                        <div className="font-normal leading-none max-w-full flex-initial">{salesman}</div>
                                    </div>
                                )
                                    :
                                    <div className="flex">
                                        <input placeholder="Please select salesmen" className="bg-transparent px-2 appearance-none outline-none h-full w-full text-gray-800" disabled />
                                    </div>
                                }

                            </div>
                            <div className="text-gray-300 w-12 flex items-center border-gray-200">
                                <button className={`cursor-pointer w-full h-full text-gray-600 outline-none focus:outline-none ${showOptions && 'text-green-500'}`} onClick={() => setShowOptions(!showOptions)}>
                                    <span className="w-2 h-2 grid place-content-center m-auto">
                                        {showOptions ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    {showOptions &&
                        <div className="absolute z-10 top-11 w-full left-0 bg-white border-1 border-gray-200 rounded-sm max-h-select overflow-y-auto">
                            <div className="flex flex-col w-full px-1">
                                {totalSalesmen && totalSalesmen?.map(salesman =>
                                    <button key={salesman._id} className="cursor-pointer w-full rounded-t border-b border-gray-100" onClick={() => handleSelect(salesman)}>
                                        <div className={`flex w-full items-center p-1 pl-2 border-transparent border-l-3 relative hover:bg-gray-100 ${!salesmen.includes(salesman) ? 'hover:border-sky-400' : 'border-sky-400 hover:border-red-400'}`}>
                                            <div className="w-full items-center flex">
                                                <div className="mx-2 leading-6  ">{salesman || 'UnKnown'}</div>
                                            </div>
                                        </div>
                                    </button>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default SelectSalesmen;

import React from 'react';

const Card = ({ children, title, classes }: any) => (
  <div className={`card-div ${classes}`}>
    <div>
      <div className="heading mb-4" style={{ borderBottom: 'none' }}>
        <h3 className="text-center">{title}</h3>
      </div>
      {children}
    </div>
  </div>
);

export default Card;

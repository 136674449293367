
import { discountPaymentTypeConst } from "../constants/discountPaymentType.constants";
import { discountTypeConst } from "../constants/discountType.constants";
import { orderTypeConst } from "../constants/orderType.constants";
import { roundToTwoDigits } from "./roundToTwoDigits";

interface IOrderDiscount {
  amount: number, 
  percent: number, 
  name: string
} 

export const calculateDiscount = (discounts, totalPrice, cartPaymentType, serviceQuantity?) => {
  const appliedDiscounts: IOrderDiscount[] = []
  discounts?.map(discount => {
      let discountedPrice =  0
      const {
        active,
        percent,
        type,
        discountAmount,
        minimumOrderValue,
        paymentType,
        maximumDiscount,
        name
      }: any = discount;
      if (
        minimumOrderValue > totalPrice ||
        !active || !(paymentType.includes("BOTH") || paymentType.includes(cartPaymentType) || (paymentType.includes(discountPaymentTypeConst.PRE_PAY) && cartPaymentType === orderTypeConst.PAY_NOW))
        ) {
        // If this is true the discount is not applied
        }
      else {
        if (type === discountTypeConst.FLAT) {
          discountedPrice += serviceQuantity ?  discountAmount * serviceQuantity : discountAmount;
        } else {
          const percentDiscount = roundToTwoDigits(((totalPrice * percent) / 100).toFixed(2));
          if (maximumDiscount && percentDiscount > (serviceQuantity ?  maximumDiscount * serviceQuantity : maximumDiscount)) {
            discountedPrice += serviceQuantity ?  maximumDiscount * serviceQuantity : maximumDiscount;
          } else {
            discountedPrice += percentDiscount;
          }
        }
        totalPrice -= discountedPrice
        appliedDiscounts.push({amount: discountedPrice, percent, name})
      }
    })
  return {discountedTotal: totalPrice, discounts: appliedDiscounts }
}
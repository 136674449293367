export const GET_CUSTOMER = {
	METHOD: 'GET',
	PATH: '/customer'
}

export const GET_IS_CUSTOMER = {
	METHOD: 'GET',
	PATH: '/customer/is-customer'
}

export const CREATE_CUSTOMER = {
	METHOD: 'POST',
	PATH: '/customer'
}


export const UPDATE_CUSTOMER_STEP = {
	METHOD: 'PATCH',
	PATH: '/customer/update-customer-step'
}

export const GET_PUBLIC_CUSTOMER = {
	METHOD: 'GET',
	PATH: '/customer/get-public-customer'
}

export const CANCEL_CUSTOMER_SUBSCRIPTION = {
	METHOD: 'DELETE',
	PATH: '/customer/cancel-subscription'
}

export const UPLOAD_CUSTOMER_COMPANY_LOGO = {
	METHOD: 'POST',
	PATH: '/customer/uploadLogo'
}

export const UPDATE_CUSTOMER = {
	METHOD: 'PATCH',
	PATH: '/customer'
}

export const UPDATE_CUSTOMER_COUNTRY = {
	METHOD: 'PATCH',
	PATH: '/customer/update-customer-country'
}

export const UPDATE_CUSTOMER_INFO_DETAILS = {
	METHOD: 'PATCH',
	PATH: '/customer/contact-info'
}

export const UPDATE_BRANDING = {
	METHOD: 'PATCH',
	PATH: '/branding'
}

export const CREATE_BRANDING = {
	METHOD: 'POST',
	PATH: '/branding'
}

export const UPDATE_SETTINGS = {
	METHOD: 'PATCH',
	PATH: '/settings'
}

export const CREATE_SETTINGS = {
	METHOD: 'POST',
	PATH: '/settings'
}

export const CHECK_TRIALING_LIMIT = {
	METHOD: 'GET',
	PATH: '/customer/check-trailing-limit'
}

export const CUSTOMER_ADVANCED_SERVICE_AREA_SUBSCRIPTION = {
	METHOD: 'POST',
	PATH: '/customer/insert-addon',
}

export const CUSTOMER_PLAN_SUBSCRIPTION = {
	METHOD: 'PUT',
	PATH: '/customer/updatePlanSubscription'
}
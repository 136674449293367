import { SERVICE_AREA_TYPES } from '../constants/services.constants';

export const getServiceSqFeet = (areaType, lawnSqFeet, buildgSqFt, polygonsFeaturesSqFeet) => {
  let squareFeet = 0;
  if (areaType === SERVICE_AREA_TYPES.LAW_AREA) {
    squareFeet = lawnSqFeet;
  // } else if (areaType === SERVICE_AREA_TYPES.LOT_AREA) {
  //   squareFeet = boundarySqFeet;
  } else if (areaType === SERVICE_AREA_TYPES.BUILDING_AREA) {
    squareFeet = buildgSqFt;
  } else if (areaType === SERVICE_AREA_TYPES.BATHROOM) {
    squareFeet = polygonsFeaturesSqFeet['baths']
  } else if (areaType === SERVICE_AREA_TYPES.BEDROOM) {
    squareFeet = polygonsFeaturesSqFeet['bedrooms']
  } else if (polygonsFeaturesSqFeet[areaType]) {
    squareFeet = polygonsFeaturesSqFeet[areaType]
  }
  return squareFeet;
}

import React from "react";
interface TotalObj {
    label: string;
    amount: string;
  }
  interface TabsProps {
    data: TotalObj;
  }
  export default function Total({ data, ...props }: TabsProps) {
    return (
      <div className="bg-green-900 w-full md:w-[194px] rounded-xl p-4">
        <h1 className="text-2xl md:text-2xl font-bold text-sky-100">{data.amount}</h1>
        <p className="text-white text-xs uppercase ">Total {data.label}</p>
      </div>
    );
  }
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { RiPencilRulerLine } from 'react-icons/ri';
import {
  BellIcon,
  FolderIcon,
  HomeIcon,
  MenuAlt2Icon,
  UserIcon,
  XIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';
import { Button } from '@material-ui/core';
import classnames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { HiOutlineDatabase } from 'react-icons/hi';
import { BsCameraVideo, BsMenuButtonWide, BsClipboardData, BsMegaphone, BsCreditCard2Front } from 'react-icons/bs';
import { MdOutlineIntegrationInstructions, MdWorkOutline, MdOutlineBrandingWatermark } from 'react-icons/md';

import { TbBrandAirtable } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { rolesConstants } from '../../constants/roles.constants';
import { resetMeasurement } from '../../redux/measurement/measurement.actions';
import { logoutUser } from '../../redux/user/user.actions';
import { resetWidget } from '../../redux/widget/widget.actions';
import styles from './dashboard.layout.module.scss';
import ProfileMenu from '../../library/stories/Menu/ProfileMenu';

import { AiOutlineMenu } from 'react-icons/ai'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { SERVICE_CART_PNG } from '../../constants/app.constants';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export interface Props {
  isNotFooter?: boolean;
}

interface INavLinkParams {
  pathname: string;
  text: string;
  icon?: IconDefinition;
  url?: string;
}

const SidenavLink = (params: INavLinkParams) => {
  const router = useRouter();
  return (
    <Link href={params.url}>
      <Button
        variant={router.pathname === params.pathname ? 'contained' : 'text'}
        className={classnames({
          [styles.active]: router.pathname === params.pathname,
        })}
      >
        {params.text}
      </Button>
    </Link>
  );
};

export const DashboardLayout = (props: PropsWithChildren<Props>) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions()
  const { user, token } = useSelector((state) => state.user);
  const { customer } = useSelector((state) => state.customer);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const contractor = user?.roles[0] === rolesConstants.CONTRACTOR;
  const sales = user?.roles[0] === rolesConstants.SALES;
  const robin = user?.roles?.includes(rolesConstants.ROBIN);

  const isContractor = user?.roles?.includes(rolesConstants.CONTRACTOR);
  const hasDensityBlaster = (customer?.settings?.enableDensityBlaster) || false

  //This code is for to show the first Name when the length is greater than 15

  let oneWordFromName = user?.name.length > 15 ? user?.name?.split(/\s+/).slice(0, 1).join(" ") : user?.name;
  // let twoWordsFromCompanyName = user?.company?.split(/\s+/).slice(0, 2).join(" ");
  // let shortName = twoWordsFromUserName?.split(' ').map(word => word.charAt(0));
  // let shortCompanyName = twoWordsFromCompanyName?.split(' ').map(word => word.charAt(0))

  let twoWordsFromUserName = user ? user.name.split(/\s+/).slice(0, 2).join(" ") : '';
  let twoWordsFromCompanyName = user ? user.company.split(/\s+/).slice(0, 2).join(" ") : '';
  let shortName = twoWordsFromUserName ? twoWordsFromUserName.split(' ').map(word => word.charAt(0)) : '';
  let shortCompanyName = twoWordsFromCompanyName ? twoWordsFromCompanyName.split(' ').map(word => word.charAt(0)) : '';

  const contractorNavigation = window.innerWidth < 768 ? [
    { name: 'Dashboard', href: '/contractor/home', pathname: '/contractor/home', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/dashboard_xcc1cr.svg' },
    { name: 'Search', href: `/contractor/search/${user?._id}`, pathname: '/contractor/search/[id]', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/search_tlqxm9.svg' },
    { name: 'Services', href: '/contractor/services-packages', pathname: '/contractor/services-packages', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/services_bew4o0.svg' },
    //Temporarily added bulk data navigator check before finalizing
    // { name: 'Bulk Data', href: '/contractor/bulk-data', pathname: '/contractor/bulk-data', icon: HiOutlineDatabase },
    // { name: 'Templates', href: '/contractor/postcard-templates', pathname: '/contractor/postcard-templates', icon: BsMenuButtonWide },
    // { name: 'Density Blaster', href: '/contractor/density-blaster', pathname: '/contractor/density-blaster', icon: BsClipboardData },
    { name: 'Service Area', href: '/contractor/not-available', pathname: '/contractor/not-available', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/servicearea_i4xccb.svg' },
    { name: 'Integrations', href: '/contractor/integrations', pathname: '/contractor/integrations', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092831/Assets/sidebar/integrations_wfhaqp.svg' },
    { name: 'Branding', href: '/contractor/branding', pathname: '/contractor/branding', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/branding_zup9n3.svg' },
    // { name: 'Tutorials', href: '/contractor/tutorials', pathname: '/contractor/tutorials', icon: BsCameraVideo },
    // { name: 'Measurements', href: '/contractor/measurements', pathname: '/contractor/measurements', icon: PresentationChartLineIcon },
    {
      name: 'Marketing',
      href: '/contractor/marketing',
      pathname: '/contractor/marketing',
      icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/marketing_ifsdte.svg',
    },
  ] : [
    { name: 'Dashboard', href: '/contractor/home', pathname: '/contractor/home', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/dashboard_xcc1cr.svg' },
    { name: 'Search', href: `/contractor/search/${user?._id}`, pathname: '/contractor/search/[id]', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/search_tlqxm9.svg' },
    { name: 'Services', href: '/contractor/services-packages', pathname: '/contractor/services-packages', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/services_bew4o0.svg' },
    //Temporarily added bulk data navigator check before finalizing
    // { name: 'Bulk Data', href: '/contractor/bulk-data', pathname: '/contractor/bulk-data', icon: HiOutlineDatabase },
    // { name: 'Templates', href: '/contractor/postcard-templates', pathname: '/contractor/postcard-templates', icon: BsMenuButtonWide },
    // { name: 'Density Blaster', href: '/contractor/density-blaster', pathname: '/contractor/density-blaster', icon: BsClipboardData },
    { name: 'Service Area', href: '/contractor/service-area', pathname: '/contractor/service-area', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/servicearea_i4xccb.svg' },
    { name: 'Integrations', href: '/contractor/integrations', pathname: '/contractor/integrations', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092831/Assets/sidebar/integrations_wfhaqp.svg' },
    { name: 'Branding', href: '/contractor/branding', pathname: '/contractor/branding', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/branding_zup9n3.svg' },
    // { name: 'Tutorials', href: '/contractor/tutorials', pathname: '/contractor/tutorials', icon: BsCameraVideo },
    // { name: 'Measurements', href: '/contractor/measurements', pathname: '/contractor/measurements', icon: PresentationChartLineIcon },
    {
      name: 'Marketing',
      href: '/contractor/marketing',
      pathname: '/contractor/marketing',
      icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/marketing_ifsdte.svg',
    },
  ]

  const managerNavigation = window.innerWidth < 768 ? [
    { name: 'Dashboard', href: '/contractor/home', pathname: '/contractor/home', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/dashboard_xcc1cr.svg' },
    { name: 'Search', href: `/contractor/search/${user?.owner_profile_id}`, pathname: '/contractor/search/[id]', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/search_tlqxm9.svg' },
    { name: 'Services', href: '/contractor/services-packages', pathname: '/contractor/services-packages', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/services_bew4o0.svg' },
    { name: 'Service Area', href: '/contractor/not-available', pathname: '/contractor/not-available', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/servicearea_i4xccb.svg' },
    { name: 'Integrations', href: '/contractor/integrations', pathname: '/contractor/integrations', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092831/Assets/sidebar/integrations_wfhaqp.svg' },
    { name: 'Branding', href: '/contractor/branding', pathname: '/contractor/branding', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/branding_zup9n3.svg' },
    { name: 'Tutorials', href: '/contractor/tutorials', pathname: '/contractor/tutorials', icon: BsCameraVideo },
    // { name: 'Measurements', href: '/contractor/measurements', pathname: '/contractor/measurements', icon: PresentationChartLineIcon },
  ] : [
    { name: 'Dashboard', href: '/contractor/home', pathname: '/contractor/home', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/dashboard_xcc1cr.svg' },
    { name: 'Search', href: `/contractor/search/${user?.owner_profile_id}`, pathname: '/contractor/search/[id]', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/search_tlqxm9.svg' },
    { name: 'Services', href: '/contractor/services-packages', pathname: '/contractor/services-packages', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/services_bew4o0.svg' },
    { name: 'Service Area', href: '/contractor/service-area', pathname: '/contractor/service-area', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/servicearea_i4xccb.svg' },
    { name: 'Integrations', href: '/contractor/integrations', pathname: '/contractor/integrations', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092831/Assets/sidebar/integrations_wfhaqp.svg' },
    { name: 'Branding', href: '/contractor/branding', pathname: '/contractor/branding', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/branding_zup9n3.svg' },
    { name: 'Tutorials', href: '/contractor/tutorials', pathname: '/contractor/tutorials', icon: BsCameraVideo },
    // { name: 'Measurements', href: '/contractor/measurements', pathname: '/contractor/measurements', icon: PresentationChartLineIcon },
  ]

  const salesNavigation = [
    { name: 'Dashboard', href: '/contractor/home', pathname: '/contractor/home', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/dashboard_xcc1cr.svg' },
    { name: 'Search', href: `/contractor/search/${user?.owner_profile_id}`, pathname: '/contractor/search/[id]', icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/search_tlqxm9.svg' },
  ]

  const logout = () => {
    dispatch(logoutUser(router));
  };

  const userNavigation = [
    { name: 'Profile', onClick: () => router.push('/contractor/profile'), icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/person_vr2994.svg' },
    { name: 'Logout', href: '', onClick: logout, icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/logout_iatbf5.svg' },
  ];
  // { name: 'Tutorials', href: '/contractor/tutorials', pathname: '/contractor/tutorials', icon: BsCameraVideo },
  const contractorDropDownNavigation = [
    { name: 'My Account', onClick: () => router.push('/contractor/my-account'), icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/person_vr2994.svg' },
    { name: 'Tutorials', onClick: () => router.push('/contractor/tutorials'), icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/tutorials_qycnhk.svg' },
    { name: 'Logout', onClick: () => dispatch(logoutUser(router)), icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/logout_iatbf5.svg' },
  ];

  const robinNavigation = [
    {
      name: 'Search',
      href: `/contractor/search/${user?.owner_profile_id || user?._id}`,
      pathname: '/contractor/search/[id]',
      icon: 'https://res.cloudinary.com/duynyjen3/image/upload/v1683092832/Assets/sidebar/search_tlqxm9.svg',
    },
  ];

  const robinDropdownNavigation = [
    { name: 'Logout', onClick: () => dispatch(logoutUser(router)) },
  ];

  let navigation = contractor
    ? contractorNavigation
    : sales
      ? salesNavigation
      : managerNavigation;

  let dropDownNav = contractor ? contractorDropDownNavigation : userNavigation;

  if (robin) {
    navigation = robinNavigation
    dropDownNav = robinDropdownNavigation
  }

  const profileIcon = contractor ? customer?.customerLogo : user?.avatar;

  useEffect(() => {
    if (router.pathname == '/contractor/search/[id]') {
      dispatch(resetWidget());
      dispatch(resetMeasurement());
    }
  }, [router]);

  useEffect(() => {
    if (user && user.roles.includes('UNPAIDCUSTOMER')) {
      router.replace('/contractor/payment-details');
    }
  }, []);

  if (!user) {
    router.replace('/');
    return <div></div>;
  }

  function getSelectedPageName() {
    if (router.pathname) {
      return router.pathname === '/contractor/home' ? 'Dashboard' :
        router.pathname === '/contractor/services-packages' ? 'Services' :
          router.pathname === '/contractor/search/[id]' ? 'Search' :
            router.pathname === '/contractor/service-area' ? 'Service Area' :
              router.pathname === '/contractor/integrations' ? 'Integrations' :
                router.pathname === '/contractor/branding' ? 'Branding' :
                  router.pathname === '/contractor/measurements' ? 'Measurements' :
                    router.pathname === '/contractor/marketing' ? 'marketing' :
                      'Servicecart'
    }
  }

  return (
    <>
      <div>
        {/* mobile sidebar options */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <Link href="/contractor/home">
                      <img
                        style={{ maxWidth: '200px', marginTop: '8px', marginLeft: '10px' }}
                        src={SERVICE_CART_PNG}
                        alt="service-cart-logo"
                      />
                    </Link>
                  </div>
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <nav className="px-2 space-y-1">
                      {navigation.map((item) => {
                        return (
                          <Link href={item.href}>
                            <div
                              key={item.name}
                              className={classNames(
                                router.pathname === item.pathname
                                  ? 'bg-gray-50 text-green-500'
                                  : 'text-gray-600 hover:bg-gray-50 hover:text-green-500',
                                'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer'
                              )}
                            >
                              <div className="flex items-center gap-5 flex-row">
                                <img src={item.icon} width="32px" height="32px" alt="" />
                                {item.name}
                                {/* {item.name === "Density Blaster" && <span className="flex items-center bg-green-50 text-green-500 border-2 border-gray-500 text-xs font-normal ml-2 px-3 py-1 rounded-lg">Beta</span>} */}
                                {item.name === "Density Blaster" && <span className={`flex absolute items-center bg-gray-50 text-green-500 border-2 border-gray-500 rounded-lg px-2 py-0.5 text-xs font-normal ${width < 358 ? 'ml-[195px]' : 'ml-[230px]'} `}>BETA</span>}
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </nav>
                  </div>
                  <ProfileMenu
                    contractorDropDownOptions={contractorDropDownNavigation}
                    username={user && shortName}
                    company={user && shortCompanyName}
                    avatar={profileIcon}
                  />
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* mobile sidebar options end */}

        {/* ------------------- sidebar desktop ------------------- */}
        {/* <div className='relative md:w-64'> */}
        <div className="z-10 hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
          <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-gray-50 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <Link href="/contractor/home">
                <img
                  style={{ maxWidth: '184px', marginLeft: '10px' }}
                  src={SERVICE_CART_PNG}
                  alt="service-cart-logo"
                />
              </Link>
            </div>
            <div className=" z-50 flex-grow flex flex-col justify-between px-3 pt-12 pb-5">
              <nav className="px-2 space-y-1">
                {navigation.map((item) => {
                  return (
                    <Link href={item.href}>
                      <div
                        key={item.name}
                        className={classNames(
                          router.pathname === item.pathname
                            ? 'bg-white text-green-500'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-green-500',
                          `${height <= 580 ? 'py-1 text-xs' : 'py-2 text-sm'} group flex items-center pl-2 text-sm font-medium rounded-md cursor-pointer`
                        )}
                      >
                        <div className={`gap-5 flex items-center flex-row`}>
                          <img src={item.icon} width={`${height <= 580 ? '28px' : '32px'}`} height={`${height <= 580 ? '28px' : '32px'}`} alt="" />
                          {item.name}
                          {item.name === "Density Blaster" && <span className="flex absolute items-center bg-gray-50 text-green-500 border-2 border-gray-500 rounded-lg px-2 py-0.5 text-xs font-normal ml-[165px]">BETA</span>}
                        </div>

                      </div>
                    </Link>
                  );
                })}
              </nav>

            </div>
            <div className='z-50 absolute w-[224px] bottom-5 right-[21px]'>
              <ProfileMenu
                contractorDropDownOptions={dropDownNav}
                username={user && oneWordFromName}
                company={''}
                // company={user && shortCompanyName}
                avatar={profileIcon}
              />
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* the sidebar-----------------------------  */}

        <div className="lg:pl-64 flex flex-col z-0 flex-1" style={{ height: '100vh' }}>
          <div className="sticky top-0 z-10 flex-shrink-0 flex items-center h-16 lg:h-0 bg-white border border-gray-100 lg:border-0">
            <button
              type="button"
              className="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 lg:hidden"

              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <AiOutlineMenu className="h-6 w-6" aria-hidden="true" />
            </button>
            <h1 className='lg:hidden text-base font-bold'>{getSelectedPageName()}</h1>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">
                {/* <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />pcon
                    </div>
                    <input
                      id="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </form> */}
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                {/* <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="md:hidden max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                      <span className="sr-only">Open user menu</span>
                      {profileIcon ? <img
                        className="h-8 w-8 rounded-full"
                        src={profileIcon || 'https://ui-avatars.com/api/?name=DeepLawn'}
                        alt="User Avatar"
                      /> :
                        <div className='rounded-full bg-green-50 border border-green-200 p-2 min-w-fit'>
                          <img src="https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/person_vr2994.svg" alt="profile" className='w-8 h-8' />
                        </div>
                      }
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right z-[100] absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-50 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {dropDownNav.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <div
                              onClick={item.onClick}
                              className={classNames(
                                active ? 'bg-white' : '',
                                'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                              )}
                            >
                              {item.name}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                      
                    </Menu.Items>
                  </Transition>
                </Menu> */}
              </div>
            </div>
          </div>


          <main className="flex-1 z-0 bg-white"
            style={{
              // display: (router.pathname === '/contractor/template' || router.pathname === '/contractor/edit-template/[id]') ? "flex" : "block",
              ...((router.pathname === '/contractor/template' || router.pathname === '/contractor/edit-template/[id]') && { flexDirection: "column", display: "flex" })
            }}>
            {/* <div className=""> */}
            {props.children}
            {/* </div> */}
          </main>
        </div>
      </div>
    </>
  );
};

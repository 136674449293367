import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Menu as MaterialMenu, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import Link from 'next/link';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import {
    deleteOrder,
    getFilteredContractorOrders,
    getFilteredMultipleSalesmanOrders,
    getFilteredSalesmanOrders,
    getMultipleSalesmenOverview,
    getOrdersOverview,
    getSalesmanOrdersOverview,
} from '../../redux/contractor/contractor.actions';
import { contractorTypes } from '../../redux/contractor/contractor.types';
import sendRequest from '../../utils/useAxios';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteModal from '../delete-modal/delete-modal.component';
import MenuComponent from '../menu/menu.component';
import { getCustomer } from '../../redux/customer/customer.actions';
import { deleteLead, getLeads, resetLead } from '../../redux/leads/leads.actions';
import { resetMeasurement } from '../../redux/measurement/measurement.actions';
import { isManager, isSalesman, isTeamMember, salesTeamMember } from '../../utils/isTeamMember';
import { getWholeTeam } from '../../redux/teamMember/teamMember.action';
import SelectRegionsComponent from '../common/select-regions-component';
import SelectSalesmen from '../common/SelectSalesmen';
import { rolesConstants } from '../../constants/roles.constants';
import { getUserMainRole } from '../../redux/user/user.selectors';
import ErrorAlert from '../tailwind/alerts/error-alert.component';
import ErrorAlertSingleLine from '../tailwind/alerts/error-alert-singleLine.component';
import { Table } from '../../library/stories/Tables/Table';
import Total from '../../library/stories/Cards/Total';
import CreditButton from '../../library/stories/Buttons/CreditButton';
import { Filter } from '../../library/stories/Buttons/Filter';
import { Search } from '../../library/stories/Inputs/Search';
import { searchMeasurements } from '../../redux/searches-log/searches-log.actions';
import { getSearchedLeads } from '../../redux/leads/leads.actions';
import { getSearchedOrders } from '../../redux/contractor/contractor.actions';
import { Chart } from '../../library/stories/Charts/Chart';
import NotFound from '../../library/stories/Cards/NotFound';
import { hasCustomerDisabledTax } from '../../redux/customer/customer.selectors';
import { calculateMaxTotalWithoutTax, calculateTotalWithoutTax, getMaxTotalPrice, getMaxTotalPriceWithoutTax, getTotalPrice, getTotalPriceWithoutTax } from '../../redux/widget/widget.selectors';


function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

let FILTER_LIST = [
    'Today',
    'Yesterday',
    'Last 7 Days',
    'Last 30 Days',
    'This Month',
    'Last Month',
    'Lifetime',
    // ...UTM_PARAMS,
];

enum ORDER_TYPES {
    "order",
    "lead"
}

const ContractorOrders = (props: Props) => {
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState<string>()
    const [searchResults, setSearchResults] = useState<boolean>(false)
    const [searchResultsString, setSearchResultsString] = useState<string>()
    const [totalSearches, setTotalSearches] = useState(0);
    const [totalSearchesChartData, setTotalSearchesChartData] = useState([]);
    const [totalSales, setTotalSales] = useState(0);
    const [numberOfSales, setNumberOfSales] = useState(0)
    const [totalSalesChartData, setTotalSalesChartData] = useState([]);
    const [totalQuotes, setTotalQuotes] = useState(0);
    const [payNowUncheckCount, setPayNowUncheckCount] = useState(0);
    const [payAsYouGoUncheckCount, setPayAsYouGoUncheckCount] = useState(0);
    const [estimateUncheckCount, setEstimateUncheckCount] = useState(0);
    const [allOrdersUncheckCount, setAllOrdersUncheckCount] = useState(0);
    const [payAsCheckUnCheckCount, setPayAsCheckUnCheckCount] = useState(0);
    const [hasLeadsCount, setHasLeadsCount] = useState(0)
    const [totalQuotesChartData, setTotalQuotesChartData] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [transition, setTransition] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [salesmenFilter, setSalesmenFilter] = useState([])
    const [showDelModal, setShowDelModal] = useState(false);
    const [orderId, setOrderId] = useState("")
    const [leadId, setLeadId] = useState("")
    const [isDeleteLead, setIsDeleteLead] = useState(false);
    const [isDeleteOrder, setIsDeleteOrder] = useState(false);
    const [salesmen, setSalesmen] = React.useState<any>([]);
    const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
    const [utmEnableParameter, setUtmEnableParameter] = useState(false);
    const [activeTabData, setActiveTabData] = useState([]);
    const [sortField, setSortField] = useState('createdAt');
    const [sortOrder, setSortOrder] = useState('desc');

    const { orders, ordersFilter, overviewFilter, totalCount, overviewData } = useSelector(
        (state: any) => state.contractor
    );
    const mainRole = useSelector(getUserMainRole());
    const { customer, loading, currency } = useSelector((state: any) => state.customer)
    const { user } = useSelector((state: any) => state.user)
    const { leads, leadsUnCheckedCount } = useSelector((state: any) => state.leads)
    const { salesTeam } = useSelector((state: any) => state.teamMember)

    const [dateFilter, setFilter] = useState(overviewFilter);
    const [utmFilterList, setUtmFilterList] = useState<string[]>([]);
    const [utmFilterOptions, setUtmFilterOptions] = useState<{ name: string, onClick: () => void }[]>([{ name: 'All', onClick: () => onFilterUTM }]);

    const total = useSelector(hasCustomerDisabledTax(customer) ? calculateTotalWithoutTax() : getTotalPrice());
  const maxTotal = useSelector(hasCustomerDisabledTax(customer) ? calculateMaxTotalWithoutTax() : getMaxTotalPrice());
  const subTotal = useSelector(getTotalPriceWithoutTax());
  const maxSubTotal = useSelector(getMaxTotalPriceWithoutTax());

    const [utmFilter, setUtmFilter] = useState('ALL');
    const open = Boolean(anchorEl);

    const getUTMFilter = (filter) => {
        if (filter == 'ALL') return ''

        return filter
    }

    useEffect(() => {
        if (customer) {
            setUtmEnableParameter(customer?.settings?.utmEnableParameter || false);
        }
    }, [customer]);

    const fetchUniqueUTMTags = async () => {
        try {
            let url = ordersFilter === 'LEADS' ? '/leads/get-unique-utm-tags' : '/orders/get-unique-utm-tags';
            const { data } = await sendRequest('GET', url);
            if (data && data.length) {
                setUtmFilterList(['ALL', ...data]);
                const utmFilterOptions = data
                    .filter(item => item !== null && item.trim() !== '')
                    .map(item => ({
                        name: item,
                        onClick: () => onFilterUTM(item)
                    }));
                setUtmFilterOptions([{ name: 'All', onClick: () => onFilterUTM('') }].concat(utmFilterOptions))
            } else {
                setUtmFilterList([]);
                setUtmFilter('ALL');
                setUtmFilterOptions([{ name: 'All', onClick: () => onFilterUTM('All') }]);
            }
        } catch (error) {
            // Handle error
            console.error('Error fetching unique UTMTags:', error);
        }
    };

    useEffect(() => {
        if (!user) return
        fetchUniqueUTMTags()
    }, [user, ordersFilter])


    // useEffect(() => {
    //   if (utmEnableParameter) {
    //     FILTER_LIST = FILTER_LIST.concat(UTM_PARAMS)
    //   }
    // }, [utmEnableParameter]);

    useEffect(() => {
        if (!user) return
        if (!isManager(user)) {
            onChangeOverviewFilter(overviewFilter);
            dispatch(resetMeasurement())
            dispatch(resetLead())
        } else if (isTeamMember(user)) {
            dispatch(getWholeTeam(user.owner_profile_id))
        }
    }, [user]);

    function salesTeamArray(team) {
        // let resultArr = ['All']
        let resultArr: any[] = []
        for (let member of team) {
            resultArr.push(member.profile.profileId.username)
        }
        return resultArr
    }

    useEffect(() => {
        if (!customer && !loading) {
            dispatch(getCustomer())
        }
    }, [customer, loading])

    useEffect(() => {
        const query = `page=${pageNo}&utmParameter=${utmFilter}&sortField=${sortField}&sortOrder=${sortOrder}`
        if (!isSalesman(user)) {
            dispatch(getLeads(user._id, query))
        }
    }, [])

    useEffect(() => {
        if (customer) {
            if (user && isManager(user)) {
                const temp_filter = customer?.settings?.managerSelectOptions?.dateRangeSelected ? customer?.settings?.managerSelectOptions?.dateRangeSelected : dateFilter
                const temp_salesmen = customer?.settings?.managerSelectOptions?.salesmenSelected || []
                setFilter(temp_filter)
                setSalesmen(temp_salesmen)
                setSalesmenFilter(temp_salesmen)
                setFilter(temp_filter);
                onChangeOverviewFilter(customer?.settings?.managerSelectOptions?.dateRangeSelected, customer?.settings?.managerSelectOptions?.salesmenSelected)
                onChangeSalesmanFilter(customer?.settings?.managerSelectOptions?.salesmenSelected, customer?.settings?.managerSelectOptions?.dateRangeSelected)
                setIsSettingsLoaded(true)
            }
        }
    }, [user, customer, salesTeam, ordersFilter])

    useEffect(() => {

        if (!user) return
        const query = `paymentType=${ordersFilter}&page=${pageNo}&memberType=member&utmParameter=${getUTMFilter(utmFilter)}&sortField=${sortField}&sortOrder=${sortOrder}`
        if (!isManager(user)) {
            onChangeOverviewFilter(dateFilter)
        }
        // else {
        //   return
        // }
        if (!isSalesman(user)) {
            if (ordersFilter && ordersFilter !== 'LEADS') {
                if (!salesmenFilter?.length) {
                    dispatch(getFilteredContractorOrders(query));
                } else {
                    let salesmenIds = salesTeamMember(salesmenFilter, salesTeam)
                    if (salesmenIds) {
                        dispatch(getFilteredMultipleSalesmanOrders(query, salesmenIds))
                    }
                }
            }
        } else {
            if (ordersFilter && ordersFilter !== 'LEADS') {
                const salesmanId = user._id
                dispatch(getFilteredSalesmanOrders(query, salesmanId));
            }
        }
    }, [ordersFilter, pageNo, user, sortField, sortOrder]);


    useEffect(() => {
        let salesmenIds = salesTeamMember(salesmenFilter, salesTeam)
        getUnCheckedOrdersCount(user._id, isSalesman(user), isManager(user), salesmenIds);
    }, []);

    const getUnCheckedOrdersCount = async (userId, isTeam, isManager, salesmenIds) => {
        let output
        if (!isTeam) {
            const { data } = await sendRequest('GET', `/orders/count/unchecked?utmParameter=${getUTMFilter(utmFilter)}`);
            output = data
        } else if (isManager) {
            const { data } = await sendRequest('POST', `/orders/multiple/salesmen/count/unchecked?ututmParameterm=${getUTMFilter(utmFilter)}`, { salesmenIds })
            output = data
        } else {
            const { data } = await sendRequest('GET', `/orders/salesman/count/unchecked/${userId}?utmParameter=${getUTMFilter(utmFilter)}`)
            output = data
        }

        if (!output.statusCode) {
            let totalCounts = 0;
            setPayNowUncheckCount(0)
            setPayAsYouGoUncheckCount(0)
            setEstimateUncheckCount(0)
            setPayAsCheckUnCheckCount(0)
            setAllOrdersUncheckCount(0)
            output.map((obj: any) => {
                if (obj.object?.payment === 'PAY_NOW') {
                    setPayNowUncheckCount(obj.count);
                    totalCounts += obj.count;
                }
                if (obj.object?.payment === 'PAY_AS_YOU_GO') {
                    setPayAsYouGoUncheckCount(obj.count);
                    totalCounts += obj.count;
                }
                if (obj.object?.payment === 'ESTIMATE_SENT') {
                    setEstimateUncheckCount(obj.count);
                    totalCounts += obj.count;
                }
                if (obj.object?.payment === 'PAY_BY_CHECK') {
                    setPayAsCheckUnCheckCount(obj.count);
                    totalCounts += obj.count;
                }
                setAllOrdersUncheckCount(totalCounts);
            });
        }
    };

    useEffect(() => {
        if (ordersFilter === 'LEADS' && !isSalesman(user)) {
            const query = `page=${pageNo}&utmParameter=${getUTMFilter(utmFilter)}&sortField=${sortField}&sortOrder=${sortOrder}`
            dispatch(getLeads(user._id, query))
        }
    }, [pageNo, ordersFilter, sortField, sortOrder])

    const handleSorting = (sortFieldTable: string, sortOrderTable: string) => {
        setSortField(sortFieldTable)
        setSortOrder(sortOrderTable)

    };



    const handleDeleteClick = (id, type) => {
        if (type === 'lead') {
            setLeadId(id)
            setIsDeleteLead(true)
        } else if (type === 'order') {
            setOrderId(id)
            setIsDeleteOrder(true)
        }
        setShowDelModal(true)
        setAnchorEl(null);
    }


    const onDeleteClick = async () => {

        if (isDeleteOrder) {
            dispatch(deleteOrder(orderId))
        } else if (isDeleteLead) {
            dispatch(deleteLead(leadId))
        }
        setShowDelModal(false)
    }

    const handleBack = () => {
        setShowDelModal(false)
    }


    const handleMenuClose = () => {
        setAnchorEl(null);
        setIsDeleteLead(false)
        setIsDeleteOrder(false)
    };


    const handlePageChange = (event: any, value: any) => {
        setPageNo(value);
    };

    const getOrderFilterContent = (orderFilter: any) => {
        if (orderFilter === 'All') {
            return 'You don’t have any estimates and orders.';
        } else if (orderFilter == 'PAY_AS_YOU_GO') {
            return 'You don’t have any pay as you go orders.';
        } else if (orderFilter == 'PAY_NOW') {
            return 'You don’t have any paid orders.';
        } else if (orderFilter == 'PAY_BY_CHECK') {
            return 'You don’t have any pay by check orders.';
        } else if (orderFilter == 'ESTIMATE_SENT') {
            return 'You don’t have any estimates';
        } else if (orderFilter == 'LEADS') {
            return 'You don’t have any leads';
        }

    };

    useEffect(() => {
        if (overviewData) {
            const { ordersData, quotesData, searchesData } = overviewData;
            setTotalSearchesChartData(searchesData.data);
            setTotalSearches(searchesData.count);

            setTotalSalesChartData(ordersData.data);
            setTotalSales(ordersData.sum?.toFixed(2));

            setNumberOfSales(ordersData.data.length)

            setTotalQuotesChartData(quotesData.data);
            setTotalQuotes(quotesData.count);
        }
    }, [overviewData]);

    const onChangeOverviewFilter = async (filter: any, salesmenNames = salesmen) => {
        setFilter(filter);
        if (isSalesman(user)) {
            dispatch(getSalesmanOrdersOverview(filter, user._id))
        } else {

            let tempNames: String[] = []

            if (salesmenNames?.length) {
                tempNames = [...salesmenNames]
            } else if (salesmenFilter.length) {
                tempNames = [...salesmenFilter]
            }

            let salesmenIds = salesTeamMember(tempNames, salesTeam)
            if (isManager(user)) {
                await sendRequest('PATCH', `/settings/${customer?.settings?._id}`, {
                    managerSelectOptions: {
                        salesmenSelected: salesmenNames,
                        dateRangeSelected: filter
                    }
                })
            }
            if (!salesmenIds.length) {
                dispatch(getOrdersOverview(filter));
            } else {
                dispatch(getMultipleSalesmenOverview(filter, salesmenIds))
            }

        }
        dispatch({
            type: contractorTypes.SET_OVERVIEW_FILTER,
            payload: filter,
        });
    };

    const onFilterUTM = async (filter) => {
        setUtmFilter(filter)
    }

    // This should run when user will change UTM Filter
    useEffect(() => {

        // Get updated unchecked values of orders
        let salesmenIds = salesTeamMember(salesmenFilter, salesTeam)
        getUnCheckedOrdersCount(user._id, isSalesman(user), isManager(user), salesmenIds);

        // Get updated leads
        const leadQuery = `page=${pageNo}&utmParameter=${getUTMFilter(utmFilter)}&sortField=${sortField}&sortOrder=${sortOrder}`
        if (!isSalesman(user)) {
            dispatch(getLeads(user._id, leadQuery))
        }

        // Update Orders
        const query = `paymentType=${ordersFilter}&page=${pageNo}&memberType=member&utmParameter=${getUTMFilter(utmFilter)}&sortField=${sortField}&sortOrder=${sortOrder}`
        if (!isSalesman(user)) {
            dispatch(getFilteredContractorOrders(query));
        }

    }, [utmFilter, sortField, sortOrder])

    useEffect(() => {
        if (orders && orders.length && ordersFilter)
            getActiveTabData()
        if (leads && leads.length && ordersFilter)
            getActiveTabData()
    }, [orders, leads, ordersFilter])


    const onChangeSalesmanFilter = async (filter: any, rangeFilter = dateFilter) => {
        setSalesmenFilter(filter)
        if (isManager(user)) {
            await sendRequest('PATCH', `/settings/${customer?.settings?._id}`, {
                managerSelectOptions: {
                    salesmenSelected: filter,
                    dateRangeSelected: dateFilter
                }
            })
        }
        if (!filter?.length) {
            if (ordersFilter && ordersFilter !== 'LEADS') {
                const query = `paymentType=${ordersFilter}&page=${pageNo}&memberType=contractor&utmParameter=${getUTMFilter(utmFilter)}`
                dispatch(getFilteredSalesmanOrders(query, user.owner_profile_id));
                getUnCheckedOrdersCount(null, false, false, null)
                dispatch(getOrdersOverview(dateFilter))
            }
            return
        }

        let salesmenIds = salesTeamMember(filter, salesTeam)
        if (salesmenIds) {
            dispatch(getMultipleSalesmenOverview(dateFilter, salesmenIds))
            if (ordersFilter && ordersFilter !== 'LEADS') {
                const query = `paymentType=${ordersFilter}&page=${pageNo}&utmParameter=${getUTMFilter(utmFilter)}`
                dispatch(getFilteredMultipleSalesmanOrders(query, salesmenIds));
                getUnCheckedOrdersCount(null, true, true, salesmenIds)
            }
        }
    }

    const getFilteredOrders = (type: any) => {
        setPageNo(1);
        dispatch({
            type: contractorTypes.SET_ORDERS_FILTER,
            payload: type,
        });
        if (searchInput) {
            setSearchInput('')
            setSearchResults(false)
        }
    };

    const handleClick = (event: any, type) => {
        setAnchorEl(event.currentTarget);
        if (type === ORDER_TYPES.lead) {
            setIsDeleteLead(true)
        } else if (type === ORDER_TYPES.order) {
            setIsDeleteOrder(true)
        }
    };

    const getPayTypeUnCheckCount = (payType) => {
        if (payType === "PAY_NOW") {
            return payNowUncheckCount
        } else if (payType === "PAY_AS_YOU_GO") {
            return payAsYouGoUncheckCount
        } else if (payType === "PAY_BY_CHECK") {
            return payAsCheckUnCheckCount
        }
    }

    // const getUTMFilterMenu = () => {
    //   if (mainRole == rolesConstants.CONTRACTOR && utmFilterList.length && utmEnableParameter) {
    //     return (
    //       <div>
    //         <MenuComponent onChangeOverviewFilter={onFilterUTM} FILTER_LIST={utmFilterList} dateFilter={utmFilter} />
    //       </div>

    //     )
    //   }
    // }

    const changeDate = (timestamp) => {
        let date = new Date(timestamp)
        return date.toString().substring(4, 15)
    }

    const formatPhone = (phoneNumber) => {
        if (!phoneNumber || !phoneNumber?.length) {
            return "-"
        }
        else if (phoneNumber[0] !== '1' && phoneNumber[0] !== '+') {
            return phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, phoneNumber.length)
        }

        else {
            const number = phoneNumber.slice(-10)
            return "+1 " + number.substring(0, 3) + '-' + number.substring(3, 6) + '-' + number.substring(6, number.length)
        }
    }

    const changeStatus = (status: any) => {
        let converted = status?.split('_').join(' ')
        let capitalized = converted.charAt(0).toUpperCase() + converted.slice(1).toLowerCase();
        return status === 'ESTIMATE_SENT' ? 'Quote' : capitalized
    }

    const getActiveTabHeaders = () => {
        let currentData: { name: string; ref: string }[] = []
        if (ordersFilter === 'All' || ordersFilter === 'PAY_NOW' || ordersFilter === 'PAY_AS_YOU_GO' || ordersFilter === 'ESTIMATE_SENT') {

            currentData = [
                { name: 'Address', ref: 'measurement.address' },
                { name: 'Name', ref: 'user.name' },
                // { name: 'Name', ref: 'user.name' },
                // { name: 'Email', ref: 'user.email' },
                { name: 'Date', ref: 'createdAt' },
                { name: 'Amount', ref: 'amount' },
                { name: 'Type', ref: 'payment' },
                { name: 'view', ref: 'view' },
                // { name: 'delete', ref: 'delete' }
            ]
        }
        if (ordersFilter === 'LEADS') {
            currentData = [
                { name: 'Address', ref: 'measurement.address' },
                { name: 'Name', ref: 'name' },
                { name: 'Email', ref: 'email' },
                { name: 'Phone', ref: 'phone' },
                { name: 'Date', ref: 'createdAt' },
                // {name:'Amount paid', ref:'amount'},
                // { name: 'Type', ref: 'status' },
                { name: 'view', ref: 'view' },
                // { name: 'delete', ref: 'delete' }

            ]
        }
        if (ordersFilter === 'MEASUREMENTS') {

            currentData = [
                { name: 'Address', ref: 'address' },
                { name: 'Date', ref: 'date' },
                { name: 'Status', ref: 'status' },
            ]
        }
        return currentData
    }

    const getActiveTabData = () => {
        let currentData: any[] = []
        if (orders && ordersFilter !== 'LEADS') {
            orders.map((each: any) =>
                currentData.push({
                    key: each?._id,
                    address: each?.measurement?.address || each?.place?.formatted_address,
                    name: each?.user?.name,
                    // email: each?.user?.email,
                    // phone: formatPhone(each?.user?.phone),
                    date: changeDate(each?.createdAt),
                    amount: each.maxAmount && each?.amount !== each?.maxAmount ? `$${each?.amount} - $${each?.maxAmount}` : `$${each?.amount}`,
                    status: changeStatus(each?.payment),
                    action: <a href={`/contractor/order-details/${each?._id}`} className="px-5 py-2 border-1 border-[#DFE6E2] rounded-md bg-white hover:text-green-500">View</a>,
                    // del: !isSalesman(user) ? e => handleDeleteClick(e, ORDER_TYPES.order) : () => { }
                    del: !isSalesman(user) ? e => handleDeleteClick(e, 'order') : () => { }

                })
            )
        }
        if (leads && ordersFilter === 'LEADS') {

            leads.map((each: any) =>
                currentData.push({
                    key: each?._id,
                    address: each?.measurement?.address || each?.place?.formatted_address,
                    name: each?.name,
                    email: each?.email,
                    phone: formatPhone(each?.phone),
                    date: changeDate(each?.createdAt),
                    // status: changeStatus(each?.payment),
                    action: <a href={`/contractor/lead-details/${each?._id}`} className="px-5 py-2 border-1 border-[#DFE6E2] rounded-md bg-white hover:text-green-500">View</a>,
                    // del: !isSalesman(user) ? e => handleDeleteClick(e, ORDER_TYPES.lead) : () => { }

                    del: !isSalesman(user) ? e => handleDeleteClick(e, 'lead') : () => { }
                })
            )
        }
        // if(measurements && ordersFilter === 'MEASUREMENTS'){

        //   measurements.map((each:any) =>
        //     currentData.push({
        //       key: each?.searchlog_id,
        //       address: each?.address,
        //       date: changeDate(each?.searchCreatedAt),
        //       status: each?.payment
        //     })
        //     )
        // }
        setActiveTabData(currentData)
        return currentData
    }

    const tabledata = {
        tabledata: activeTabData,
        headers: getActiveTabHeaders(),
        enableSorting: true,
        handleSorting: handleSorting
    };

    const FILTER_OPTIONS = [
        { name: 'Today', onClick: () => onChangeOverviewFilter("Today") },
        { name: 'Yesterday', onClick: () => onChangeOverviewFilter("Yesterday") },
        { name: 'Last 7 Days', onClick: () => onChangeOverviewFilter("Last 7 Days") },
        { name: 'Last 30 Days', onClick: () => onChangeOverviewFilter("Last 30 Days") },
        { name: 'This Month', onClick: () => onChangeOverviewFilter("This Month") },
        { name: 'Last Month', onClick: () => onChangeOverviewFilter("Last Month") },
        { name: 'Lifetime', onClick: () => onChangeOverviewFilter("Lifetime") },
    ];

    const FILTER_TYPES_OPTIONS = [
        { name: 'All', onClick: () => getFilteredOrders("All") },
        { name: 'Pay now', onClick: () => getFilteredOrders("PAY_NOW") },
        { name: 'Pay as you go', onClick: () => getFilteredOrders("PAY_AS_YOU_GO") },
        { name: 'Estimate sent', onClick: () => getFilteredOrders("ESTIMATE_SENT") },
        !isSalesman(user) && { name: 'Leads', onClick: () => { !isSalesman(user) && getFilteredOrders("LEADS") } },
    ];

    const handleSearch = () => {
        setSearchResults(true)
        setSearchResultsString(searchInput)
        if (ordersFilter !== 'LEADS') {
            if (searchInput === '') {
                setSearchResults(false)
            }
            dispatch(getSearchedOrders(searchInput, ordersFilter, 1))
        } else if (ordersFilter === 'LEADS') {
            if (searchInput === '') {
                setSearchResults(false)
            }
            dispatch(getSearchedLeads(searchInput, pageNo))
        } else if (ordersFilter === 'MEASUREMENTS') {
            if (searchInput === '') {
                setSearchResults(false)
            }
            dispatch(searchMeasurements(pageNo, searchInput))
        }
    }

    const getSubscriptionRemainingTime = () => {

        const startDate = customer?.subscriptionFailed?.failDate ? new Date(customer?.subscriptionFailed?.failDate) : null
        if (!startDate) return 7
        const today = new Date()
        const remainingTime = 7 - ((today.getTime() - startDate.getTime()) / (1000 * 3600 * 24))
        return Math.ceil(remainingTime)
    }

    const calculatePaginationRecords = () => {
        const record_from = ((pageNo - 1) * 9) + 1
        const record_to = pageNo * 9
        const total_record = Math.ceil(totalCount)
        return {
            total_record,
            record_from: record_from <= total_record ? record_from : total_record,
            record_to: record_to <= total_record ? record_to : total_record,
        }
    }

    const paginationCounts = calculatePaginationRecords()

    return (
        <div className="no-gutters row">
            {customer?.paddleSubscriptionStatus !== 'active' && <ErrorAlertSingleLine errorMessage={`There has been an issue with your card on file. Please ensure the card on file is correct or update with a new card within the next ${getSubscriptionRemainingTime()} days to continue using ServiceCart.`} />}
            {showDelModal ? <DeleteModal onBack={handleBack} onDelete={onDeleteClick} /> : null}
            <div className="md:flex md:justify-between items-center">
                <h1 className="text-[24px] font-[700]">Dashboard overview</h1>
                <div className="md:flex md:justify-center md:items-center md:space-x-5 mt-3 md:mt-0 space-y-3 md:space-y-3">
                    {/* <CreditButton
            label='Credits'
            total="3204"
            onClick={() => console.log("set the credit button here")}
          /> */}
                    {isManager(user) && <div className='mr-3 w-full'>
                        <SelectSalesmen isSettingsLoaded={isSettingsLoaded} salesmen={salesmen} setSalesmen={setSalesmen} totalSalesmen={salesTeamArray(salesTeam)} onChangeSalesmanFilter={onChangeSalesmanFilter} />
                    </div>}
                    <Filter active={`${dateFilter}`} label={`${dateFilter}`} options={FILTER_OPTIONS} />
                </div>
            </div>
            <div className="md:flex md:justify-start space-y-3 md:space-y-0 md:space-x-7 py-[26px]">
                <Total
                    data={{
                        amount: currency + totalSales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        label: "SALES",
                    }}
                />
                <Total
                    data={{
                        amount: totalQuotes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        label: "QUOTES",
                    }}
                />
                <Total
                    data={{
                        amount: totalSearches.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        label: "SEARCHES",
                    }}
                />
            </div>
            <section className='hidden lg:grid lg:grid-cols-2 lg:gap-4' style={{ width: '100%' }}>
                <div className='col-span-1'>
                    <Chart
                        label="Sales"
                        total={numberOfSales}
                        datakey="amount"
                        totalChartData={totalSalesChartData}
                    />
                </div>
                <div className='col-span-1'>
                    <Chart
                        label="Quotes"
                        total={totalQuotes}
                        datakey="total"
                        totalChartData={totalQuotesChartData}
                    />
                </div>
            </section>
            <section className="col-md-12 section md:mt-10 mb-5" style={{ paddingTop: '15px' }}>
                <div className="col-md-12 pull-left">
                    <div className="row">
                        <h3 className="heading text-2xl font-bold">Active orders</h3>
                    </div>
                </div>
            </section>
            <div className="md:flex md:justify-between items-center space-y-3 md:space-y-0 mb-5">
                <Search
                    label="for address, owners"
                    onChange={(event: any) => setSearchInput(event.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />
                <div className="md:flex md:justify-center items-center md:space-x-3 space-y-3 md:space-y-0">
                    {mainRole == rolesConstants.CONTRACTOR && utmFilterList.length && utmEnableParameter &&
                        <Filter
                            active="All"
                            label={`UTM`}
                            options={utmFilterOptions}
                        /> || ''}
                    <Filter
                        active="All"
                        label={`Sort by type`}
                        options={FILTER_TYPES_OPTIONS}
                    />
                </div>
            </div>
            <hr />
            <section className="col-md-12 ">
                <div>
                    {orders && orders.length < 1 && ordersFilter !== 'LEADS' ?
                        <NotFound message='orders' />
                        : leads && leads.length < 1 && ordersFilter === 'LEADS' ? <NotFound message='leads' /> :
                            <Table {...tabledata} />

                    }
                </div>
            </section>
            { totalCount > 0 && <div className='grid justify-end' style={{ marginTop: 30 }}>
                <Pagination className='border-separate rounded-md border w-[100%]' shape='rounded' count={Math.ceil(totalCount / 9)}
                    page={pageNo} onChange={handlePageChange} />
            </div>}
            { totalCount > 0 && <div className='mt-[-35px]'>
                <span>Showing {paginationCounts.record_from} to {paginationCounts.record_to} of {paginationCounts.total_record} results</span>
            </div>}
        </div>
    );
};

export default ContractorOrders;

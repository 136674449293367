export const GET_PACKAGES = {
	METHOD: 'GET',
	PATH: '/packages'
}

export const GET_PACKAGE = {
	METHOD: 'GET',
	PATH: '/packages'
}

export const CREATE_PACKAGE = {
	METHOD: 'POST',
	PATH: '/packages'
}

export const EDIT_PACKAGE = {
	METHOD: 'PATCH',
	PATH: '/packages'
}

export const GET_PACKAGE_BY_ID = {
	METHOD: 'GET',
	PATH: '/packages/user'
}

export const GET_PUBLIC_PACKAGES = {
	METHOD: 'GET',
	PATH: '/packages/public'
}

export const UPLOAD_PACKAGE_IMAGE = {
	METHOD: 'POST',
	PATH: '/packages/uploadImage'
}
import { contractorTypes } from './contractor.types';
import sendRequest from '../../utils/useAxios';
import { DELETE_ORDER_BY_ID, GET_CONTRACTOR_ORDERS, GET_CONTRACTOR_ORDERS_FILTERED, GET_MULTIPLE_SALESMAN_ORDERS_OVERVIEW, GET_MULTIPLE_SALESMEN_ORDERS_FILTERED, GET_ORDERS_OVERVIEW, GET_SALESMAN_ORDERS_FILTERED, GET_SALESMAN_ORDERS_OVERVIEW, GET_SEARCHED_ORDERS } from './contractor.api';
import { getDatesByFilter } from '../../utils/getDatesByFilter';
import moment from 'moment';

export const getContractorOrders = () => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_CONTRACTOR_ORDERS.METHOD, GET_CONTRACTOR_ORDERS.PATH);
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false));
};

export const getFilteredMultipleSalesmanOrders = (query, salesmenIds) => async (dispatch) => {
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_MULTIPLE_SALESMEN_ORDERS_FILTERED.METHOD, `${GET_MULTIPLE_SALESMEN_ORDERS_FILTERED.PATH}?${query}`, {
    salesmenIds
  })
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false))
}

export const getFilteredSalesmanOrders = (query, salesmanId) => async (dispatch) => {
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_SALESMAN_ORDERS_FILTERED.METHOD, `${GET_SALESMAN_ORDERS_FILTERED.PATH}/${salesmanId}?${query}`)
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false))
}

export const getMultipleSalesmenOverview = (filter, salesmenIds) => async (dispatch) => {
  dispatch(setLoading(true));
  const {startDate, endDate} = getDatesByFilter(filter);
  const utcOffset = moment().format("Z")
  const { data } = await sendRequest(GET_MULTIPLE_SALESMAN_ORDERS_OVERVIEW.METHOD,`${GET_MULTIPLE_SALESMAN_ORDERS_OVERVIEW.PATH}/${filter}/${startDate}/${endDate}/${utcOffset}/`, {
    salesmenIds
  });
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_OVERVIEW_DATA, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false));
}

export const getSalesmanOrdersOverview = (filter, salesmanId) => async (dispatch) => {
  dispatch(setLoading(true));
  const {startDate, endDate} = getDatesByFilter(filter);
  const utcOffset = moment().format("Z")
  const { data } = await sendRequest(GET_SALESMAN_ORDERS_OVERVIEW.METHOD,`${GET_SALESMAN_ORDERS_OVERVIEW.PATH}/${salesmanId}/${filter}/${startDate}/${endDate}/${utcOffset}/`);

  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_OVERVIEW_DATA, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false));
};

export const getFilteredContractorOrders = (query) => async (dispatch) => {
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_CONTRACTOR_ORDERS_FILTERED.METHOD, `${GET_CONTRACTOR_ORDERS_FILTERED.PATH}?${query}`)
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false))
};

export const getSearchedOrders = (searchQuery, pageNo) => async (dispatch) => {
  const query = {
    searchQuery
  }
  dispatch(setLoading(true))
  const { data } = await sendRequest(GET_SEARCHED_ORDERS.METHOD, `${GET_SEARCHED_ORDERS.PATH}?page=${pageNo}`, query)
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_CONTRACTOR_ORDERS, data))
  } else {
    dispatch(setError(data?.message))
  }
  dispatch(setLoading(false))
  return data
}

export const deleteOrder = (orderId: string) => async (dispatch) => {
 
  sendRequest(DELETE_ORDER_BY_ID.METHOD, `${DELETE_ORDER_BY_ID.PATH}/${orderId}`).then(
  dispatch({
      type: contractorTypes.DELETE_ORDER_BY_ID,
      payload: orderId,
  }))  
  
}

export const getOrdersOverview = (filter) => async (dispatch) => {
  dispatch(setLoading(true));
  const {startDate, endDate} = getDatesByFilter(filter);
  const utcOffset = moment().format("Z")
  const { data } = await sendRequest(GET_ORDERS_OVERVIEW.METHOD,`${GET_ORDERS_OVERVIEW.PATH}/${filter}/${startDate}/${endDate}/${utcOffset}/`);
  if (data && !data.statusCode) {
    dispatch(setSuccess(contractorTypes.SET_OVERVIEW_DATA, data));
  } else {
    dispatch(setError(data?.message));
  }
  dispatch(setLoading(false));
};

export const toggleOrderExpand = (id) => (dispatch) => {
  dispatch({
    type: contractorTypes.TOGGLE_ORDER_EXPAND,
    payload: id,
  });
};

export const setOrdersFilter = (filter) => (dispatch) => {
  dispatch({
    type: contractorTypes.SET_ORDERS_FILTER,
    payload: filter,
  });
};

export const setOverviewFilter = (id) => (dispatch) => {
  dispatch({
    type: contractorTypes.SET_OVERVIEW_FILTER,
    payload: id,
  });
};

export const changeProductSelection = (type) => async (dispatch) => {
  dispatch({
    type: contractorTypes.CHANGE_PRODUCT_SELECTION,
    payload: type,
  });
};

export const setLoading = (type) => async (dispatch) => {
  dispatch({
    type: contractorTypes.SET_LOADING_CONTRACTOR,
    payload: type,
  });
};

export const setSuccess = (action, payload) => {
  return {
    type: contractorTypes[action],
    payload,
  }
}

export const setError = (payload) => {
  return {
    type: contractorTypes.SET_ERROR_CONTRACTOR,
    payload,
  }
}

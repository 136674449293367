// Make sure to add polygon color on server constants.ts > POLYGONS_COLOR
export const PolygonTypeConsts = {
  LAWN: {
    type: "Lawn",
    color: '#3DE396',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
    // search card
    displaySearchOption: true,
    searchTypeFeatures: ['LAWN', 'BUILDING', 'PARKING'],
    search_option_icon: '/assets/grass-option.png',
  },
  BUILDING: {
    type: "BUILDING",
    color: '#f24e3f',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.20,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: true,
  },
  PARKING: {
    type: "PARKING",
    color: '#12089e',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  DRIVEWAY: {
    type: "DRIVEWAY",
    color: '#C97C0B',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  SIDEWALK: {
    type: "SIDEWALK",
    color: '#F9EC1B',
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    properties: {
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      suppressUndo: true,
      zIndex: 2
    },
    displayToggleBtn: false,
  },
  SNOW: {
    type: "Snow",
    sqFeet_card_icon: '/assets/3d-print-pyramid.PNG',
    displayToggleBtn: true,
    includeMultipleFeatures: true,
    polygonFeatures: ['DRIVEWAY', 'SIDEWALK'], // db features
    // search card
    displaySearchOption: true,
    searchTypeFeatures: ['DRIVEWAY', 'SIDEWALK'],
    search_option_icon: '/assets/snowflake.png',
  }
}

export const getFeatureName = (key) => {
  return PolygonTypeConsts[key].type
}

export const getToggleFeatures = (feature) => {
  if (PolygonTypeConsts[feature].includeMultipleFeatures) {
    return PolygonTypeConsts[feature].polygonFeatures
  }
  return [feature]
}

export const getToggleValue = (polygonFeatures, checkFeature) => {
  if (PolygonTypeConsts[checkFeature].includeMultipleFeatures) {
    const temp_features = PolygonTypeConsts[checkFeature].polygonFeatures
    return temp_features.every(feature => polygonFeatures.includes(feature))
  }
  return polygonFeatures.includes(checkFeature)
}

export const getPopupFeatureName = (toggleFeatures) => {
  let featureName = toggleFeatures[0]
  if (toggleFeatures.length > 1) {
    featureName = Object.keys(PolygonTypeConsts).find(key => PolygonTypeConsts[key].includeMultipleFeatures && PolygonTypeConsts[key].polygonFeatures.every(feature => toggleFeatures.includes(feature)))
  }
  return PolygonTypeConsts[featureName].type
}

export const isMutipleSearchTypes = (customerPolygonFeatures) => {
  const temp_feature = Object.keys(PolygonTypeConsts).find(key => PolygonTypeConsts[key].includeMultipleFeatures && PolygonTypeConsts[key].polygonFeatures.every(feature => customerPolygonFeatures.includes(feature)))
  if (temp_feature) return true
  return false
}